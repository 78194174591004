import React, { useEffect } from "react";
import profil from "../assets/img/profile/profile-image.png";
import { clineId, slotId5 } from "../Components/JSON/id";
const Profile = () => {
  const coin = localStorage.getItem("coin");
  const count = localStorage.getItem("quizCount");
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  return (
    <div>
      <div className="row row-cols-1">
        <div className="col d-flex justify-content-center">
          <div>
            <img src={profil} alt="" />
          </div>
        </div>
        <div className="col text-center text-white">
          <div>
            <h4>User x</h4>
          </div>
        </div>
        <div className="col mt-3">
          <div className="row row-cols-2 m-auto">
            <div className="col m-auto">
              <div className="wallet">
                Coins
                <div className="d-inline float-end" id="coins">
                  {coin}
                </div>
              </div>
            </div>
            <div className="col m-auto">
              <div className="quiz-played">
                Played Quiz
                <div className="d-inline float-end" id="playedquiz">
                  {count}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={clineId}
        data-ad-slot={slotId5}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default Profile;
