/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { clineId, slotId3, slotPopId } from "../JSON/id";

function FullScreenModal(props) {
  const [isId, setId] = useState();
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  function getRandomObject(slotId) {
    return slotId[Math.floor(Math.random() * slotId.length)];
  }
  useEffect(() => {
    const data = getRandomObject(slotPopId);
    setId(data);
  }, [slotPopId]);

  return (
    <>
      <Modal style={{ background: "black" }} true fullscreen {...props}>
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="position-relative">
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={isId}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FullScreenModal;
