/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { categoryData } from "../Components/JSON/categoryData";
import { useNavigate } from "react-router-dom";
import { clineId,  slotId6, slotId7 } from "../Components/JSON/id";
import FullScreenModal from "../Components/Modal/FullScreenModal";
const Singlecategory = () => {
  const [singelData, setSingelData] = useState({});
  const [isQuestionList, setQuestionList] = useState(false);
  const [isData, setData] = useState(false);
  const [isRight, setRight] = useState(false);
  const [isWrong, setWrong] = useState(false);
  const [isAns, setAns] = useState(false);
  const [iscorrectAns, setcorrectAns] = useState("");
  const [isOtyNumber, setOtyNumber] = useState(1);
  const [isNumber, setNumber] = useState(0);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(30);
  const [rightAns, setRightAns] = useState(0);
  const [showFull, setShowFull] = useState(false);
  const count = localStorage.getItem("quizCount");

  const { id } = useParams();
  useEffect(() => {
    const temData = categoryData.filter((item) => item.id === +id);
    setSingelData(temData?.[0]);
  }, []);
  const hadleQuestionList = () => {
    setQuestionList(true);
    setShowFull(true);
    localStorage.setItem("quizCount", +count + 1);
  };

  useEffect(() => {
    const data = singelData.questionList?.[isNumber];
    setData(data);
  }, [singelData, isQuestionList]);

  const handleAns = (item, id) => {
    setAns(item);
    if (item === isData.answer) {
      setRight(true);
      setRightAns((prev) => prev + 1);
      setWrong(false);
    } else {
      setWrong(true);
      setRight(false);
      setcorrectAns(isData?.answer);
    }
    setTimeout(() => {
      setTimeLeft(30);
      setNumber((prev) => prev + 1);
      setOtyNumber((prev) => prev + 1);
    }, 1000);
  };

  useEffect(() => {
    const data = singelData.questionList?.[isNumber];
    setData(data);
  }, [isNumber]);

  useEffect(() => {
    if (isQuestionList) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 30));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isQuestionList]);

  useEffect(() => {
    if (timeLeft === 0) {
      handleAns(isData?.answer);
    }
  }, [timeLeft]);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const handleAdLoaded = () => {
      console.log("Ad successfully loaded.");
    };
    window.addEventListener("load", handleAdLoaded);
    return () => {
      window.removeEventListener("load", handleAdLoaded);
    };
  }, [id, isQuestionList]);
  useEffect(() => {
    localStorage.setItem("rightAns", rightAns);
  }, [rightAns]);

  useEffect(() => {
    if (isOtyNumber === singelData?.questionList?.length) {
      navigate("/win-coin");
    }
  }, [isOtyNumber]);
  return (
    <>
      {isQuestionList ? (
        <>
          <div className="col question-answer mt-3">
            <h1 className="text-center">{timeLeft}</h1>
            <p className="text-center">
              Answer few question and win 200 coin free!
            </p>
            <div className="m-3 text-center">
              <h4>
                Question <span className="quiz-text">{isOtyNumber}</span>/
                {singelData?.questionList?.length}
              </h4>
            </div>

            <div className="question-answer-box m-auto ">
              <p className="text-start p-3" id="question">
                {isData?.question}
              </p>

              <div className="" id="answers">
                <div className="row row-cols-2 justify-content-center d-flex  ">
                  {isData?.options?.map((item, i) => (
                    <div
                      key={i}
                      className={`options col-5 ${
                        isAns === item && isRight && "bg-success"
                      } ${isAns === item && isWrong && "bg-danger"} ${
                        iscorrectAns === item &&
                        iscorrectAns === isData?.answer &&
                        "bg-success"
                      }`}
                      onClick={() => handleAns(item, i)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={slotId7}
            data-ad-format="auto"
            data-full-width-responsive="true"
            onLoad={() => console.log("ok")}
          />
        </>
      ) : (
        <>
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={slotId6}
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
          <div className="quiz-entry-box mt-2">
            <div className="col d-flex justify-content-center my-3">
              <div>
                {singelData?.img && (
                  <img
                    src={require(`../assets/img/category/${singelData?.img}`)}
                    className="category-img rounded-3"
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="col text-center text-white ">
              <div>
                <h6 className="quiz-text">
                  {singelData?.category}-{singelData?.name}
                </h6>
                <h5>
                  Chance to win{" "}
                  <img src="/assets/images/big-coin.png" width={25} alt="" />{" "}
                  10000 coins
                </h5>
              </div>
            </div>
            <div className="col mt-3">
              <div className="row  m-auto">
                <div className="col">
                  <button
                    className="quiz-played text-center"
                    style={{ borderColor: "#FFC700" }}
                    onClick={() => hadleQuestionList()}
                  >
                    Play Now
                  </button>
                </div>
              </div>
            </div>
            <div className="col">
              <ul className="p-3 pb-3 ms-3 text-white m-3">
                <li> You've got 60 seconds to answer all questions</li>
                <li> To answer every question you have 5 seconds each</li>
                <li> For Every Correct answer you will get +100 Coins</li>
                <li> Answer as many questions as you can</li>
                <li> Play quizzes win coins and gain knowledge!</li>
              </ul>
            </div>
          </div>
        </>
      )}
      {showFull && (
        <FullScreenModal
          show={showFull}
          onHide={() => {
            setShowFull(false);
            setTimeLeft(30);
            //   navigate("/home");
          }}
        />
      )}
    </>
  );
};

export default Singlecategory;
