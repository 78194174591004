import React, { useEffect } from "react";
import { clineId, slotId2 } from "../Components/JSON/id";
import { useNavigate } from "react-router-dom";

const Wincoin = () => {
  const navigate = useNavigate();
  const rightAns = localStorage.getItem("rightAns");
  const coin = localStorage.getItem("coin");
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  const addCoin = () => {
    navigate("/home");
    localStorage.setItem("coin", +coin + +rightAns * 10);
    localStorage.setItem("adsPop", true);
  };
  return (
    <div className="p-3 pt-2 " style={{ height: "auto !important" }}>
      <div className="row row-cols-1" style={{ height: "auto !important" }}>
        <div className="col d-flex justify-content-center ">
          <div>
            <img
              src={require("../../src/assets/img/category/VZvx.gif")}
              alt=""
              width={150}
            />
          </div>
        </div>
        <div className="col text-center text-white">
          <div>
            <h2>Well Played</h2>
          </div>
        </div>
        <div className="col mt-3">
          <div className="row row-cols-2 m-auto">
            <div className="col m-auto">
              <div className="wallet">
                <div className="text-center" id="score">
                  {rightAns}
                  <div className="ps-1">Your Score</div>
                </div>
              </div>
            </div>
            <div className="col m-auto">
              <div className="quiz-played" style={{ borderColor: "#FFC700" }}>
                <div className="text-center" id="coin">
                  {rightAns * 10}
                  <div className="ps-1">CoinsEarned</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col mt-3 d-flex justify-content-center mb-2">
          <div onClick={addCoin} className="">
            <button className="signup-btn" style={{ width: 150 }}>
              Home
            </button>
          </div>
        </div>
        <div
          className="native-ads-size"
          style={{ height: "auto !important", maxHeight: "none !important" }}
        >
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={slotId2}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      </div>
    </div>
  );
};

export default Wincoin;
