import React, { useEffect, useState } from "react";
import coin_animation from "../assets/img/coin_animation.gif";
import { NavLink, useNavigate } from "react-router-dom";
import FullScreenModal from "../Components/Modal/FullScreenModal";
import { clineId, slotId2 } from "../Components/JSON/id";
const PlayquizBlock = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const handleAdLoaded = () => {
      console.log("Ad successfully loaded.");
    };
    window.addEventListener("load", handleAdLoaded);
    return () => {
      window.removeEventListener("load", handleAdLoaded);
    };
  }, []);

  function handleShow() {
    setShow(true);
  }
  return (
    <>
      <div className="row row-cols-1" style={{ height: "auto !important" }}>
        <div className="native-ads-size"></div>
        <div className="col">
          <div className="playquiz-block mt-2">
            <div className="text-center mt-3">
              <img src={coin_animation} alt="coin_animation" />
              <p className="text-white">
                You Have Won <span id="coins">100</span>
              </p>
              <div className="position-relative">
                <NavLink onClick={handleShow}>
                  <button className="quiz-btn">Play Quiz</button>
                </NavLink>
              </div>
            </div>
            <div>
              <ul className="p-3 ms-3 text-white m-3">
                <li>
                  Play Quizzes in 25+ categories like GK, Sports, Bollywood,
                  Business, Cricket &amp; More!
                </li>
                <li> Compete with lakhs of other players!</li>
                <li> Win Coins for every game</li>
                <li>
                  {" "}
                  Trusted by millions of other quiz enthusiasts like you!
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <FullScreenModal
          show={show}
          onHide={() => {
            setShow(false);
            localStorage.setItem("claimPop", true);
            navigate("/home");
          }}
        />
      )}
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={clineId}
        data-ad-slot={slotId2}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </>
  );
};

export default PlayquizBlock;
