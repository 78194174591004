import React, { useEffect, useState } from "react";
import { categoryData } from "../Components/JSON/categoryData";
import { useNavigate } from "react-router-dom";
import { clineId, slotId4 } from "../Components/JSON/id";

const Category = () => {
  const [isSearchValue, setSearchValue] = useState();
  const [isSearchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const filteredData = categoryData.filter((item) =>
      item.category.toLowerCase().includes(value.toLowerCase())
    );
    setSearchData(filteredData);
  };
  useEffect(() => {
    setSearchData(categoryData);
  }, []);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const handleAdLoaded = () => {
      console.log("Ad successfully loaded.");
    };
    window.addEventListener("load", handleAdLoaded);
    return () => {
      window.removeEventListener("load", handleAdLoaded);
    };
  }, []);
  const handleNavigate = (id) => {
    navigate(`/category/${id}`);
  };
  return (
    <div>
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={clineId}
        data-ad-slot={slotId4}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <div className="row g-3 px-2 p-3 cat-block mx-2 justify-content-center text-center mt-1">
        Select The Quiz Category that you want to play
        <div className="search">
          <input
            type="search"
            placeholder="Search"
            className="input-hide"
            value={isSearchValue}
            onChange={handleSearch}
          />
          <i class="fa-solid fa-magnifying-glass me-2" />
        </div>
        {isSearchData.map((item, i) => (
          <div
            className="col-4 cursor-pointer"
            onClick={() => {
              handleNavigate(item.id);
            }}
          >
            <div className="category-tiles">
              <div className="d-flex justify-content-center mt-2">
                <img
                  src={require(`../assets/img/category/${item.img}`)}
                  alt=""
                  className="rounded-3"
                />
              </div>
              <div className="d-flex justify-content-center my-2 category-name ">
                <div className="text-ellipsis">{item.category}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
