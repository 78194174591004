import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Question from "./Pages/Question";
import PlayquizBlock from "./Pages/PlayquizBlock";
import Home from "./Pages/Home";
import TopHeader from "./Layout/TopHeader";
import BottomHeader from "./Layout/BottomHeader";
import Category from "./Pages/Category";
import Profile from "./Pages/Profile";
import Singlecategory from "./Pages/Singlecategory";
import Wincoin from "./Pages/Wincoin";
import Ads from "./Pages/Ads";

const App = () => {
  const { pathname } = useLocation();
  const adsPath = ["/Ads", "/ads"];

  return (
    <div className="position-relative">
      {adsPath?.includes(pathname) ? (
        <Routes>
          <Route path="/ads" element={<Ads />} />
        </Routes>
      ) : (
        <>
          <div className="bg-img" />
          <div className="position-absolute top-0 w-100 mx-auto">
            <TopHeader />
            <div className="page-wrapper">
              <div className="p-2 h-100">
                <Routes>
                  <Route path="/" element={<Question />} />
                  <Route path="/playquiz" element={<PlayquizBlock />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/category/:id" element={<Singlecategory />} />
                  <Route path="/win-coin" element={<Wincoin />} />
                </Routes>
              </div>
            </div>
            <BottomHeader />
          </div>
        </>
      )}
    </div>
  );
};

export default App;
