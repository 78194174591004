export const quizlist = [
  {
    question:
      "If Paula's present age is X years, what will be her age in years after 20 years from now?",
    answer: "X+20",
    options: ["X+20", "20-X", "20X", "X-20"],
  },
  {
    question: "What is Jaguar's first all-electric car model called?",
    answer: "I-pace",
    options: ["E-pace", "I-pace", "f-pace", "j-pace"],
  },
  {
    question: "Which is the folk character that appears during Onam in north Malabar region?",
    answer: "Onapottan",
    options: ["Onthappan", "Varamali", "Onapottan", "Pookallam"],
  },
  {
    question: "What is the capital of France?",
    answer: "Paris",
    options: ["London", "Berlin", "Madrid", "Paris"],
  },
  {
    question: "Who wrote 'Romeo and Juliet'?",
    answer: "William Shakespeare",
    options: ["Jane Austen", "Charles Dickens", "William Shakespeare", "Mark Twain"],
  },
  {
    question: "Which Hindu god is worshiped during Diwali?",
    answer: "Lord Rama",
    options: ["Lord Krishna", "Lord Shiva", "Goddess Lakshmi", "Lord Rama"],
  },
  {
    question: "Which city in India is famous for its grand Diwali celebrations?",
    answer: "Varanasi",
    options: ["Mumbai", "Kolkata", "Varanasi", "Jaipur"],
  },
  {
    question: "What is the name of the epic associated with Diwali?",
    answer: "Ramayana",
    options: ["Mahabharata", "Ramayana", "Bhagavad Gita", "Upanishads"],
  },
];
