import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { categoryData } from "../Components/JSON/categoryData";
import { useNavigate } from "react-router-dom";
import { clineId,  slotId3 } from "../Components/JSON/id";
import Claim from "../Components/Modal/Claim";
import FullScreenModal from "../Components/Modal/FullScreenModal";

const Home = () => {
  const [isData, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [isCategoryData, setCategoryData] = useState([]);
  const [showFull, setShowFull] = useState(false);
  const [isCategoryName, setcategoryName] = useState("All");
  const adsPop = localStorage.getItem("adsPop");
  const navigate = useNavigate();
  const setting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setData(categoryData);
    const tempCategoryData = [
      ...new Set(["All", ...categoryData.map((item) => item.category)]),
    ];
    setCategoryData(tempCategoryData);
  }, []);

  const handleSelectCategory = (categoryName) => {
    setcategoryName(categoryName);
    if (categoryName !== "All") {
      const filteredData = categoryData.filter((item) =>
        item.category.toLowerCase().includes(categoryName.toLowerCase())
      );
      setData(filteredData);
    } else {
      setData(categoryData);
    }
  };

  const handleNavigate = (id) => {
    navigate(`/category/${id}`);
  };

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    const handleAdLoaded = () => {
      console.log("Ad successfully loaded.");
    };
    window.addEventListener("load", handleAdLoaded);
    return () => {
      window.removeEventListener("load", handleAdLoaded);
    };
  }, []);

  const hadleShow = (value) => {
    setShow(value);
  };
  useEffect(() => {
    const value = localStorage.getItem("claimPop");
    const value2 = value === "false" ? false : true;
    hadleShow(value2);
  }, []);


  const handleClaim = () => {
    const coin = localStorage.getItem("coin");
    localStorage.setItem("coin", +coin + 100);
    localStorage.setItem("claimPop", false);
    setShow(false);
    setShowFull(true);
  };
  useEffect(() => {
    const vaues2 = adsPop === "true" ? true : false;
    setShowFull(vaues2);
  }, [adsPop]);
  return (
    <div>
      <div className="d-flex justify-content-center p-0"></div>
      <div className="row p-1 justify-content-center p-3 pt-2">
        <div>
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={slotId3}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
        <div className="col-11 mx-auto mt-3">
          <ul className="categorys p-0">
            <Slider className="owl-theme" {...setting} margin={10}>
              {isCategoryData.map((item) => (
                <div
                  className={`item cursor-pointer ${
                    isCategoryName === item && "active-menu"
                  }`}
                  onClick={() => handleSelectCategory(item)}
                >
                  <li>{item}</li>
                </div>
              ))}
            </Slider>
          </ul>
        </div>
      </div>
      <div className="row g-3 px-2 pb-3  cat-block mx-2 justify-content-center">
        {isData.map((item, i) => (
          <div className="col-6">
            <div className="category-tiles">
              <div className="d-flex justify-content-center mt-2">
                <img
                  src={require(`../assets/img/category/${item?.img}`)}
                  alt=""
                  className="rounded-50"
                />
              </div>
              <div className="d-flex justify-content-center mt-2 category-name ">
                <div className="text-ellipsis">
                  {item?.category} | {item?.name}
                </div>
              </div>
              <div className="d-flex justify-content-center category-name">
                <div>
                  Play & Win{" "}
                  <i className="fa fa-circle " style={{ color: "#FFD43B" }} />{" "}
                  10000
                </div>
              </div>
              <div
                className="d-flex justify-content-center category-name my-3"
                onClick={() => handleNavigate(item.id)}
              >
                <button type="button" className="entry-btn">
                  Entry Fee 50
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Claim
        show={show}
        handleClaim={handleClaim}
        onHide={() => {
          setShow(false);
          setShowFull(true);
          navigate("/home");
          localStorage.setItem("claimPop", false);
        }}
      />
      {showFull && (
        <FullScreenModal
          show={showFull}
          onHide={() => {
            setShowFull(false);
            localStorage.setItem("adsPop", false);
            //   navigate("/home");
          }}
        />
      )}
    </div>
  );
};

export default Home;
