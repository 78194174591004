import React, { useEffect, useState } from "react";
import { clineId } from "../Components/JSON/id";
import { useNavigate } from "react-router-dom";

const Ads = () => {
  const [showAd, setShowAd] = useState(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowAd(false);
    navigate("/home");
  };
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  return (
    <>
      {showAd && (
        <div className="full-page-ad">
          <div className="close-button" onClick={handleClose}>
            x
          </div>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-format="autorelaxed"
            data-ad-client={clineId}
            // data-ad-slot={slotId}
            data-full-width-responsive={true}
          ></ins>
        </div>
      )}
    </>
  );
};

export default Ads;
