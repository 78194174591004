import React from "react";
import category from "../assets/img/nav-img/category.png";
import home from "../assets/img/nav-img/home.png";
import user from "../assets/img/nav-img/user.png";
import category_active from "../assets/img/nav-img/category-active.png";
import home_active from "../assets/img/nav-img/home-active.png";
import user_active from "../assets/img/nav-img/user-active.png";
import { NavLink, useLocation } from "react-router-dom";

const BottomHeader = () => {
  const { pathname } = useLocation();

  return (
    <div className="header-warrper">
      {/*bottom header start  */}
      <div className="sticky-navbar">
        <div className="container">
          <div className="row row-cols-3 mt-2">
            <div className="col text-center">
              <NavLink to="/category">
                <img
                  src={pathname === "/category" ? category_active : category}
                  alt=""
                />
              </NavLink>
            </div>
            <div className="col text-center">
              <NavLink to="/home">
                <img src={pathname === "/home" ? home_active : home} alt="" />
              </NavLink>
            </div>
            <div className="col text-center">
              <NavLink to="/profile">
                <img
                  src={pathname === "/profile" ? user_active : user}
                  alt=""
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      {/*bottom header end  */}
    </div>
  );
};

export default BottomHeader;
