import React, { useState } from "react";
import FullScreenModal from "../Components/Modal/FullScreenModal";
import { useNavigate } from "react-router-dom";

const TopHeader = () => {
  const coin = localStorage.getItem("coin");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  function handleShow() {
    setShow(true);
  }
  return (
    <div className="header-warrper">
      {/*top header start  */}
      <div
        className="col h-10 text-center quiz-text d-flex justify-content-end p-0 pb-2"
        style={{ fontSize: 12 }}
      >
        <div className="w-50 d-flex justify-content-start ms-2">
          <img
            src={require("../assets/img/nav-img/logo.png")}
            alt=""
            className="img-fluid my-auto"
            style={{ width: 150, height: 25 }}
          />
        </div>
        <div
          className="pt-1 w-25"
          onClick={handleShow}
          style={{ cursor: "pointer" }}
        >
          <i className="fa fa-gift" aria-hidden="true" />
          <span className="text-white"> Reward</span>{" "}
        </div>
        <div className="pt-1 w-25">
          <i className="fa-solid fa-coins" style={{ color: "#FFD43B" }} />{" "}
          <span className="text-white" id="wallet">
            {coin}
          </span>{" "}
          coins{" "}
        </div>
      </div>
      <div className="native-ads-size"></div>
      {show && (
        <FullScreenModal
          show={show}
          onHide={() => {
            setShow(false);
            navigate("/home");
          }}
        />
      )}
      {/*top header end  */}
    </div>
  );
};

export default TopHeader;
