import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { quizlist } from "../Components/JSON/quizlist";
import { clineId, slotId } from "../Components/JSON/id";

const Question = () => {
  const [isData, setData] = useState({});
  const [isRight, setRight] = useState(false);
  const [isWrong, setWrong] = useState(false);
  const [isAns, setAns] = useState(false);
  const [iscorrectAns, setcorrectAns] = useState(false);
  const [isOtyNumber, setOtyNumber] = useState(1);
  const navigate = useNavigate();

  function getRandomObject(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  const handleAns = (item, id) => {
    setAns(item);
    if (item === isData.answer) {
      setRight(true);
      setWrong(false);
    } else {
      setWrong(true);
      setRight(false);
      setcorrectAns(isData.answer);
    }
    setTimeout(() => {
      const data = getRandomObject(quizlist);
      setData(data);
      setOtyNumber((prev) => prev + 1);
      if (isOtyNumber === 2) {
        navigate("/playquiz");
      }
    }, 1000);
  };

  useEffect(() => {
    const data = getRandomObject(quizlist);
    setData(data);
  }, []);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});

    const handleAdLoaded = () => {
      console.log("Ad successfully loaded.");
    };
    window.addEventListener("load", handleAdLoaded);
    return () => {
      window.removeEventListener("load", handleAdLoaded);
    };
  }, []);
  return (
    <>
      <div>
        <div className="native-ads-size">
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={clineId}
            data-ad-slot={slotId}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
        <div className="row row-cols-1 text-center text-white">
          <div className="col question-answer mt-3">
            <p>Answer few question and win 200 coin free!</p>
            <div className="m-3">
              <h4>
                Question <span className="quiz-text">{isOtyNumber}</span>/2
              </h4>
            </div>
            <div className="question-answer-box m-auto ">
              <p className="text-start p-3" id="question">
                {isData.question}
              </p>
              <div className="" id="answers">
                <div className="row row-cols-2 justify-content-center d-flex  ">
                  {isData?.options?.map((item, i) => (
                    <div
                      key={i}
                      className={`options col-5 ${
                        isAns === item && isRight && "bg-success"
                      } ${isAns === item && isWrong && "bg-danger"} ${
                        iscorrectAns === item &&
                        iscorrectAns === isData.answer &&
                        "bg-success"
                      }`}
                      onClick={() => handleAns(item, i)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="question-answer-box m-auto mt-3 pb-0">
              <h4 className="pt-2">#Genius Facts</h4>
              <p id="randomFact" className="p-3">
                On the morning of 11/11/11 South Africa needed 111 runs to win
                at 11:11
              </p>
            </div>
            <div className="col mt-3 text-start">
              <h5>
                <span className="quiz-text ms-4">Play</span> Quiz and{" "}
                <span className="quiz-text">Win</span> Coins!
              </h5>
            </div>
            <div className="col m-3 mt-1 text-start">
              <ul className="p-3">
                <li>
                  Play Quizzes in 25+ categories like GK, Sports, Bollywood,
                  Business, Cricket &amp; More!
                </li>
                <li>Compete with lakhs of other players!</li>
                <li>Win Coins for every game</li>
                <li>Trusted by millions of other quiz enthusiasts like you!</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
