export const clineId = "ca-pub-3017413478487541";
export const slotPopId = ["9441042959", "6662824693", "6388558183", "3917473368", "3011925119"];
export const slotId = "3416045959";
export const slotId2 = "4537540024";
export const slotId3 = "3011925119";
export const slotId4 = "6388558183";
export const slotId5 = "9100667228";
export const slotId6 = "3917473368";
export const slotId7 = "3386990329";

