/* eslint-disable react-hooks/exhaustive-deps */

import Modal from "react-bootstrap/Modal";

function Claim(props) {
  const handleClaim = () => {
    props.handleClaim();
  };
  return (
    <>
      <Modal xl centered {...props}>
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="position-relative">
          <div className="reward-popup" id="reward-popup">
            <div className="d-flex justify-content-end text-white position-absolute"></div>
            <div className="row row-cols-1 d-flex justify-content-center">
              <div className="col text-center">
                <img
                  alt=""
                  src="https://khajanagame.milodygames.com/assets/images/ads-reward.gif"
                  width={150}
                  className="img-fluid"
                />
                <h6 className="quiz-text">New Reward Available</h6>
                <h5 className="text-white">Get Instant 100 Coins!</h5>
                <p style={{ color: "rgb(128, 128, 128)" }}>
                  Watch a simple ad and get rewarded
                </p>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="signup-btn" onClick={handleClaim}>
                  Claim
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Claim;
