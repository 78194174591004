export const categoryData = [
  // Sports
  {
    id: 1,
    img: "sports.png",
    category: "Sports",
    name: "Cricket",
    questionList: [
      {
        "question": "Who is the highest run-scorer in Test cricket?",
        "answer": "Sachin Tendulkar",
        "options": [
          "Sachin Tendulkar",
          "Ricky Ponting",
          "Virat Kohli",
          "Jacques Kallis"
        ]
      },
      {
        "question": "Which cricketer has scored the most centuries in One Day Internationals?",
        "answer": "Sachin Tendulkar",
        "options": [
          "Sachin Tendulkar",
          "Rohit Sharma",
          "Virat Kohli",
          "Kumar Sangakkara"
        ]
      },
      {
        "question": "Who holds the record for the fastest century in T20 International cricket?",
        "answer": "David Miller",
        "options": [
          "David Miller",
          "Yuvraj Singh",
          "Chris Gayle",
          "AB de Villiers"
        ]
      },
      {
        "question": "Which bowler has taken the most wickets in Test cricket?",
        "answer": "Muttiah Muralitharan",
        "options": [
          "Muttiah Muralitharan",
          "Shane Warne",
          "Anil Kumble",
          "James Anderson"
        ]
      },
      {
        "question": "Who was the captain of the Indian cricket team when they won the ICC Cricket World Cup in 2011?",
        "answer": "MS Dhoni",
        "options": [
          "Sachin Tendulkar",
          "Virat Kohli",
          "Rahul Dravid",
          "MS Dhoni"
        ]
      },
      {
        "question": "Which country won the ICC Cricket World Cup in 2019?",
        "answer": "England",
        "options": [
          "Australia",
          "India",
          "New Zealand",
          "England"
        ]
      },
      {
        "question": "Who holds the record for the highest individual score in Test cricket?",
        "answer": "Brian Lara",
        "options": [
          "Sachin Tendulkar",
          "Brian Lara",
          "Matthew Hayden",
          "Virender Sehwag"
        ]
      },
      {
        "question": "Which cricketer has won the most ICC Cricketer of the Year awards?",
        "answer": "Virat Kohli",
        "options": [
          "Sachin Tendulkar",
          "Ricky Ponting",
          "Virat Kohli",
          "Steve Smith"
        ]
      },
      {
        "question": "Who is the only player to have scored 100 international centuries?",
        "answer": "Sachin Tendulkar",
        "options": [
          "Sachin Tendulkar",
          "Ricky Ponting",
          "Virat Kohli",
          "Kumar Sangakkara"
        ]
      },
      {
        "question": "Who was the first cricketer to score a double century in One Day Internationals?",
        "answer": "Sachin Tendulkar",
        "options": [
          "Sachin Tendulkar",
          "Virender Sehwag",
          "Chris Gayle",
          "Martin Guptill"
        ]
      },
      {
        "question": "Which Indian cricketer has the nickname 'The Wall'?",
        "answer": "Rahul Dravid",
        "options": [
          "Sachin Tendulkar",
          "Virat Kohli",
          "Rahul Dravid",
          "VVS Laxman"
        ]
      },
      {
        "question": "Who is the fastest bowler to reach 100 Test wickets?",
        "answer": "George Lohmann",
        "options": [
          "Dale Steyn",
          "George Lohmann",
          "Muttiah Muralitharan",
          "James Anderson"
        ]
      }
    ]
    
  },
  
  { 
    id: 2, 
    img: "sports.png", 
    category: "Sports", 
    name: "T-20",
    questionList: [
      {
      "question": "Who won the first ICC T20 World Cup?",
      "answer": "India",
      "options": [
        "Australia",
        "India",
        "Pakistan",
        "Sri Lanka"
      ]
    },
    {
      "question": "Who has hit the most sixes in T20 International cricket?",
      "answer": "Chris Gayle",
      "options": [
        "Chris Gayle",
        "Rohit Sharma",
        "Brendon McCullum",
        "Martin Guptill"
      ]
    },
    {
      "question": "Which team has won the most IPL titles?",
      "answer": "Mumbai Indians",
      "options": [
        "Chennai Super Kings",
        "Mumbai Indians",
        "Kolkata Knight Riders",
        "Sunrisers Hyderabad"
      ]
    },
    {
      "question": "Who is the highest run-scorer in IPL history?",
      "answer": "Virat Kohli",
      "options": [
        "Virat Kohli",
        "Suresh Raina",
        "Rohit Sharma",
        "David Warner"
      ]
    },
    {
      "question": "Which player has taken the most wickets in IPL history?",
      "answer": "Lasith Malinga",
      "options": [
        "Lasith Malinga",
        "Sunil Narine",
        "Dwayne Bravo",
        "Amit Mishra"
      ]
    },
    {
      "question": "Who is the only cricketer to score 4 consecutive centuries in T20 cricket?",
      "answer": "Chris Gayle",
      "options": [
        "Virat Kohli",
        "AB de Villiers",
        "Rohit Sharma",
        "Chris Gayle"
      ]
    },
    {
      "question": "Who was the first bowler to take a hat-trick in IPL history?",
      "answer": "Amit Mishra",
      "options": [
        "Amit Mishra",
        "Lakshmipathy Balaji",
        "Yuvraj Singh",
        "Sohail Tanvir"
      ]
    },
    {
      "question": "Which team won the most recent ICC T20 World Cup?",
      "answer": "West Indies",
      "options": [
        "India",
        "West Indies",
        "Australia",
        "England"
      ]
    },
    {
      "question": "Who is the highest wicket-taker in T20 International cricket?",
      "answer": "Shahid Afridi",
      "options": [
        "Lasith Malinga",
        "Shahid Afridi",
        "Rashid Khan",
        "Shakib Al Hasan"
      ]
    },
    {
      "question": "Who was the first player to score a century in IPL history?",
      "answer": "Brendon McCullum",
      "options": [
        "Adam Gilchrist",
        "Chris Gayle",
        "Brendon McCullum",
        "AB de Villiers"
      ]
    },
    {
      "question": "Which player holds the record for the fastest fifty in T20 cricket?",
      "answer": "Yuvraj Singh",
      "options": [
        "AB de Villiers",
        "David Miller",
        "Yuvraj Singh",
        "Chris Gayle"
      ]
    },
    {
      "question": "Who is the captain of the Indian cricket team in T20 Internationals?",
      "answer": "Virat Kohli",
      "options": [
        "MS Dhoni",
        "Virat Kohli",
        "Rohit Sharma",
        "Shikhar Dhawan"
      ]
    }
  ]
},

{ 
  id: 3, 
  img: "sports.png", 
  category: "Sports", 
  name: "Football",
  questionList: [
    {
      "question": "Which country won the FIFA World Cup in 2018?",
      "answer": "France",
      "options": [
        "Germany",
        "Argentina",
        "France",
        "Brazil"
      ]
    },
    {
      "question": "Who is the all-time top scorer in the UEFA Champions League?",
      "answer": "Cristiano Ronaldo",
      "options": [
        "Lionel Messi",
        "Cristiano Ronaldo",
        "Robert Lewandowski",
        "Raul"
      ]
    },
    {
      "question": "Which team has won the most English Premier League titles?",
      "answer": "Manchester United",
      "options": [
        "Manchester City",
        "Liverpool",
        "Chelsea",
        "Manchester United"
      ]
    },
    {
      "question": "Who is the only player to have won the FIFA Ballon d'Or with three different clubs?",
      "answer": "Cristiano Ronaldo",
      "options": [
        "Lionel Messi",
        "Cristiano Ronaldo",
        "Neymar",
        "Andres Iniesta"
      ]
    },
    {
      "question": "Which player has won the most FIFA World Player of the Year awards?",
      "answer": "Lionel Messi",
      "options": [
        "Cristiano Ronaldo",
        "Lionel Messi",
        "Zinedine Zidane",
        "Ronaldinho"
      ]
    },
    {
      "question": "Who is the manager of the Spanish national football team?",
      "answer": "Luis Enrique",
      "options": [
        "Pep Guardiola",
        "Jurgen Klopp",
        "Luis Enrique",
        "Diego Simeone"
      ]
    },
    {
      "question": "Which player holds the record for the most goals scored in a single Premier League season?",
      "answer": "Mohamed Salah",
      "options": [
        "Thierry Henry",
        "Alan Shearer",
        "Luis Suarez",
        "Mohamed Salah"
      ]
    },
    {
      "question": "Which club has won the most UEFA Champions League titles?",
      "answer": "Real Madrid",
      "options": [
        "FC Barcelona",
        "Bayern Munich",
        "Real Madrid",
        "AC Milan"
      ]
    },
    {
      "question": "Who is the captain of the Brazilian national football team?",
      "answer": "Neymar",
      "options": [
        "Neymar",
        "Philippe Coutinho",
        "Thiago Silva",
        "Casemiro"
      ]
    },
    {
      "question": "Which player holds the record for the fastest hat-trick in Premier League history?",
      "answer": "Sadio Mane",
      "options": [
        "Sergio Aguero",
        "Harry Kane",
        "Sadio Mane",
        "Mohamed Salah"
      ]
    },
    {
      "question": "Who is the manager of the Liverpool football club?",
      "answer": "Jurgen Klopp",
      "options": [
        "Jose Mourinho",
        "Pep Guardiola",
        "Jurgen Klopp",
        "Carlo Ancelotti"
      ]
    },
    {
      "question": "Which player has won the most Ballon d'Or awards?",
      "answer": "Lionel Messi",
      "options": [
        "Cristiano Ronaldo",
        "Lionel Messi",
        "Michel Platini",
        "Johan Cruyff"
      ]
    }
  ]
},

{ 
  id: 4, 
  img: "sports.png", 
  category: "Sports", 
  name: " Table Tennis",
  questionList: [
    {
      "question": "Which country has won the most Olympic gold medals in table tennis?",
      "answer": "China",
      "options": [
        "China",
        "Sweden",
        "Japan",
        "South Korea"
      ]
    },
    {
      "question": "Who is known as the 'Queen of Table Tennis'?",
      "answer": "Deng Yaping",
      "options": [
        "Zhang Yining",
        "Liu Shiwen",
        "Wang Nan",
        "Deng Yaping"
      ]
    },
    {
      "question": "Which player has won the most Men's Singles titles at the World Table Tennis Championships?",
      "answer": "Zhang Jike",
      "options": [
        "Ma Long",
        "Wang Hao",
        "Zhang Jike",
        "Jan-Ove Waldner"
      ]
    },
    {
      "question": "Who is the youngest Olympic gold medalist in table tennis history?",
      "answer": "Zhang Yining",
      "options": [
        "Liu Shiwen",
        "Zhang Yining",
        "Ding Ning",
        "Li Xiaoxia"
      ]
    },
    {
      "question": "Which country has won the most Women's Singles titles at the World Table Tennis Championships?",
      "answer": "China",
      "options": [
        "Japan",
        "China",
        "South Korea",
        "Singapore"
      ]
    },
    {
      "question": "Who is the only player to have won Olympic gold in Singles, Doubles, and Team events?",
      "answer": "Ma Lin",
      "options": [
        "Ma Long",
        "Wang Liqin",
        "Zhang Jike",
        "Ma Lin"
      ]
    },
    {
      "question": "Which player has won the most Women's Singles titles at the World Table Tennis Championships?",
      "answer": "Deng Yaping",
      "options": [
        "Zhang Yining",
        "Deng Yaping",
        "Wang Nan",
        "Liu Shiwen"
      ]
    },
    {
      "question": "What is the name of the grip where the index finger is bent over the rubber?",
      "answer": "Penhold grip",
      "options": [
        "Shakehand grip",
        "Penhold grip",
        "V-Grip",
        "Seemiller grip"
      ]
    },
    {
      "question": "Who is the only player to have won the Grand Slam in table tennis?",
      "answer": "Liu Guoliang",
      "options": [
        "Waldner",
        "Liu Guoliang",
        "Ma Long",
        "Jan-Ove Waldner"
      ]
    },
    {
      "question": "Which player has won the most Men's Doubles titles at the World Table Tennis Championships?",
      "answer": "Wang Liqin",
      "options": [
        "Ma Lin",
        "Wang Liqin",
        "Zhang Jike",
        "Wang Hao"
      ]
    },
    {
      "question": "What is the diameter of a standard table tennis ball in millimeters?",
      "answer": "40mm",
      "options": [
        "38mm",
        "40mm",
        "42mm",
        "44mm"
      ]
    },
    {
      "question": "What is the name of the type of rubber used on table tennis bats?",
      "answer": "Pimpled rubber",
      "options": [
        "Smooth rubber",
        "Pimpled rubber",
        "Textured rubber",
        "Grippy rubber"
      ]
    }
  ]
},

{ 
  id: 5, 
  img: "sports.png", 
  category: "Sports", 
  name:"Batmantan",
  questionList: [
    {
      "question": "In the sport of batmantan, what is the name of the equipment used to hit the shuttlecock?",
      "answer": "Batarang",
      "options": [
        "Batstick",
        "Batarang",
        "Batracquet",
        "Batshuttle"
      ]
    },
    {
      "question": "Which fictional city hosts the annual Batmantan Championship?",
      "answer": "Gotham City",
      "options": [
        "Metropolis",
        "Gotham City",
        "Central City",
        "Star City"
      ]
    },
    {
      "question": "What is the objective of batmantan?",
      "answer": "To score points by hitting the shuttlecock over the net and into the opponent's court.",
      "options": [
        "To knock out the opponent with the Batarang.",
        "To solve riddles while playing badminton.",
        "To score points by hitting the shuttlecock over the net and into the opponent's court.",
        "To catch criminals while playing badminton."
      ]
    },
    {
      "question": "Who is considered the greatest batmantan player of all time?",
      "answer": "Bruce Shuttlecock",
      "options": [
        "Dick Shuttlebird",
        "Selina Smash",
        "Barbara Backhand",
        "Bruce Shuttlecock"
      ]
    },
    {
      "question": "What is the name of the batmantan tournament held exclusively for villains?",
      "answer": "The Rogues' Racket",
      "options": [
        "The Villain's Volley",
        "The Criminal Court",
        "The Rogues' Racket",
        "The Sinister Shuttle"
      ]
    },
    {
      "question": "Which fictional character is known for his lightning-fast serves in batmantan?",
      "answer": "The Flash",
      "options": [
        "Captain America",
        "The Flash",
        "Iron Man",
        "Spider-Man"
      ]
    },
    {
      "question": "What is the name of the batmantan court?",
      "answer": "The Batcourt",
      "options": [
        "The Gotham Grid",
        "The Batspace",
        "The Dark Court",
        "The Batcourt"
      ]
    },
    {
      "question": "Who is the inventor of the sport of batmantan?",
      "answer": "Lucius Shuttleworth",
      "options": [
        "Alfred Shuttlecock",
        "Lucius Shuttleworth",
        "Harvey Shuttleman",
        "Jonathan Shuttleworth"
      ]
    },
    {
      "question": "What is the penalty for a player who uses a grappling hook to move around the batmantan court?",
      "answer": "Disqualification",
      "options": [
        "A time penalty",
        "Loss of a point",
        "Disqualification",
        "A warning from the Batumpire"
      ]
    },
    {
      "question": "What is the name of the legendary batmantan move that involves a swift aerial strike?",
      "answer": "The Bat Smash",
      "options": [
        "The Gotham Glide",
        "The Bat Swing",
        "The Batarang Blast",
        "The Bat Smash"
      ]
    },
    {
      "question": "What is the official beverage of batmantan players?",
      "answer": "Bat-ade",
      "options": [
        "Bat-ade",
        "Bat-juice",
        "Bat-brew",
        "Bat-boost"
      ]
    },
    {
      "question": "What is the name of the batmantan league that features teams representing different superheroes?",
      "answer": "The Justice League of Batmantan",
      "options": [
        "The Heroic Shuttle League",
        "The Batmantan Alliance",
        "The Gotham Games",
        "The Justice League of Batmantan"
      ]
    }
  ]
},

{ 
  id: 6, 
  img: "sports.png", 
  category: "Sports", 
  name:"Hockey",
  QuestionList: [
    {
      "question": "In hockey, how many players are on the ice for each team during regular play?",
      "answer": "6",
      "options": [
        "5",
        "6",
        "7",
        "8"
      ]
    },
    {
      "question": "Which country won the most Olympic gold medals in men's field hockey?",
      "answer": "India",
      "options": [
        "Australia",
        "Netherlands",
        "India",
        "Germany"
      ]
    },
    {
      "question": "What is the name of the line that divides the hockey rink in half?",
      "answer": "Center line",
      "options": [
        "Blue line",
        "Goal line",
        "Center line",
        "Red line"
      ]
    },
    {
      "question": "What is the maximum number of players a team can have on the ice without penalty?",
      "answer": "6",
      "options": [
        "5",
        "6",
        "7",
        "8"
      ]
    },
    {
      "question": "Which player holds the record for the most career goals scored in the NHL?",
      "answer": "Wayne Gretzky",
      "options": [
        "Mario Lemieux",
        "Jaromir Jagr",
        "Gordie Howe",
        "Wayne Gretzky"
      ]
    },
    {
      "question": "What is the term for a goal scored when a player hits the puck into the net?",
      "answer": "Field goal",
      "options": [
        "Field goal",
        "Slapshot",
        "Power play",
        "Breakaway"
      ]
    },
    {
      "question": "Which NHL team has won the most Stanley Cup championships?",
      "answer": "Montreal Canadiens",
      "options": [
        "Toronto Maple Leafs",
        "Detroit Red Wings",
        "Montreal Canadiens",
        "Chicago Blackhawks"
      ]
    },
    {
      "question": "What is the name of the protective gear worn by hockey goaltenders?",
      "answer": "Goalie mask",
      "options": [
        "Helmet",
        "Chest protector",
        "Goalie mask",
        "Leg pads"
      ]
    },
    {
      "question": "Which type of penalty in hockey results in a player being removed from the ice for a specified period?",
      "answer": "Minor penalty",
      "options": [
        "Major penalty",
        "Minor penalty",
        "Misconduct penalty",
        "Match penalty"
      ]
    },
    {
      "question": "What is the term for a situation where a player has a clear path to the opposing team's goal with no defenders in front of them?",
      "answer": "Breakaway",
      "options": [
        "Power play",
        "Breakaway",
        "Faceoff",
        "Slapshot"
      ]
    },
    {
      "question": "What is the maximum length of a hockey stick?",
      "answer": "63 inches",
      "options": [
        "55 inches",
        "60 inches",
        "63 inches",
        "68 inches"
      ]
    },
    {
      "question": "In international hockey, what is the diameter of the puck?",
      "answer": "3 inches",
      "options": [
        "2 inches",
        "2.5 inches",
        "3 inches",
        "3.5 inches"
      ]
    }
  ]
},

//Festivals
{ 
  id: 7, 
  img: "festivals.png", 
  category: "Festivals", 
  name:"Diwali",
  questionList: [
    {
      "question": "What is the literal meaning of the word 'Diwali'?",
      "answer": "Row of lamps",
      "options": [
        "Festival of colors",
        "Row of lamps",
        "Harvest festival",
        "Festival of lights"
      ]
    },
    {
      "question": "Which Hindu god is worshiped during Diwali?",
      "answer": "Lord Rama",
      "options": [
        "Lord Krishna",
        "Lord Shiva",
        "Goddess Lakshmi",
        "Lord Rama"
      ]
    },
    {
      "question": "What is the name of the traditional sweets exchanged during Diwali?",
      "answer": "Ladoo",
      "options": [
        "Jalebi",
        "Barfi",
        "Gulab Jamun",
        "Ladoo"
      ]
    },
    {
      "question": "In which month does Diwali usually fall?",
      "answer": "October or November",
      "options": [
        "August or September",
        "October or November",
        "December or January",
        "February or March"
      ]
    },
    {
      "question": "What is the significance of lighting oil lamps during Diwali?",
      "answer": "Symbolizes the victory of light over darkness",
      "options": [
        "Symbolizes the start of winter",
        "Symbolizes the victory of light over darkness",
        "Symbolizes the harvest season",
        "Symbolizes prosperity and wealth"
      ]
    },
    {
      "question": "Which city in India is famous for its grand Diwali celebrations?",
      "answer": "Varanasi",
      "options": [
        "Mumbai",
        "Kolkata",
        "Varanasi",
        "Jaipur"
      ]
    },
    {
      "question": "What is the name of the ritual performed on Diwali to welcome Goddess Lakshmi?",
      "answer": "Lakshmi Puja",
      "options": [
        "Durga Puja",
        "Ganesh Puja",
        "Lakshmi Puja",
        "Saraswati Puja"
      ]
    },
    {
      "question": "What is the name of the epic associated with Diwali?",
      "answer": "Ramayana",
      "options": [
        "Mahabharata",
        "Ramayana",
        "Bhagavad Gita",
        "Upanishads"
      ]
    },
    {
      "question": "What is the traditional practice of cleaning homes before Diwali called?",
      "answer": "Diwali cleaning",
      "options": [
        "Spring cleaning",
        "Diwali cleaning",
        "Deep cleaning",
        "Festive cleaning"
      ]
    },
    {
      "question": "What is the name of the firecracker commonly burst during Diwali?",
      "answer": "Sutli bomb",
      "options": [
        "Chakri",
        "Anar",
        "Sutli bomb",
        "Rocket"
      ]
    },
    {
      "question": "What is the name of the dance form traditionally performed during Diwali in Gujarat?",
      "answer": "Garba",
      "options": [
        "Bharatanatyam",
        "Kathak",
        "Garba",
        "Bhangra"
      ]
    },
    {
      "question": "What is the name of the day that follows Diwali?",
      "answer": "Govardhan Puja",
      "options": [
        "Bhai Dooj",
        "Chhath Puja",
        "Govardhan Puja",
        "Dhanteras"
      ]
    }
  ]
},

{ 
  id: 8, 
  img: "festivals.png", 
  category: "Festivals", 
  name:"Holi",
  questionList: [
    {
      "question": "What is the literal meaning of the word 'Holi'?",
      "answer": "Festival of colors",
      "options": [
        "Festival of lights",
        "Festival of colors",
        "Harvest festival",
        "Spring festival"
      ]
    },
    {
      "question": "Which Hindu god is associated with Holi?",
      "answer": "Lord Krishna",
      "options": [
        "Lord Rama",
        "Lord Shiva",
        "Goddess Durga",
        "Lord Krishna"
      ]
    },
    {
      "question": "What is the name of the special drink consumed during Holi?",
      "answer": "Thandai",
      "options": [
        "Lassi",
        "Thandai",
        "Buttermilk",
        "Aam Panna"
      ]
    },
    {
      "question": "In which month does Holi usually fall?",
      "answer": "March",
      "options": [
        "February",
        "March",
        "April",
        "May"
      ]
    },
    {
      "question": "What is the significance of burning Holika during Holi?",
      "answer": "Symbolizes the victory of good over evil",
      "options": [
        "Symbolizes the arrival of spring",
        "Symbolizes the victory of good over evil",
        "Symbolizes the end of winter",
        "Symbolizes the harvest season"
      ]
    },
    {
      "question": "Which city in India is famous for its grand Holi celebrations?",
      "answer": "Mathura",
      "options": [
        "Jaipur",
        "Mathura",
        "Varanasi",
        "Udaipur"
      ]
    },
    {
      "question": "What is the name of the bonfire lit on the eve of Holi?",
      "answer": "Holika Dahan",
      "options": [
        "Lohri",
        "Diwali",
        "Holika Dahan",
        "Navratri"
      ]
    },
    {
      "question": "What is the name of the dance traditionally performed during Holi in Uttar Pradesh?",
      "answer": "Raslila",
      "options": [
        "Garba",
        "Bhangra",
        "Raslila",
        "Dandiya"
      ]
    },
    {
      "question": "What is the name of the colored powder thrown during Holi celebrations?",
      "answer": "Gulal",
      "options": [
        "Rangoli",
        "Gulal",
        "Haldi",
        "Kumkum"
      ]
    },
    {
      "question": "What is the name of the festival celebrated on the day after Holi?",
      "answer": "Dhuleti",
      "options": [
        "Baisakhi",
        "Dhuleti",
        "Makar Sankranti",
        "Pongal"
      ]
    },
    {
      "question": "What is the name of the traditional Holi sweet made from condensed milk and sugar?",
      "answer": "Gujiya",
      "options": [
        "Rasgulla",
        "Jalebi",
        "Gujiya",
        "Gajar ka Halwa"
      ]
    },
    {
      "question": "What is the name of the folk dance performed by women during Holi in Gujarat?",
      "answer": "Garba",
      "options": [
        "Bihu",
        "Garba",
        "Lavani",
        "Chhau"
      ]
    }
  ]
},

{ 
  id: 9, 
  img: "festivals.png", 
  category: "Festivals", 
  name:"Navratri",
  questionList: [
    {
      "question": "What is the literal meaning of the word 'Navratri'?",
      "answer": "Nine nights",
      "options": [
        "Festival of lights",
        "Festival of colors",
        "Nine nights",
        "Harvest festival"
      ]
    },
    {
      "question": "Which Hindu goddess is worshipped during Navratri?",
      "answer": "Goddess Durga",
      "options": [
        "Goddess Lakshmi",
        "Goddess Saraswati",
        "Goddess Parvati",
        "Goddess Durga"
      ]
    },
    {
      "question": "In which month does Navratri usually fall?",
      "answer": "September or October",
      "options": [
        "August or September",
        "September or October",
        "October or November",
        "November or December"
      ]
    },
    {
      "question": "What is the name of the dance form traditionally performed during Navratri in Gujarat?",
      "answer": "Garba",
      "options": [
        "Bhangra",
        "Kathak",
        "Garba",
        "Bharatanatyam"
      ]
    },
    {
      "question": "What is the name of the ritual performed on the eighth day of Navratri?",
      "answer": "Kanya Puja",
      "options": [
        "Durga Puja",
        "Lakshmi Puja",
        "Saraswati Puja",
        "Kanya Puja"
      ]
    },
    {
      "question": "What is the significance of Navratri?",
      "answer": "Celebration of the triumph of good over evil",
      "options": [
        "Celebration of love and affection",
        "Celebration of the harvest season",
        "Celebration of the triumph of good over evil",
        "Celebration of knowledge and wisdom"
      ]
    },
    {
      "question": "What is the name of the special food items prepared during Navratri?",
      "answer": "Navratri thali",
      "options": [
        "Diwali sweets",
        "Navratri thali",
        "Holi snacks",
        "Ganesh Chaturthi dishes"
      ]
    },
    {
      "question": "What is the name of the festival celebrated on the tenth day of Navratri?",
      "answer": "Dussehra",
      "options": [
        "Diwali",
        "Holi",
        "Navratri",
        "Dussehra"
      ]
    },
    {
      "question": "What is the name of the musical instrument commonly played during Navratri?",
      "answer": "Dhol",
      "options": [
        "Tabla",
        "Sitar",
        "Dhol",
        "Flute"
      ]
    },
    {
      "question": "What is the traditional color associated with the first day of Navratri?",
      "answer": "Yellow",
      "options": [
        "Red",
        "Green",
        "Yellow",
        "Blue"
      ]
    },
    {
      "question": "What is the name of the dance performed during Navratri in Maharashtra?",
      "answer": "Lavani",
      "options": [
        "Garba",
        "Lavani",
        "Bihu",
        "Kuchipudi"
      ]
    },
    {
      "question": "What is the name of the ritual observed on the seventh day of Navratri?",
      "answer": "Saptami Puja",
      "options": [
        "Ashtami Puja",
        "Saptami Puja",
        "Navami Puja",
        "Dashami Puja"
      ]
    }
  ]
},
{ 
  id: 10, 
  img: "festivals.png", 
  category: "Festivals", 
  name:"Eid-ul-Fitr",
  questionList: [
    {
      "question": "What is the literal meaning of the Arabic term 'Eid-ul-Fitr'?",
      "answer": "Festival of breaking the fast",
      "options": [
        "Festival of fasting",
        "Festival of sacrifice",
        "Festival of breaking the fast",
        "Festival of prayer"
      ]
    },
    {
      "question": "Which month of the Islamic calendar marks the celebration of Eid-ul-Fitr?",
      "answer": "Shawwal",
      "options": [
        "Ramadan",
        "Shawwal",
        "Dhu al-Hijjah",
        "Muharram"
      ]
    },
    {
      "question": "What is the name of the special prayer offered on Eid-ul-Fitr?",
      "answer": "Salat al-Eid",
      "options": [
        "Salat al-Fajr",
        "Salat al-Zuhr",
        "Salat al-Eid",
        "Salat al-Isha"
      ]
    },
    {
      "question": "What is the name of the charity given to the poor on Eid-ul-Fitr?",
      "answer": "Zakat al-Fitr",
      "options": [
        "Zakat al-Mal",
        "Zakat al-Fitr",
        "Sadaqah",
        "Fitrah"
      ]
    },
    {
      "question": "What is the traditional greeting exchanged on Eid-ul-Fitr?",
      "answer": "Eid Mubarak",
      "options": [
        "Ramadan Kareem",
        "Eid Mubarak",
        "Assalamu Alaikum",
        "Merry Eid"
      ]
    },
    {
      "question": "What is the name of the special sweet dishes prepared for Eid-ul-Fitr?",
      "answer": "Sheer Khurma",
      "options": [
        "Baklava",
        "Sheer Khurma",
        "Kunafa",
        "Basbousa"
      ]
    },
    {
      "question": "What is the name of the pre-dawn meal consumed before the Eid prayer?",
      "answer": "Suhur",
      "options": [
        "Iftar",
        "Suhur",
        "Sehri",
        "Suhoor"
      ]
    },
    {
      "question": "What is the name of the special prayer offered at night during Ramadan and on Eid-ul-Fitr?",
      "answer": "Taraweeh",
      "options": [
        "Fajr",
        "Isha",
        "Taraweeh",
        "Witr"
      ]
    },
    {
      "question": "What is the name of the special clothing worn by Muslims on Eid-ul-Fitr?",
      "answer": "Eid clothes",
      "options": [
        "Thobe",
        "Eid clothes",
        "Abaya",
        "Jubah"
      ]
    },
    {
      "question": "What is the name of the congregation held on Eid-ul-Fitr for the special prayer?",
      "answer": "Eidgah",
      "options": [
        "Mosque",
        "Eidgah",
        "Haram",
        "Dargah"
      ]
    },
    {
      "question": "What is the name of the Islamic month preceding Eid-ul-Fitr?",
      "answer": "Ramadan",
      "options": [
        "Shawwal",
        "Ramadan",
        "Dhu al-Qidah",
        "Dhu al-Hijjah"
      ]
    },
    {
      "question": "What is the name of the special greeting cards exchanged on Eid-ul-Fitr?",
      "answer": "Eidi",
      "options": [
        "Eidi",
        "Dua",
        "Zakat",
        "Hadith"
      ]
    }
  ]
},

{ 
  id: 11, 
  img: "festivals.png", 
  category: "Festivals", 
  name:"Ganesh Chaturthi",
  questionList: [
    {
      "question": "What is the literal meaning of the term 'Ganesh Chaturthi'?",
      "answer": "Ganesh's fourth day",
      "options": [
        "Ganesh's birthday",
        "Ganesh's arrival",
        "Ganesh's festival",
        "Ganesh's fourth day"
      ]
    },
    {
      "question": "What is the name of the Hindu god celebrated during Ganesh Chaturthi?",
      "answer": "Lord Ganesha",
      "options": [
        "Lord Shiva",
        "Lord Vishnu",
        "Lord Brahma",
        "Lord Ganesha"
      ]
    },
    {
      "question": "What is the name of the sweet dish offered to Lord Ganesha during Ganesh Chaturthi?",
      "answer": "Modak",
      "options": [
        "Laddu",
        "Jalebi",
        "Modak",
        "Barfi"
      ]
    },
    {
      "question": "What is the duration of Ganesh Chaturthi?",
      "answer": "10 days",
      "options": [
        "5 days",
        "7 days",
        "10 days",
        "15 days"
      ]
    },
    {
      "question": "What is the name of the ritual performed before immersing the idol of Lord Ganesha?",
      "answer": "Visarjan",
      "options": [
        "Aarti",
        "Havan",
        "Visarjan",
        "Prasad"
      ]
    },
    {
      "question": "What is the name of the festival celebrated the day after Ganesh Chaturthi?",
      "answer": "Anant Chaturdashi",
      "options": [
        "Diwali",
        "Navratri",
        "Holi",
        "Anant Chaturdashi"
      ]
    },
    {
      "question": "What is the name of the special mud paste applied to the idols of Lord Ganesha?",
      "answer": "Shodashopachara",
      "options": [
        "Rudrabhishek",
        "Shodashopachara",
        "Ganesh Atharvashirsha",
        "Ganesh Sahasranama"
      ]
    },
    {
      "question": "What is the name of the instrument traditionally played during Ganesh Chaturthi processions?",
      "answer": "Dhol",
      "options": [
        "Tabla",
        "Sitar",
        "Dhol",
        "Flute"
      ]
    },
    {
      "question": "What is the name of the special pandals erected to house the idols of Lord Ganesha?",
      "answer": "Pandal",
      "options": [
        "Tent",
        "Pandal",
        "Shrine",
        "Temple"
      ]
    },
    {
      "question": "What is the name of the ritual observed on the final day of Ganesh Chaturthi?",
      "answer": "Uttarpuja",
      "options": [
        "Ganesh Visarjan",
        "Ganesh Aarti",
        "Uttarpuja",
        "Prasad distribution"
      ]
    },
    {
      "question": "What is the name of the special dance performed during Ganesh Chaturthi celebrations in Maharashtra?",
      "answer": "Lezim",
      "options": [
        "Garba",
        "Lezim",
        "Bihu",
        "Kuchipudi"
      ]
    },
    {
      "question": "What is the name of the traditional food item served as Prasad during Ganesh Chaturthi?",
      "answer": "Modak",
      "options": [
        "Laddu",
        "Modak",
        "Kheer",
        "Puri"
      ]
    }
  ]
},

//Fun Maths
{ 
  id: 12, 
  img: "funmaths.png", 
  category: "Fun Maths", 
  name:"Addition",
  questionList: [
      {
        "question": "What is 432 + 789?",
        "answer": "1221",
        "options": ["1111", "1221", "1331", "1441"]
      },
      {
        "question": "What is 5678 + 1234?",
        "answer": "6912",
        "options": ["6789", "6912", "7035", "7258"]
      },
      {
        "question": "What is 9876 + 5432?",
        "answer": "15308",
        "options": ["15008", "15308", "15608", "16008"]
      },
      {
        "question": "What is 98765 + 43210?",
        "answer": "141875",
        "options": ["137765", "141875", "146985", "151875"]
      },
      {
        "question": "What is 987654 + 123456?",
        "answer": "1111110",
        "options": ["1111100", "1111110", "1111120", "1111130"]
      },
      {
        "question": "What is 54321 + 98765?",
        "answer": "153986",
        "options": ["151986", "153986", "155986", "157986"]
      },
      {
        "question": "What is 1234567 + 7654321?",
        "answer": "8888888",
        "options": ["8777777", "8888888", "8999999", "9000000"]
      },
      {
        "question": "What is 9876543 + 3456789?",
        "answer": "13333332",
        "options": ["13333322", "13333332", "13333342", "13333352"]
      },
      {
        "question": "What is 12345678 + 87654321?",
        "answer": "99999999",
        "options": ["98999999", "99999999", "100000000", "101010101"]
      },
      {
        "question": "What is 11111111 + 22222222?",
        "answer": "33333333",
        "options": ["33333322", "33333333", "33333344", "33333355"]
      },
      {
        "question": "What is 99999999 + 12345678?",
        "answer": "112345677",
        "options": ["111234567", "112345677", "113456778", "114567889"]
      },
      {
        "question": "What is 31415926 + 27182818?",
        "answer": "58598744",
        "options": ["57598744", "58598744", "59598744", "60598744"]
      }
    ]
  },

  { 
    id: 13, 
    img: "funmaths.png", 
    category: "Fun Maths", 
    name:"Subtraction",
    questionList: [
        {
          "question": "What is 9876 - 5432?",
          "answer": "4444",
          "options": ["4356", "4444", "4532", "4658"]
        },
        {
          "question": "What is 98765 - 43210?",
          "answer": "55555",
          "options": ["52255", "55555", "58855", "61255"]
        },
        {
          "question": "What is 876543 - 123456?",
          "answer": "753087",
          "options": ["753087", "765432", "777777", "789012"]
        },
        {
          "question": "What is 9876543 - 3456789?",
          "answer": "6419754",
          "options": ["6419754", "6543210", "6666666", "6790122"]
        },
        {
          "question": "What is 98765432 - 12345678?",
          "answer": "86419754",
          "options": ["86419754", "87654321", "88888888", "90000000"]
        },
        {
          "question": "What is 99999999 - 12345678?",
          "answer": "87654321",
          "options": ["76543210", "87654321", "98765432", "109876543"]
        },
        {
          "question": "What is 31415926 - 27182818?",
          "answer": "4233108",
          "options": ["4233108", "4323108", "4332108", "4234108"]
        },
        {
          "question": "What is 987654321 - 123456789?",
          "answer": "864197532",
          "options": ["765432109", "864197532", "963061455", "107102643"]
        },
        {
          "question": "What is 9876543210 - 1234567890?",
          "answer": "8641975320",
          "options": ["7654321090", "8641975320", "9630614550", "1071026430"]
        },
        {
          "question": "What is 98765432100 - 1234567890?",
          "answer": "97530864210",
          "options": ["97530864210", "97530854210", "96530864210", "96530854210"]
        },
        {
          "question": "What is 1234567890 - 987654321?",
          "answer": "246913569",
          "options": ["236913569", "246913569", "256913569", "266913569"]
        },
        {
          "question": "What is 123456789 - 98765432?",
          "answer": "247911357",
          "options": ["247911357", "257911357", "267911357", "277911357"]
        }
      ]
    },

    { 
      id: 14, 
      img: "funmaths.png", 
      category: "Fun Maths", 
      name:"Multiplication",
      questionList: [
          {
            "question": "What is 12 × 11?",
            "answer": "132",
            "options": ["121", "132", "143", "154"]
          },
          {
            "question": "What is 23 × 14?",
            "answer": "322",
            "options": ["311", "322", "333", "344"]
          },
          {
            "question": "What is 34 × 25?",
            "answer": "850",
            "options": ["811", "822", "833", "850"]
          },
          {
            "question": "What is 45 × 36?",
            "answer": "1620",
            "options": ["1536", "1620", "1704", "1812"]
          },
          {
            "question": "What is 56 × 47?",
            "answer": "2632",
            "options": ["2543", "2632", "2721", "2810"]
          },
          {
            "question": "What is 67 × 58?",
            "answer": "3886",
            "options": ["3799", "3886", "3973", "4060"]
          },
          {
            "question": "What is 78 × 69?",
            "answer": "5382",
            "options": ["5295", "5382", "5479", "5566"]
          },
          {
            "question": "What is 89 × 70?",
            "answer": "6230",
            "options": ["6140", "6230", "6320", "6410"]
          },
          {
            "question": "What is 90 × 81?",
            "answer": "7290",
            "options": ["7180", "7290", "7380", "7470"]
          },
          {
            "question": "What is 101 × 92?",
            "answer": "9292",
            "options": ["9182", "9292", "9382", "9472"]
          },
          {
            "question": "What is 112 × 103?",
            "answer": "11536",
            "options": ["11423", "11536", "11649", "11762"]
          },
          {
            "question": "What is 123 × 114?",
            "answer": "14022",
            "options": ["13911", "14022", "14133", "14244"]
          }
      ]
    },

    { 
      id: 15, 
      img: "funmaths.png", 
      category: "Fun Maths", 
      name:"Division",
        questionList: [
          {
            "question": "What is 144 ÷ 12?",
            "answer": "12",
            "options": ["10", "12", "14", "16"]
          },
          {
            "question": "What is 245 ÷ 15?",
            "answer": "16.33",
            "options": ["15.88", "16.33", "16.77", "17.22"]
          },
          {
            "question": "What is 364 ÷ 26?",
            "answer": "14",
            "options": ["12", "14", "16", "18"]
          },
          {
            "question": "What is 489 ÷ 27?",
            "answer": "18.11",
            "options": ["17.34", "18.11", "18.88", "19.65"]
          },
          {
            "question": "What is 576 ÷ 32?",
            "answer": "18",
            "options": ["16", "18", "20", "22"]
          },
          {
            "question": "What is 625 ÷ 35?",
            "answer": "17.86",
            "options": ["17.02", "17.86", "18.70", "19.54"]
          },
          {
            "question": "What is 729 ÷ 43?",
            "answer": "16.95",
            "options": ["16.12", "16.95", "17.78", "18.61"]
          },
          {
            "question": "What is 841 ÷ 49?",
            "answer": "17.20",
            "options": ["16.90", "17.20", "17.50", "17.80"]
          },
          {
            "question": "What is 961 ÷ 53?",
            "answer": "18.13",
            "options": ["17.42", "18.13", "18.84", "19.55"]
          },
          {
            "question": "What is 1024 ÷ 64?",
            "answer": "16",
            "options": ["14", "16", "18", "20"]
          },
          {
            "question": "What is 1156 ÷ 68?",
            "answer": "17",
            "options": ["15", "17", "19", "21"]
          },
          {
            "question": "What is 1296 ÷ 72?",
            "answer": "18",
            "options": ["16", "18", "20", "22"]
          }
        ]
    },

    { 
      id: 16, 
      img: "funmaths.png", 
      category: "Fun Maths", 
      name:"Geometry",
      questionList: [
          {
            "question": "What is the area of a square with side length 6 units?",
            "answer": "36 square units",
            "options": ["24 square units", "30 square units", "36 square units", "42 square units"]
          },
          {
            "question": "What is the perimeter of a rectangle with length 8 units and width 5 units?",
            "answer": "26 units",
            "options": ["21 units", "24 units", "26 units", "30 units"]
          },
          {
            "question": "What is the circumference of a circle with radius 4 units? (Use π = 3.14)",
            "answer": "25.12 units",
            "options": ["12.56 units", "18.84 units", "25.12 units", "31.40 units"]
          },
          {
            "question": "What is the area of a triangle with base 10 units and height 6 units?",
            "answer": "30 square units",
            "options": ["24 square units", "30 square units", "36 square units", "42 square units"]
          },
          {
            "question": "What is the volume of a cube with side length 3 units?",
            "answer": "27 cubic units",
            "options": ["18 cubic units", "24 cubic units", "27 cubic units", "36 cubic units"]
          },
          {
            "question": "What is the surface area of a cylinder with radius 5 units and height 7 units? (Use π = 3.14)",
            "answer": "329.5 square units",
            "options": ["261.8 square units", "293.5 square units", "329.5 square units", "372.8 square units"]
          },
          {
            "question": "What is the perimeter of a regular hexagon with side length 4 units?",
            "answer": "24 units",
            "options": ["16 units", "20 units", "24 units", "28 units"]
          },
          {
            "question": "What is the area of a circle with diameter 10 units? (Use π = 3.14)",
            "answer": "78.5 square units",
            "options": ["50.2 square units", "62.8 square units", "78.5 square units", "94.2 square units"]
          },
          {
            "question": "What is the volume of a sphere with radius 6 units? (Use π = 3.14)",
            "answer": "904.32 cubic units",
            "options": ["678.24 cubic units", "752.88 cubic units", "904.32 cubic units", "1056.76 cubic units"]
          },
          {
            "question": "What is the surface area of a cone with radius 8 units and slant height 10 units? (Use π = 3.14)",
            "answer": "282.6 square units",
            "options": ["198.6 square units", "235.5 square units", "282.6 square units", "329.7 square units"]
          },
          {
            "question": "What is the perimeter of a rhombus with side length 7 units?",
            "answer": "28 units",
            "options": ["21 units", "24 units", "28 units", "32 units"]
          },
          {
            "question": "What is the area of a trapezoid with bases 6 units and 8 units and height 5 units?",
            "answer": "35 square units",
            "options": ["24 square units", "30 square units", "35 square units", "40 square units"]
          }
        ]
      },

      { 
        id: 17, 
        img: "funmaths.png", 
        category: "Fun Maths", 
        name:"Algebra",
          questionList: [
            {
              "question": "Simplify the expression: 5x + 3 - 2x",
              "answer": "3x + 3",
              "options": ["3x + 5", "3x + 3", "7x + 3", "5x + 3"]
            },
            {
              "question": "Solve for x: 2x - 7 = 11",
              "answer": "x = 9",
              "options": ["x = 4", "x = 7", "x = 9", "x = 11"]
            },
            {
              "question": "Simplify the expression: 4(x - 3) + 2",
              "answer": "4x - 10",
              "options": ["4x - 8", "4x - 10", "4x - 12", "4x - 14"]
            },
            {
              "question": "Solve for y: 3y + 5 = 20",
              "answer": "y = 5",
              "options": ["y = 3", "y = 5", "y = 7", "y = 10"]
            },
            {
              "question": "Evaluate the expression for x = 2: 3x² - 5x + 4",
              "answer": "10",
              "options": ["6", "8", "10", "12"]
            },
            {
              "question": "Solve for x: x² + 4x + 4 = 0",
              "answer": "x = -2",
              "options": ["x = -1", "x = -2", "x = -3", "x = -4"]
            },
            {
              "question": "Simplify the expression: (2x + 3)²",
              "answer": "4x² + 12x + 9",
              "options": ["4x² + 6x + 9", "4x² + 9", "4x² + 12x + 9", "4x² + 12x"]
            },
            {
              "question": "Solve for x: 5(x - 2) = 25",
              "answer": "x = 7",
              "options": ["x = 5", "x = 6", "x = 7", "x = 8"]
            },
            {
              "question": "Simplify the expression: 3(x² - 4)",
              "answer": "3x² - 12",
              "options": ["3x² - 4", "3x² - 8", "3x² - 12", "3x² - 16"]
            },
            {
              "question": "Solve for y: 2(y + 6) = 20",
              "answer": "y = 4",
              "options": ["y = 2", "y = 4", "y = 6", "y = 8"]
            },
            {
              "question": "Evaluate the expression for x = 3: x² - 2x + 5",
              "answer": "8",
              "options": ["6", "7", "8", "9"]
            },
            {
              "question": "Solve for x: 3x - 2 = 4x + 1",
              "answer": "x = -3",
              "options": ["x = -1", "x = -2", "x = -3", "x = -4"]
            }
          ]
        },

//English Grammar
{ 
  id: 18, 
  img: "englishgrammar.png", 
  category: "English Grammar", 
  name:"Parts of Speech",
   questionList: [
      {
        "question": "Identify the part of speech for the word 'happy'.",
        "answer": "Adjective",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "Which part of speech is 'quickly'?",
        "answer": "Adverb",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "In the sentence 'She loves to dance.', what is the part of speech of 'to dance'?",
        "answer": "Infinitive",
        "options": ["Verb", "Noun", "Adjective", "Infinitive"]
      },
      {
        "question": "Identify the part of speech for the word 'run'.",
        "answer": "Verb",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "What is the part of speech of 'underneath'?",
        "answer": "Preposition",
        "options": ["Noun", "Verb", "Adjective", "Preposition"]
      },
      {
        "question": "Which part of speech is 'happily'?",
        "answer": "Adverb",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "In the sentence 'The cat sat on the mat.', what is the part of speech of 'on'?",
        "answer": "Preposition",
        "options": ["Verb", "Noun", "Adjective", "Preposition"]
      },
      {
        "question": "Identify the part of speech for the word 'book'.",
        "answer": "Noun",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "Which part of speech is 'very'?",
        "answer": "Adverb",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      },
      {
        "question": "In the sentence 'She is intelligent.', what is the part of speech of 'intelligent'?",
        "answer": "Adjective",
        "options": ["Verb", "Noun", "Adjective", "Adverb"]
      },
      {
        "question": "What is the part of speech of 'above'?",
        "answer": "Preposition",
        "options": ["Noun", "Verb", "Adjective", "Preposition"]
      },
      {
        "question": "Identify the part of speech for the word 'sing'.",
        "answer": "Verb",
        "options": ["Noun", "Verb", "Adjective", "Adverb"]
      }
    ]
  },

  { 
    id: 19, 
    img: "englishgrammar.png", 
    category: "English Grammar", 
    name:"Sentence Structure",
    questionList: [
        {
          "question": "Which word is the subject in the sentence 'The dog barks loudly.'?",
          "answer": "Dog",
          "options": ["The", "Dog", "Barks", "Loudly"]
        },
        {
          "question": "What is the predicate in the sentence 'I enjoy playing tennis.'?",
          "answer": "Enjoy playing tennis",
          "options": ["I", "Enjoy", "Playing tennis", "Tennis"]
        },
        {
          "question": "In the sentence 'The sun shines brightly.', what is the object?",
          "answer": "Sun",
          "options": ["The", "Sun", "Shines", "Brightly"]
        },
        {
          "question": "Identify the direct object in the sentence 'She bought a new car.'.",
          "answer": "Car",
          "options": ["She", "Bought", "A new", "Car"]
        },
        {
          "question": "What is the subject complement in the sentence 'She is a doctor.'?",
          "answer": "Doctor",
          "options": ["She", "Is", "A", "Doctor"]
        },
        {
          "question": "Which word serves as the object of the preposition in the sentence 'The book is on the table.'?",
          "answer": "Table",
          "options": ["The", "Book", "Is", "Table"]
        },
        {
          "question": "In the sentence 'John and Mary went to the store.', what is the compound subject?",
          "answer": "John and Mary",
          "options": ["John", "Mary", "Went", "John and Mary"]
        },
        {
          "question": "What is the object complement in the sentence 'They elected her president.'?",
          "answer": "President",
          "options": ["They", "Elected", "Her", "President"]
        },
        {
          "question": "Identify the indirect object in the sentence 'The teacher gave the students an assignment.'.",
          "answer": "Students",
          "options": ["The teacher", "Gave", "An assignment", "Students"]
        },
        {
          "question": "Which word serves as the object of the preposition in the sentence 'The cat slept under the bed.'?",
          "answer": "Bed",
          "options": ["The", "Cat", "Slept", "Bed"]
        },
        {
          "question": "In the sentence 'The flowers smelled sweet.', what is the predicate adjective?",
          "answer": "Sweet",
          "options": ["The", "Flowers", "Smelled", "Sweet"]
        },
        {
          "question": "What is the object of the gerund in the sentence 'She enjoys swimming.'?",
          "answer": "Swimming",
          "options": ["She", "Enjoys", "Swimming", "Enjoyment"]
        }
      ]
    },

    { 
      id: 20, 
      img: "englishgrammar.png", 
      category: "English Grammar", 
      name:"Punctuation",
        questionList: [
          {
            "question": "Which punctuation mark is used to indicate the end of a sentence?",
            "answer": "Period (.)",
            "options": ["Comma (,)", "Exclamation mark (!)", "Question mark (?)", "Period (.)"]
          },
          {
            "question": "What punctuation mark is used to separate items in a list?",
            "answer": "Comma (,)",
            "options": ["Semicolon (;)", "Colon (:) ", "Dash (-)", "Comma (,)"]
          },
          {
            "question": "In the sentence 'Wow! What a great performance!', what is the punctuation mark after 'Wow'?",
            "answer": "Exclamation mark (!)",
            "options": ["Period (.)", "Comma (,)", "Question mark (?)", "Exclamation mark (!)"]
          },
          {
            "question": "Identify the punctuation mark used to indicate a pause or break within a sentence for emphasis.",
            "answer": "Em dash (—)",
            "options": ["Colon (:) ", "Hyphen (-)", "En dash (–)", "Em dash (—)"]
          },
          {
            "question": "Which punctuation mark is used to indicate a possessive noun?",
            "answer": "Apostrophe (')",
            "options": ["Quotation marks (\")", "Hyphen (-)", "Parentheses (())", "Apostrophe (')"]
          },
          {
            "question": "In the sentence 'Where are you going?', what is the punctuation mark at the end?",
            "answer": "Question mark (?)",
            "options": ["Period (.)", "Exclamation mark (!)", "Comma (,)", "Question mark (?)"]
          },
          {
            "question": "What punctuation mark is used to indicate a quote or direct speech?",
            "answer": "Quotation marks (\")",
            "options": ["Apostrophe (')", "Hyphen (-)", "Colon (:) ", "Quotation marks (\")"]
          },
          {
            "question": "Identify the punctuation mark used to separate independent clauses in a compound sentence.",
            "answer": "Semicolon (;)",
            "options": ["Colon (:) ", "Hyphen (-)", "Comma (,)", "Semicolon (;)"]
          },
          {
            "question": "Which punctuation mark is used to indicate a pause shorter than that of a semicolon?",
            "answer": "Comma (,)",
            "options": ["Colon (:) ", "Hyphen (-)", "Semicolon (;)", "Comma (,)"]
          },
          {
            "question": "In the sentence 'My friend's cat is missing.', what is the punctuation mark after 'friend's'?",
            "answer": "Apostrophe (')",
            "options": ["Quotation marks (\")", "Hyphen (-)", "Parentheses (())", "Apostrophe (')"]
          },
          {
            "question": "Identify the punctuation mark used to indicate an interruption in a sentence or a trailing-off of thought.",
            "answer": "Ellipsis (...) ",
            "options": ["Colon (:) ", "Hyphen (-)", "Semicolon (;)", "Ellipsis (...) "]
          },
          {
            "question": "What punctuation mark is used to indicate a title, subtitle, or emphasis?",
            "answer": "Colon (:) ",
            "options": ["Semicolon (;)", "Hyphen (-)", "Period (.)", "Colon (:) "]
          }
        ]
       
      },

    { 
      id: 21, 
      img: "englishgrammar.png", 
      category: "English Grammar", 
      name:"Tenses",
      questionList: [
            {
              "question": "What tense is used to express actions that are happening now?",
              "answer": "Present tense",
              "options": ["Past tense", "Future tense", "Present tense", "Perfect tense"]
            },
            {
              "question": "In the sentence 'He is eating dinner.', what tense is used for 'is eating'?",
              "answer": "Present continuous tense",
              "options": ["Present perfect tense", "Present continuous tense", "Past continuous tense", "Future continuous tense"]
            },
            {
              "question": "What tense is used to express actions that will happen in the future?",
              "answer": "Future tense",
              "options": ["Present tense", "Past tense", "Future tense", "Future perfect tense"]
            },
            {
              "question": "Identify the tense used in the sentence 'She had already finished her homework.'.",
              "answer": "Past perfect tense",
              "options": ["Present perfect tense", "Past perfect tense", "Future perfect tense", "Past tense"]
            },
            {
              "question": "Which tense is used to describe actions that were completed before a certain moment in the past?",
              "answer": "Past perfect tense",
              "options": ["Present perfect tense", "Past perfect tense", "Future perfect tense", "Past tense"]
            },
            {
              "question": "In the sentence 'I will have completed my assignment by tomorrow.', what tense is used for 'will have completed'?",
              "answer": "Future perfect tense",
              "options": ["Present perfect tense", "Future perfect tense", "Past perfect tense", "Future tense"]
            },
            {
              "question": "What tense is used to express actions that are habitual or regular occurrences?",
              "answer": "Present simple tense",
              "options": ["Present continuous tense", "Present simple tense", "Past simple tense", "Future simple tense"]
            },
            {
              "question": "Identify the tense in the sentence 'She will be arriving at 6 p.m.'.",
              "answer": "Future continuous tense",
              "options": ["Present continuous tense", "Future continuous tense", "Past continuous tense", "Future tense"]
            },
            {
              "question": "Which tense is used to describe actions that were ongoing at a specific time in the past?",
              "answer": "Past continuous tense",
              "options": ["Present continuous tense", "Past continuous tense", "Future continuous tense", "Past tense"]
            },
            {
              "question": "In the sentence 'They have been waiting for an hour.', what tense is used for 'have been waiting'?",
              "answer": "Present perfect continuous tense",
              "options": ["Present perfect continuous tense", "Past perfect continuous tense", "Future perfect continuous tense", "Present perfect tense"]
            },
            {
              "question": "What tense is used to describe actions that will be ongoing at a specific time in the future?",
              "answer": "Future continuous tense",
              "options": ["Present continuous tense", "Future continuous tense", "Past continuous tense", "Future tense"]
            },
            {
              "question": "Identify the tense used in the sentence 'She was studying when I called her.'.",
              "answer": "Past continuous tense",
              "options": ["Present continuous tense", "Past continuous tense", "Future continuous tense", "Past tense"]
            }
      ]
    },

    { 
       id: 22, 
       img: "englishgrammar.png", 
       category: "English Grammar", 
       name:"Subject-Verb Agreement",
       questionList: [
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) She play tennis every day. b) She plays tennis every day.",
                "answer": "She plays tennis every day.",
                "options": ["She play tennis every day.", "She plays tennis every day.", "She playing tennis every day.", "She playings tennis every day."]
              },
              {
                "question": "In the sentence 'The dog and the cat (is/are) playing in the garden.', which verb form is correct?",
                "answer": "Are",
                "options": ["Is", "Are", "Was", "Were"]
              },
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) He do his homework after school. b) He does his homework after school.",
                "answer": "He does his homework after school.",
                "options": ["He do his homework after school.", "He does his homework after school.", "He doing his homework after school.", "He doing his homeworks after school."]
              },
              {
                "question": "In the sentence 'My friend or his brothers (is/are) coming to the party.', which verb form is correct?",
                "answer": "Are",
                "options": ["Is", "Are", "Was", "Were"]
              },
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) The book on the table is interesting. b) The book on the table are interesting.",
                "answer": "The book on the table is interesting.",
                "options": ["The book on the table is interesting.", "The book on the table are interesting.", "The books on the table is interesting.", "The books on the table are interesting."]
              },
              {
                "question": "In the sentence 'The team (play/plays) well together.', which verb form is correct?",
                "answer": "Plays",
                "options": ["Play", "Plays", "Played", "Playing"]
              },
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) Neither of the girls want to go to the party. b) Neither of the girls wants to go to the party.",
                "answer": "Neither of the girls wants to go to the party.",
                "options": ["Neither of the girls want to go to the party.", "Neither of the girls wants to go to the party.", "Neither of the girls wanting to go to the party.", "Neither of the girls wanted to go to the party."]
              },
              {
                "question": "In the sentence 'One of the students (has/have) forgotten his books.', which verb form is correct?",
                "answer": "Has",
                "options": ["Has", "Have", "Had", "Having"]
              },
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) Mathematics are my favorite subject. b) Mathematics is my favorite subject.",
                "answer": "Mathematics is my favorite subject.",
                "options": ["Mathematics are my favorite subject.", "Mathematics is my favorite subject.", "Mathematics be my favorite subject.", "Mathematics were my favorite subject."]
              },
              {
                "question": "In the sentence 'The news (is/are) interesting.', which verb form is correct?",
                "answer": "Is",
                "options": ["Is", "Are", "Was", "Were"]
              },
              {
                "question": "Which sentence demonstrates correct subject-verb agreement? a) The cat and the dog is sleeping. b) The cat and the dog are sleeping.",
                "answer": "The cat and the dog are sleeping.",
                "options": ["The cat and the dog is sleeping.", "The cat and the dog are sleeping.", "The cats and the dog are sleeping.", "The cat and the dogs are sleeping."]
              },
              {
                "question": "In the sentence 'Neither the dishes nor the glasses (need/needs) to be washed.', which verb form is correct?",
                "answer": "Need",
                "options": ["Need", "Needs", "Needed", "Needing"]
              }
            ]
          },

      { 
        id: 23, 
        img: "englishgrammar.png", 
        category: "English Grammar", 
        name:"Modifiers",
       questionList: [
                {
                  "question": "Which sentence uses the adverb 'quickly' correctly? a) She quickly ran to the store. b) She ran quickly to the store.",
                  "answer": "She quickly ran to the store.",
                  "options": ["She quickly ran to the store.", "She ran quickly to the store.", "She ran to the store quickly.", "She ran to the quickly store."]
                },
                {
                  "question": "Which sentence uses the adjective 'blue' correctly? a) The cat is blue. b) The blue cat jumped over the fence.",
                  "answer": "The blue cat jumped over the fence.",
                  "options": ["The cat is blue.", "The blue cat jumped over the fence.", "The cat jumped over the blue fence.", "The cat jumped blue over the fence."]
                },
                {
                  "question": "Which sentence uses the adverb 'carefully' correctly? a) She carefully drew a picture. b) She drew a picture carefully.",
                  "answer": "She carefully drew a picture.",
                  "options": ["She carefully drew a picture.", "She drew a picture carefully.", "She drew carefully a picture.", "She drew a carefully picture."]
                },
                {
                  "question": "Which sentence uses the adverb 'often' correctly? a) He is often late for class. b) He often is late for class.",
                  "answer": "He is often late for class.",
                  "options": ["He is often late for class.", "He often is late for class.", "He late is often for class.", "He often late is for class."]
                },
                {
                  "question": "Which sentence uses the adverb 'slowly' correctly? a) She reads slowly. b) Slowly she reads.",
                  "answer": "She reads slowly.",
                  "options": ["She reads slowly.", "Slowly she reads.", "She slowly reads.", "Reads she slowly."]
                },
                {
                  "question": "Which sentence uses the adjective 'beautiful' correctly? a) The sun set was beautiful. b) The beautiful sun set in the evening.",
                  "answer": "The beautiful sun set in the evening.",
                  "options": ["The sun set was beautiful.", "The beautiful sun set in the evening.", "The sun beautiful set in the evening.", "The sun set beautiful in the evening."]
                },
                {
                  "question": "Which sentence uses the adverb 'eagerly' correctly? a) She eagerly completed her homework. b) Eagerly she completed her homework.",
                  "answer": "She eagerly completed her homework.",
                  "options": ["She eagerly completed her homework.", "Eagerly she completed her homework.", "She completed her homework eagerly.", "She completed eagerly her homework."]
                },
                {
                  "question": "Which sentence uses the adjective 'huge' correctly? a) The huge elephant in the zoo. b) The elephant in the zoo was huge.",
                  "answer": "The elephant in the zoo was huge.",
                  "options": ["The huge elephant in the zoo.", "The elephant in the zoo was huge.", "The huge elephant was in the zoo.", "The elephant huge in the zoo."]
                },
                {
                  "question": "Which sentence uses the adverb 'carefully' correctly? a) He listens to the teacher carefully. b) Carefully he listens to the teacher.",
                  "answer": "He listens to the teacher carefully.",
                  "options": ["He listens to the teacher carefully.", "Carefully he listens to the teacher.", "He carefully listens to the teacher.", "He listens carefully to the teacher."]
                },
                {
                  "question": "Which sentence uses the adjective 'happy' correctly? a) The girl was happy with the gift. b) The happy girl received a gift.",
                  "answer": "The girl was happy with the gift.",
                  "options": ["The girl was happy with the gift.", "The happy girl received a gift.", "The girl received a happy gift.", "The girl happy was with the gift."]
                },
                {
                  "question": "Which sentence uses the adverb 'loudly' correctly? a) She loudly sang a song. b) Sang she a song loudly.",
                  "answer": "She loudly sang a song.",
                  "options": ["She loudly sang a song.", "Sang she a song loudly.", "She sang a song loudly.", "She sang loudly a song."]
                },
                {
                  "question": "Which sentence uses the adjective 'small' correctly? a) The small bird sang sweetly. b) The bird was small and brown.",
                  "answer": "The bird was small and brown.",
                  "options": ["The small bird sang sweetly.", "The bird was small and brown.", "The bird was small sang sweetly.", "The bird sang sweetly small."]
                }
              ]
       },
  
       //Food & Beverages
  
       { 
        id: 24, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Fruits",
        questionList: [
            {
              "question": "Which fruit is known as the 'king of fruits'?",
              "answer": "Mango",
              "options": ["Apple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit has varieties like Valencia, Navel, and Blood?",
              "answer": "Orange",
              "options": ["Apple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is known for its yellow peel and curved shape?",
              "answer": "Banana",
              "options": ["Apple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is a symbol of hospitality and has varieties like Gala and Fuji?",
              "answer": "Apple",
              "options": ["Apple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is commonly used in making guacamole?",
              "answer": "Avocado",
              "options": ["Avocado", "Banana", "Mango", "Pineapple"]
            },
            {
              "question": "Which fruit is known for its rough, brown exterior and sweet, creamy interior?",
              "answer": "Kiwi",
              "options": ["Kiwi", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is also known as a 'custard apple'?",
              "answer": "Cherimoya",
              "options": ["Cherimoya", "Banana", "Mango", "Pineapple"]
            },
            {
              "question": "Which fruit is often used to make pies and is known for its tart flavor?",
              "answer": "Apple",
              "options": ["Apple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is a cross between a blackberry and a raspberry?",
              "answer": "Boysenberry",
              "options": ["Boysenberry", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is commonly found in tropical regions and is often used in desserts?",
              "answer": "Pineapple",
              "options": ["Pineapple", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is known for its distinct aroma and is often used in perfumes and flavorings?",
              "answer": "Guava",
              "options": ["Guava", "Banana", "Mango", "Orange"]
            },
            {
              "question": "Which fruit is native to China and comes in varieties like Lychee and Rambutan?",
              "answer": "Lychee",
              "options": ["Lychee", "Banana", "Mango", "Orange"]
            }
          ]
       },

       { 
        id: 25, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Vegetables",
        questionList: [
            {
              "question": "Which vegetable is often used as a base for soups and stews?",
              "answer": "Carrot",
              "options": ["Carrot", "Broccoli", "Cabbage", "Spinach"]
            },
            {
              "question": "Which vegetable is commonly used to make pickles and is known for its green color?",
              "answer": "Cucumber",
              "options": ["Carrot", "Broccoli", "Cucumber", "Spinach"]
            },
            {
              "question": "Which vegetable is often roasted or mashed and served as a side dish?",
              "answer": "Potato",
              "options": ["Carrot", "Broccoli", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is a staple in Italian cuisine and is used to make sauces and salads?",
              "answer": "Tomato",
              "options": ["Carrot", "Tomato", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is known for its distinct aroma and is commonly used in stir-fries?",
              "answer": "Garlic",
              "options": ["Carrot", "Garlic", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is often used as a topping on pizzas and is a good source of vitamin C?",
              "answer": "Bell pepper",
              "options": ["Carrot", "Bell pepper", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is commonly used in Indian cuisine and comes in varieties like baby, curly, and Tuscan?",
              "answer": "Kale",
              "options": ["Carrot", "Kale", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is often sliced and used in sandwiches and salads?",
              "answer": "Lettuce",
              "options": ["Carrot", "Lettuce", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is known for its long, slender shape and is often used in Asian dishes?",
              "answer": "Bean sprouts",
              "options": ["Carrot", "Bean sprouts", "Potato", "Spinach"]
            },
            {
              "question": "Which vegetable is used to make sauerkraut and is a good source of vitamin C?",
              "answer": "Cabbage",
              "options": ["Carrot", "Broccoli", "Cabbage", "Spinach"]
            },
            {
              "question": "Which vegetable is often used in Mediterranean cuisine and is a good source of fiber?",
              "answer": "Eggplant",
              "options": ["Carrot", "Broccoli", "Eggplant", "Spinach"]
            },
            {
              "question": "Which vegetable is often boiled, mashed, and served with butter?",
              "answer": "Turnip",
              "options": ["Carrot", "Broccoli", "Turnip", "Spinach"]
            }
          ]
       },
       
       { 
        id: 26, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Beverages",
        questionList: [
            {
              "question": "Which beverage is made from brewed coffee beans?",
              "answer": "Coffee",
              "options": ["Coffee", "Tea", "Orange juice", "Milk"]
            },
            {
              "question": "Which beverage is made from the leaves of Camellia sinensis plant?",
              "answer": "Tea",
              "options": ["Coffee", "Tea", "Orange juice", "Milk"]
            },
            {
              "question": "Which beverage is a popular breakfast drink made from citrus fruits?",
              "answer": "Orange juice",
              "options": ["Coffee", "Tea", "Orange juice", "Milk"]
            },
            {
              "question": "Which beverage is a white liquid produced by mammals?",
              "answer": "Milk",
              "options": ["Coffee", "Tea", "Orange juice", "Milk"]
            },
            {
              "question": "Which beverage is made by fermenting grapes?",
              "answer": "Wine",
              "options": ["Wine", "Tea", "Orange juice", "Milk"]
            },
            {
              "question": "Which beverage is a carbonated soft drink flavored with vanilla and other flavorings?",
              "answer": "Cream soda",
              "options": ["Coffee", "Tea", "Orange juice", "Cream soda"]
            },
            {
              "question": "Which beverage is made by mixing water and cocoa powder or melted chocolate?",
              "answer": "Hot chocolate",
              "options": ["Coffee", "Tea", "Hot chocolate", "Milk"]
            },
            {
              "question": "Which beverage is a sweetened beverage made from lemons, sugar, and water?",
              "answer": "Lemonade",
              "options": ["Coffee", "Tea", "Orange juice", "Lemonade"]
            },
            {
              "question": "Which beverage is made from fermented apple juice?",
              "answer": "Cider",
              "options": ["Coffee", "Tea", "Orange juice", "Cider"]
            },
            {
              "question": "Which beverage is made from the fermented juice of sugarcane?",
              "answer": "Rum",
              "options": ["Coffee", "Tea", "Orange juice", "Rum"]
            },
            {
              "question": "Which beverage is made by fermenting a mixture of water, honey, yeast, and fruit juice?",
              "answer": "Mead",
              "options": ["Coffee", "Tea", "Orange juice", "Mead"]
            },
            {
              "question": "Which beverage is made by steeping dried chamomile flowers in hot water?",
              "answer": "Chamomile tea",
              "options": ["Coffee", "Tea", "Orange juice", "Chamomile tea"]
            }
          ]
       },

       { 
        id: 27, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Desserts",
        questionList: [
            {
              "question": "Which dessert is made from frozen fruit juice or flavored water?",
              "answer": "Popsicle",
              "options": ["Popsicle", "Ice cream", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is a frozen dessert made from cream, sugar, and flavorings?",
              "answer": "Ice cream",
              "options": ["Popsicle", "Ice cream", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is a sweet baked food made from flour, sugar, and eggs?",
              "answer": "Cake",
              "options": ["Popsicle", "Ice cream", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is a small cake baked in a cup-shaped container?",
              "answer": "Cupcake",
              "options": ["Popsicle", "Ice cream", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is made from whipped egg whites and sugar, often flavored or colored?",
              "answer": "Meringue",
              "options": ["Popsicle", "Meringue", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is a sweet baked food made from flour, sugar, and butter or oil?",
              "answer": "Cookie",
              "options": ["Cookie", "Meringue", "Cake", "Cupcake"]
            },
            {
              "question": "Which dessert is a sweet dish consisting of layers of puff pastry filled with cream, fruit, or other fillings?",
              "answer": "Napoleon",
              "options": ["Cookie", "Meringue", "Napoleon", "Cupcake"]
            },
            {
              "question": "Which dessert is a sweet, sticky, jelly-like dessert made from sugar and fruit juice?",
              "answer": "Jelly",
              "options": ["Cookie", "Meringue", "Jelly", "Cupcake"]
            },
            {
              "question": "Which dessert is a sweet, baked, yeast-leavened bread commonly eaten at breakfast or as a snack?",
              "answer": "Doughnut",
              "options": ["Cookie", "Meringue", "Jelly", "Doughnut"]
            },
            {
              "question": "Which dessert is a cold dessert made from whipped cream, sugar, and gelatin?",
              "answer": "Mousse",
              "options": ["Cookie", "Mousse", "Jelly", "Doughnut"]
            },
            {
              "question": "Which dessert is a type of sweet, baked pastry filled with fruit, jam, or cream?",
              "answer": "Pie",
              "options": ["Pie", "Mousse", "Jelly", "Doughnut"]
            },
            {
              "question": "Which dessert is a sweet, baked, bread-like food made from flour, water, and yeast?",
              "answer": "Bread pudding",
              "options": ["Pie", "Mousse", "Bread pudding", "Doughnut"]
            }
          ]
       },

       { 
        id: 28, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Snacks",
        questionList: [
            {
              "question": "Which snack is made from roasted or fried corn kernels?",
              "answer": "Popcorn",
              "options": ["Popcorn", "Potato chips", "Pretzels", "Peanuts"]
            },
            {
              "question": "Which snack is made from thinly sliced, fried potatoes?",
              "answer": "Potato chips",
              "options": ["Popcorn", "Potato chips", "Pretzels", "Peanuts"]
            },
            {
              "question": "Which snack is a type of baked bread product made from dough?",
              "answer": "Pretzels",
              "options": ["Popcorn", "Potato chips", "Pretzels", "Peanuts"]
            },
            {
              "question": "Which snack is a legume that is roasted and eaten as a snack?",
              "answer": "Peanuts",
              "options": ["Popcorn", "Potato chips", "Pretzels", "Peanuts"]
            },
            {
              "question": "Which snack is made from fried or roasted pieces of dough?",
              "answer": "Chips",
              "options": ["Popcorn", "Chips", "Pretzels", "Peanuts"]
            },
            {
              "question": "Which snack is made from deep-fried dough, often sweetened and coated with sugar or cinnamon?",
              "answer": "Churros",
              "options": ["Popcorn", "Chips", "Churros", "Peanuts"]
            },
            {
              "question": "Which snack is made from roasted, salted chickpeas?",
              "answer": "Roasted chickpeas",
              "options": ["Popcorn", "Chips", "Roasted chickpeas", "Peanuts"]
            },
            {
              "question": "Which snack is made from fried or baked slices of potatoes, often seasoned with salt or other spices?",
              "answer": "French fries",
              "options": ["Popcorn", "French fries", "Roasted chickpeas", "Peanuts"]
            },
            {
              "question": "Which snack is a sweet, fried dough pastry often topped with sugar or honey?",
              "answer": "Donut",
              "options": ["Popcorn", "Donut", "Roasted chickpeas", "Peanuts"]
            },
            {
              "question": "Which snack is a deep-fried dough ball, often filled with meat, cheese, or vegetables?",
              "answer": "Arancini",
              "options": ["Popcorn", "Arancini", "Roasted chickpeas", "Peanuts"]
            },
            {
              "question": "Which snack is a type of flat, oven-baked or fried bread product?",
              "answer": "Cracker",
              "options": ["Cracker", "Arancini", "Roasted chickpeas", "Peanuts"]
            },
            {
              "question": "Which snack is a sweet pastry of Italian origin, often flavored with anise?",
              "answer": "Taralli",
              "options": ["Cracker", "Taralli", "Roasted chickpeas", "Peanuts"]
            }
          ]
       },

       { 
        id: 29, 
        img: "foodandbeverages.png", 
        category: "Food & Beverages", 
        name:"Cuisines",
        questionList: [
            {
              "question": "Which cuisine is known for its use of spices like cumin, coriander, and turmeric?",
              "answer": "Indian cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its pasta dishes and use of ingredients like tomatoes and olive oil?",
              "answer": "Italian cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like soy sauce, garlic, and ginger?",
              "answer": "Chinese cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like corn, beans, and chili peppers?",
              "answer": "Mexican cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of spices like cinnamon, cardamom, and cloves?",
              "answer": "Middle Eastern cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Middle Eastern cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like rice, coconut, and seafood?",
              "answer": "Thai cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Thai cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its grilled meats, hummus, and falafel?",
              "answer": "Middle Eastern cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Middle Eastern cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like corn tortillas, beans, and avocado?",
              "answer": "Mexican cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of spices like garam masala, turmeric, and fenugreek?",
              "answer": "Indian cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like pasta, cheese, and tomatoes?",
              "answer": "Italian cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Mexican cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like tofu, soy sauce, and rice vinegar?",
              "answer": "Japanese cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Japanese cuisine", "Indian cuisine"]
            },
            {
              "question": "Which cuisine is known for its use of ingredients like couscous, dates, and lamb?",
              "answer": "Moroccan cuisine",
              "options": ["Italian cuisine", "Chinese cuisine", "Moroccan cuisine", "Indian cuisine"]
            }
          ]
       },
  
//Vocabulary
{ 
  id: 30, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Synonyms",
  questionList: [
      {
        "question": "What is a synonym for 'happy'?",
        "answer": "Joyful",
        "options": ["Sad", "Joyful", "Angry", "Excited"]
      },
      {
        "question": "What is a synonym for 'big'?",
        "answer": "Large",
        "options": ["Small", "Large", "Tiny", "Huge"]
      },
      {
        "question": "What is a synonym for 'beautiful'?",
        "answer": "Attractive",
        "options": ["Ugly", "Attractive", "Plain", "Gorgeous"]
      },
      {
        "question": "What is a synonym for 'angry'?",
        "answer": "Furious",
        "options": ["Happy", "Furious", "Sad", "Calm"]
      },
      {
        "question": "What is a synonym for 'smart'?",
        "answer": "Intelligent",
        "options": ["Dumb", "Intelligent", "Stupid", "Wise"]
      },
      {
        "question": "What is a synonym for 'tired'?",
        "answer": "Exhausted",
        "options": ["Energetic", "Exhausted", "Refreshed", "Lively"]
      },
      {
        "question": "What is a synonym for 'fast'?",
        "answer": "Quick",
        "options": ["Slow", "Quick", "Rapid", "Swift"]
      },
      {
        "question": "What is a synonym for 'difficult'?",
        "answer": "Challenging",
        "options": ["Easy", "Challenging", "Simple", "Hard"]
      },
      {
        "question": "What is a synonym for 'old'?",
        "answer": "Ancient",
        "options": ["New", "Ancient", "Young", "Elderly"]
      },
      {
        "question": "What is a synonym for 'happy'?",
        "answer": "Content",
        "options": ["Sad", "Content", "Joyful", "Excited"]
      },
      {
        "question": "What is a synonym for 'big'?",
        "answer": "Enormous",
        "options": ["Small", "Enormous", "Tiny", "Huge"]
      },
      {
        "question": "What is a synonym for 'beautiful'?",
        "answer": "Stunning",
        "options": ["Ugly", "Stunning", "Plain", "Gorgeous"]
      }
    ]
 },

 { 
  id: 31, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Antonyms",
  questionList: [
      {
        "question": "What is an antonym for 'good'?",
        "answer": "Bad",
        "options": ["Great", "Bad", "Happy", "Excellent"]
      },
      {
        "question": "What is an antonym for 'hot'?",
        "answer": "Cold",
        "options": ["Warm", "Cold", "Sizzling", "Burning"]
      },
      {
        "question": "What is an antonym for 'beautiful'?",
        "answer": "Ugly",
        "options": ["Pretty", "Ugly", "Attractive", "Gorgeous"]
      },
      {
        "question": "What is an antonym for 'happy'?",
        "answer": "Sad",
        "options": ["Joyful", "Sad", "Excited", "Content"]
      },
      {
        "question": "What is an antonym for 'fast'?",
        "answer": "Slow",
        "options": ["Quick", "Slow", "Rapid", "Swift"]
      },
      {
        "question": "What is an antonym for 'difficult'?",
        "answer": "Easy",
        "options": ["Hard", "Easy", "Challenging", "Simple"]
      },
      {
        "question": "What is an antonym for 'old'?",
        "answer": "New",
        "options": ["Ancient", "New", "Young", "Elderly"]
      },
      {
        "question": "What is an antonym for 'light'?",
        "answer": "Dark",
        "options": ["Bright", "Dark", "Glowing", "Shining"]
      },
      {
        "question": "What is an antonym for 'big'?",
        "answer": "Small",
        "options": ["Large", "Small", "Tiny", "Huge"]
      },
      {
        "question": "What is an antonym for 'easy'?",
        "answer": "Difficult",
        "options": ["Simple", "Difficult", "Challenging", "Hard"]
      },
      {
        "question": "What is an antonym for 'happy'?",
        "answer": "Miserable",
        "options": ["Joyful", "Miserable", "Excited", "Content"]
      },
      {
        "question": "What is an antonym for 'strong'?",
        "answer": "Weak",
        "options": ["Powerful", "Weak", "Sturdy", "Mighty"]
      }
    ]
 },

 { 
  id: 32, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Homophones",
  questionList: [
      {
        "question": "Which word sounds the same as 'meet' but means to come together?",
        "answer": "Meet",
        "options": ["Meat", "Meet", "Met", "Me"]
      },
      {
        "question": "Which word sounds the same as 'write' but means a legal document?",
        "answer": "Write",
        "options": ["Right", "Write", "Rite", "Wright"]
      },
      {
        "question": "Which word sounds the same as 'flower' but means to diminish or lose strength?",
        "answer": "Flour",
        "options": ["Flour", "Flower", "Floor", "Flare"]
      },
      {
        "question": "Which word sounds the same as 'knight' but means a piece of armor for the leg?",
        "answer": "Night",
        "options": ["Night", "Knight", "Nite", "Kite"]
      },
      {
        "question": "Which word sounds the same as 'pair' but means a fruit?",
        "answer": "Pear",
        "options": ["Pare", "Pair", "Pear", "Peer"]
      },
      {
        "question": "Which word sounds the same as 'fare' but means a type of large bird?",
        "answer": "Fair",
        "options": ["Fare", "Fair", "Phare", "Phair"]
      },
      {
        "question": "Which word sounds the same as 'seen' but means a past event or fact?",
        "answer": "Scene",
        "options": ["Seen", "Scene", "Sene", "Seem"]
      },
      {
        "question": "Which word sounds the same as 'pause' but means to sew with long, loose stitches?",
        "answer": "Paws",
        "options": ["Pause", "Paws", "Paus", "Pores"]
      },
      {
        "question": "Which word sounds the same as 'buy' but means to accept something as true?",
        "answer": "By",
        "options": ["Buy", "By", "Bi", "Bye"]
      },
      {
        "question": "Which word sounds the same as 'night' but means a container for holding liquids?",
        "answer": "Knight",
        "options": ["Night", "Knight", "Nite", "Kite"]
      },
      {
        "question": "Which word sounds the same as 'sea' but means a large body of land surrounded by water?",
        "answer": "See",
        "options": ["Sea", "See", "Si", "Se"]
      },
      {
        "question": "Which word sounds the same as 'write' but means a person's job?",
        "answer": "Right",
        "options": ["Right", "Write", "Rite", "Wright"]
      }
    ]
 },

 { 
  id: 33, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Idioms",
  questionList: [
      {
        "question": "What does the idiom 'hit the hay' mean?",
        "answer": "Go to bed",
        "options": ["Go to bed", "Have a picnic", "Start a fight", "Go shopping"]
      },
      {
        "question": "What does the idiom 'cost an arm and a leg' mean?",
        "answer": "Be very expensive",
        "options": ["Be very expensive", "Be very cheap", "Be very heavy", "Be very light"]
      },
      {
        "question": "What does the idiom 'piece of cake' mean?",
        "answer": "Something very easy",
        "options": ["Something very easy", "Something very difficult", "Something very tasty", "Something very hard"]
      },
      {
        "question": "What does the idiom 'break the ice' mean?",
        "answer": "To start a conversation",
        "options": ["To start a conversation", "To end a conversation", "To start a fight", "To go fishing"]
      },
      {
        "question": "What does the idiom 'barking up the wrong tree' mean?",
        "answer": "To be mistaken or misguided",
        "options": ["To be mistaken or misguided", "To be lost in the woods", "To be barking at squirrels", "To be looking for a dog"]
      },
      {
        "question": "What does the idiom 'once in a blue moon' mean?",
        "answer": "Very rarely",
        "options": ["Very rarely", "Every day", "Every week", "Every month"]
      },
      {
        "question": "What does the idiom 'hit the nail on the head' mean?",
        "answer": "To describe exactly what is causing a situation or problem",
        "options": ["To describe exactly what is causing a situation or problem", "To miss the target", "To be good at hammering nails", "To be very accurate"]
      },
      {
        "question": "What does the idiom 'raining cats and dogs' mean?",
        "answer": "Raining very heavily",
        "options": ["Raining very heavily", "Raining animals", "Raining only cats", "Raining only dogs"]
      },
      {
        "question": "What does the idiom 'kick the bucket' mean?",
        "answer": "To die",
        "options": ["To die", "To clean the house", "To play soccer", "To travel"]
      },
      {
        "question": "What does the idiom 'spill the beans' mean?",
        "answer": "To reveal a secret",
        "options": ["To reveal a secret", "To cook beans", "To drop a container", "To eat messily"]
      },
      {
        "question": "What does the idiom 'call it a day' mean?",
        "answer": "To stop working for the day",
        "options": ["To stop working for the day", "To have a party", "To take a vacation", "To go to bed"]
      },
      {
        "question": "What does the idiom 'break a leg' mean?",
        "answer": "Good luck",
        "options": ["Good luck", "Get injured", "Start a fight", "To be successful"]
      }
    ]
 },

 { 
  id: 34, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Phrasal Verbs",
  questionList: [
      {
        "question": "What does the phrasal verb 'look up' mean?",
        "answer": "To search for information",
        "options": ["To search for information", "To jump", "To look down", "To stop looking"]
      },
      {
        "question": "What does the phrasal verb 'bring up' mean?",
        "answer": "To mention something",
        "options": ["To mention something", "To bring something down", "To bring something over", "To bring something up high"]
      },
      {
        "question": "What does the phrasal verb 'give up' mean?",
        "answer": "To quit",
        "options": ["To quit", "To give something away", "To give something up high", "To give something up close"]
      },
      {
        "question": "What does the phrasal verb 'come across' mean?",
        "answer": "To find or discover unexpectedly",
        "options": ["To find or discover unexpectedly", "To come back", "To come around", "To come in"]
      },
      {
        "question": "What does the phrasal verb 'take off' mean?",
        "answer": "To remove something",
        "options": ["To remove something", "To take something off high", "To take something up", "To take something over"]
      },
      {
        "question": "What does the phrasal verb 'put off' mean?",
        "answer": "To postpone",
        "options": ["To postpone", "To put something down", "To put something off high", "To put something in"]
      },
      {
        "question": "What does the phrasal verb 'look forward to' mean?",
        "answer": "To be excited about something in the future",
        "options": ["To be excited about something in the future", "To look behind", "To look around", "To look up"]
      },
      {
        "question": "What does the phrasal verb 'get along with' mean?",
        "answer": "To have a good relationship with someone",
        "options": ["To have a good relationship with someone", "To get something along", "To get something over", "To get something around"]
      },
      {
        "question": "What does the phrasal verb 'turn down' mean?",
        "answer": "To refuse or reject something",
        "options": ["To refuse or reject something", "To turn something off", "To turn something around", "To turn something up"]
      },
      {
        "question": "What does the phrasal verb 'pick up' mean?",
        "answer": "To lift something or to collect someone",
        "options": ["To lift something or to collect someone", "To pick something out", "To pick something up high", "To pick something down"]
      },
      {
        "question": "What does the phrasal verb 'put up with' mean?",
        "answer": "To tolerate or endure something",
        "options": ["To tolerate or endure something", "To put something up", "To put something in", "To put something over"]
      },
      {
        "question": "What does the phrasal verb 'come up with' mean?",
        "answer": "To think of or produce an idea or solution",
        "options": ["To think of or produce an idea or solution", "To come over", "To come up", "To come around"]
      }
    ]
 },

 { 
  id: 35, 
  img: "vocabulary.png", 
  category: "Vocabulary", 
  name:"Word Origins",
   questionList: [
      {
        "question": "From which language does the word 'avatar' originate?",
        "answer": "Sanskrit",
        "options": ["Sanskrit", "Greek", "Latin", "Arabic"]
      },
      {
        "question": "Which language does the word 'karaoke' come from?",
        "answer": "Japanese",
        "options": ["Japanese", "Chinese", "Korean", "Vietnamese"]
      },
      {
        "question": "From which language does the word 'safari' originate?",
        "answer": "Swahili",
        "options": ["Swahili", "Arabic", "Zulu", "Hindi"]
      },
      {
        "question": "Which language does the word 'tsunami' originate from?",
        "answer": "Japanese",
        "options": ["Japanese", "Hawaiian", "Indonesian", "Fijian"]
      },
      {
        "question": "From which language does the word 'robot' originate?",
        "answer": "Czech",
        "options": ["Czech", "Russian", "Hungarian", "Polish"]
      },
      {
        "question": "Which language does the word 'ketchup' come from?",
        "answer": "Chinese",
        "options": ["Chinese", "Malay", "Portuguese", "Spanish"]
      },
      {
        "question": "From which language does the word 'chocolate' originate?",
        "answer": "Nahuatl",
        "options": ["Nahuatl", "Mayan", "Aztec", "Incan"]
      },
      {
        "question": "Which language does the word 'salsa' come from?",
        "answer": "Spanish",
        "options": ["Spanish", "Portuguese", "Italian", "French"]
      },
      {
        "question": "From which language does the word 'banana' originate?",
        "answer": "Portuguese",
        "options": ["Portuguese", "Spanish", "Italian", "Arabic"]
      },
      {
        "question": "Which language does the word 'tycoon' come from?",
        "answer": "Japanese",
        "options": ["Japanese", "Chinese", "Korean", "Vietnamese"]
      },
      {
        "question": "From which language does the word 'jungle' originate?",
        "answer": "Hindi",
        "options": ["Hindi", "Malay", "Swahili", "Tamil"]
      },
      {
        "question": "Which language does the word 'boomerang' come from?",
        "answer": "Australian Aboriginal",
        "options": ["Australian Aboriginal", "Maori", "Inuit", "Native American"]
      }
    ]
 },

 //Entertainment
 { 
  id: 36, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"Movies",
  questionList: [
      {
        "question": "Which movie won the Academy Award for Best Picture in 2020?",
        "answer": "Parasite",
        "options": ["Parasite", "1917", "Joker", "Once Upon a Time in Hollywood"]
      },
      {
        "question": "Who directed the movie 'Inception'?",
        "answer": "Christopher Nolan",
        "options": ["Steven Spielberg", "James Cameron", "Martin Scorsese", "Christopher Nolan"]
      },
      {
        "question": "Which actor played the role of Tony Stark in the Marvel Cinematic Universe?",
        "answer": "Robert Downey Jr.",
        "options": ["Chris Evans", "Chris Hemsworth", "Robert Downey Jr.", "Mark Ruffalo"]
      },
      {
        "question": "Which movie features the famous line: 'Here's looking at you, kid'?",
        "answer": "Casablanca",
        "options": ["Gone with the Wind", "Casablanca", "Citizen Kane", "The Godfather"]
      },
      {
        "question": "What is the highest-grossing film of all time (not adjusted for inflation)?",
        "answer": "Avatar",
        "options": ["Avengers: Endgame", "Avatar", "Titanic", "Star Wars: The Force Awakens"]
      },
      {
        "question": "Who played the character Jack Dawson in the movie 'Titanic'?",
        "answer": "Leonardo DiCaprio",
        "options": ["Brad Pitt", "Tom Cruise", "Leonardo DiCaprio", "Johnny Depp"]
      },
      {
        "question": "Which film won the first Academy Award for Best Picture?",
        "answer": "Wings",
        "options": ["The Broadway Melody", "Wings", "All Quiet on the Western Front", "Sunrise: A Song of Two Humans"]
      },
      {
        "question": "Who directed the 1975 movie 'Jaws'?",
        "answer": "Steven Spielberg",
        "options": ["Francis Ford Coppola", "George Lucas", "Martin Scorsese", "Steven Spielberg"]
      },
      {
        "question": "Which movie features the line: 'You can't handle the truth!'?",
        "answer": "A Few Good Men",
        "options": ["The Shawshank Redemption", "A Few Good Men", "Gladiator", "The Dark Knight"]
      },
      {
        "question": "What is the name of the fictional African country in the movie 'Black Panther'?",
        "answer": "Wakanda",
        "options": ["Zamunda", "Wakanda", "Genovia", "Agrabah"]
      },
      {
        "question": "Which actor starred in the 1990 movie 'Pretty Woman'?",
        "answer": "Julia Roberts",
        "options": ["Meryl Streep", "Julia Roberts", "Demi Moore", "Meg Ryan"]
      },
      {
        "question": "Who directed the 1994 movie 'Forrest Gump'?",
        "answer": "Robert Zemeckis",
        "options": ["Quentin Tarantino", "Martin Scorsese", "James Cameron", "Robert Zemeckis"]
      }
    ] 
 },

 { 
  id: 37, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"Music",
  questionList: [
      {
        "question": "Which Beatles album is often considered one of the greatest albums of all time?",
        "answer": "Sgt. Pepper's Lonely Hearts Club Band",
        "options": ["Abbey Road", "Revolver", "The White Album", "Sgt. Pepper's Lonely Hearts Club Band"]
      },
      {
        "question": "Who is known as the 'King of Pop'?",
        "answer": "Michael Jackson",
        "options": ["Elvis Presley", "Michael Jackson", "Prince", "David Bowie"]
      },
      {
        "question": "Which artist released the album 'Thriller' in 1982?",
        "answer": "Michael Jackson",
        "options": ["Madonna", "Whitney Houston", "Michael Jackson", "Prince"]
      },
      {
        "question": "What is the name of the lead singer of the band Queen?",
        "answer": "Freddie Mercury",
        "options": ["Freddie Mercury", "David Bowie", "Mick Jagger", "Elton John"]
      },
      {
        "question": "Who sang the theme song for the James Bond movie 'Skyfall'?",
        "answer": "Adele",
        "options": ["Beyoncé", "Adele", "Rihanna", "Lady Gaga"]
      },
      {
        "question": "Which band performed the song 'Stairway to Heaven'?",
        "answer": "Led Zeppelin",
        "options": ["The Rolling Stones", "Pink Floyd", "Led Zeppelin", "The Beatles"]
      },
      {
        "question": "Who is known for the hit song 'Bohemian Rhapsody'?",
        "answer": "Queen",
        "options": ["The Beatles", "Queen", "The Beach Boys", "The Rolling Stones"]
      },
      {
        "question": "Which artist released the album 'The Dark Side of the Moon'?",
        "answer": "Pink Floyd",
        "options": ["The Doors", "Pink Floyd", "The Rolling Stones", "The Who"]
      },
      {
        "question": "What is the name of Beyoncé's visual album released in 2016?",
        "answer": "Lemonade",
        "options": ["Lemonade", "B'Day", "4", "I Am... Sasha Fierce"]
      },
      {
        "question": "Who is known as the 'Queen of Soul'?",
        "answer": "Aretha Franklin",
        "options": ["Whitney Houston", "Diana Ross", "Aretha Franklin", "Tina Turner"]
      },
      {
        "question": "Which artist released the album 'Back to Black' in 2006?",
        "answer": "Amy Winehouse",
        "options": ["Britney Spears", "Amy Winehouse", "Lady Gaga", "Adele"]
      },
      {
        "question": "What is the name of the British rock band formed in 1964 known for hits like 'Satisfaction'?",
        "answer": "The Rolling Stones",
        "options": ["The Beatles", "The Rolling Stones", "The Who", "Led Zeppelin"]
      }
    ]
 },

 { 
  id: 38, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"TV Shows",
  questionList: [
      {
        "question": "Which TV show features the characters Ross, Rachel, and Joey?",
        "answer": "Friends",
        "options": ["Seinfeld", "The Office", "Friends", "How I Met Your Mother"]
      },
      {
        "question": "Who is the main character in the TV show 'Breaking Bad'?",
        "answer": "Walter White",
        "options": ["Jesse Pinkman", "Saul Goodman", "Walter White", "Skyler White"]
      },
      {
        "question": "What is the name of the fictional continent in the TV show 'Game of Thrones'?",
        "answer": "Westeros",
        "options": ["Essos", "Westeros", "Sothoryos", "Ulthos"]
      },
      {
        "question": "Who plays the character Michael Scott in the TV show 'The Office'?",
        "answer": "Steve Carell",
        "options": ["Rainn Wilson", "John Krasinski", "Steve Carell", "Ed Helms"]
      },
      {
        "question": "Which TV show is set in the fictional town of Hawkins, Indiana?",
        "answer": "Stranger Things",
        "options": ["Breaking Bad", "Stranger Things", "The Crown", "The Walking Dead"]
      },
      {
        "question": "Who is the host of the TV show 'The Late Show'?",
        "answer": "Stephen Colbert",
        "options": ["Jimmy Fallon", "Stephen Colbert", "Jimmy Kimmel", "Conan O'Brien"]
      },
      {
        "question": "Which TV show follows the lives of a group of nerdy friends living in Pasadena?",
        "answer": "The Big Bang Theory",
        "options": ["Modern Family", "The Big Bang Theory", "How I Met Your Mother", "Two and a Half Men"]
      },
      {
        "question": "What is the name of the high school where 'Beverly Hills, 90210' takes place?",
        "answer": "West Beverly Hills High School",
        "options": ["West Beverly Hills High School", "Bayside High School", "Rydell High School", "Hill Valley High School"]
      },
      {
        "question": "Who is known as the 'Mother of Dragons' in 'Game of Thrones'?",
        "answer": "Daenerys Targaryen",
        "options": ["Cersei Lannister", "Sansa Stark", "Daenerys Targaryen", "Margaery Tyrell"]
      },
      {
        "question": "Which TV show is set in the fictional town of Springfield?",
        "answer": "The Simpsons",
        "options": ["Family Guy", "The Simpsons", "South Park", "Rick and Morty"]
      },
      {
        "question": "Who played the character Walter White in 'Breaking Bad'?",
        "answer": "Bryan Cranston",
        "options": ["Aaron Paul", "Bob Odenkirk", "Bryan Cranston", "Jonathan Banks"]
      },
      {
        "question": "What is the name of the street where the Simpson family lives in 'The Simpsons'?",
        "answer": "Evergreen Terrace",
        "options": ["Maple Street", "Evergreen Terrace", "Oak Street", "Elm Avenue"]
      }
    ]
 },

 { 
  id: 39, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"Books",
  questionList: [
      {
        "question": "Who is the author of 'To Kill a Mockingbird'?",
        "answer": "Harper Lee",
        "options": ["John Steinbeck", "Harper Lee", "Ernest Hemingway", "F. Scott Fitzgerald"]
      },
      {
        "question": "What is the title of J.K. Rowling's first 'Harry Potter' book?",
        "answer": "Harry Potter and the Philosopher's Stone",
        "options": ["Harry Potter and the Philosopher's Stone", "Harry Potter and the Chamber of Secrets", "Harry Potter and the Prisoner of Azkaban", "Harry Potter and the Goblet of Fire"]
      },
      {
        "question": "Who wrote 'The Catcher in the Rye'?",
        "answer": "J.D. Salinger",
        "options": ["J.D. Salinger", "F. Scott Fitzgerald", "Ernest Hemingway", "John Steinbeck"]
      },
      {
        "question": "What is the first book in George R.R. Martin's 'A Song of Ice and Fire' series?",
        "answer": "A Game of Thrones",
        "options": ["A Game of Thrones", "A Clash of Kings", "A Storm of Swords", "A Feast for Crows"]
      },
      {
        "question": "Who wrote '1984'?",
        "answer": "George Orwell",
        "options": ["Aldous Huxley", "George Orwell", "Ray Bradbury", "Kurt Vonnegut"]
      },
      {
        "question": "What is the subtitle of 'The Lord of the Rings: The Fellowship of the Ring'?",
        "answer": "The Fellowship of the Ring",
        "options": ["The Two Towers", "The Fellowship of the Ring", "The Return of the King", "The Hobbit"]
      },
      {
        "question": "Who is the author of 'Pride and Prejudice'?",
        "answer": "Jane Austen",
        "options": ["Charlotte Brontë", "Jane Austen", "Emily Dickinson", "George Eliot"]
      },
      {
        "question": "What is the name of the fictional land in 'The Chronicles of Narnia' series?",
        "answer": "Narnia",
        "options": ["Middle-earth", "Narnia", "Westeros", "Hogwarts"]
      },
      {
        "question": "Who wrote the dystopian novel 'Brave New World'?",
        "answer": "Aldous Huxley",
        "options": ["George Orwell", "Ray Bradbury", "Aldous Huxley", "Kurt Vonnegut"]
      },
      {
        "question": "What is the first book in the 'Twilight' series by Stephenie Meyer?",
        "answer": "Twilight",
        "options": ["New Moon", "Eclipse", "Breaking Dawn", "Twilight"]
      },
      {
        "question": "Who wrote the novel 'The Great Gatsby'?",
        "answer": "F. Scott Fitzgerald",
        "options": ["John Steinbeck", "Ernest Hemingway", "F. Scott Fitzgerald", "J.D. Salinger"]
      },
      {
        "question": "What is the name of the youngest Weasley sibling in the 'Harry Potter' series?",
        "answer": "Ginny",
        "options": ["Ginny", "Ron", "Fred", "George"]
      }
    ]
 },

 { 
  id: 40, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"Video Games",
  questionList: [
      {
        "question": "Which video game features the character Link?",
        "answer": "The Legend of Zelda",
        "options": ["Super Mario Bros.", "The Legend of Zelda", "Metroid", "Donkey Kong"]
      },
      {
        "question": "Who is the main protagonist of the 'Uncharted' series?",
        "answer": "Nathan Drake",
        "options": ["Nathan Drake", "Solid Snake", "Master Chief", "Samus Aran"]
      },
      {
        "question": "Which video game franchise features the character Sonic the Hedgehog?",
        "answer": "Sonic the Hedgehog",
        "options": ["Final Fantasy", "Sonic the Hedgehog", "Pokemon", "Mega Man"]
      },
      {
        "question": "What is the name of the main character in the 'Assassin's Creed' series?",
        "answer": "Desmond Miles",
        "options": ["Ezio Auditore", "Altaïr Ibn-La'Ahad", "Desmond Miles", "Connor Kenway"]
      },
      {
        "question": "Which game introduced the iconic character Mario?",
        "answer": "Donkey Kong",
        "options": ["Super Mario Bros.", "The Legend of Zelda", "Pac-Man", "Donkey Kong"]
      },
      {
        "question": "Who is the creator of the 'Metal Gear' series?",
        "answer": "Hideo Kojima",
        "options": ["Shigeru Miyamoto", "Hideo Kojima", "Hidetaka Miyazaki", "Yu Suzuki"]
      },
      {
        "question": "Which game franchise features the character Lara Croft?",
        "answer": "Tomb Raider",
        "options": ["Uncharted", "Tomb Raider", "Resident Evil", "Gears of War"]
      },
      {
        "question": "Who is the main antagonist of the 'Half-Life' series?",
        "answer": "G-Man",
        "options": ["Dr. Breen", "G-Man", "The Combine", "Headcrab"]
      },
      {
        "question": "Which game introduced the concept of 'bullet time'?",
        "answer": "Max Payne",
        "options": ["Max Payne", "Doom", "Quake", "Half-Life"]
      },
      {
        "question": "What is the name of the protagonist in 'The Witcher' series?",
        "answer": "Geralt of Rivia",
        "options": ["Ciri", "Geralt of Rivia", "Yennefer", "Triss Merigold"]
      },
      {
        "question": "Which game introduced the character Master Chief?",
        "answer": "Halo: Combat Evolved",
        "options": ["Halo: Combat Evolved", "Call of Duty", "Battlefield", "Destiny"]
      },
      {
        "question": "Who is the developer of the 'Final Fantasy' series?",
        "answer": "Square Enix",
        "options": ["Nintendo", "Sega", "Capcom", "Square Enix"]
      }
    ]
 },

 { 
  id: 41, 
  img: "entertainment.png", 
  category: "Entertainment", 
  name:"Celebrities",
  questionList: [
      {
        "question": "Who is known as the 'King of Pop'?",
        "answer": "Michael Jackson",
        "options": ["Elvis Presley", "Michael Jackson", "Prince", "David Bowie"]
      },
      {
        "question": "What is the real name of the rapper Eminem?",
        "answer": "Marshall Mathers",
        "options": ["Curtis Jackson", "Andre Young", "Marshall Mathers", "Shawn Carter"]
      },
      {
        "question": "Who is the highest-paid actress in the world?",
        "answer": "Sofia Vergara",
        "options": ["Angelina Jolie", "Jennifer Lawrence", "Sofia Vergara", "Scarlett Johansson"]
      },
      {
        "question": "Which actor played the character Tony Stark in the Marvel Cinematic Universe?",
        "answer": "Robert Downey Jr.",
        "options": ["Chris Evans", "Chris Hemsworth", "Robert Downey Jr.", "Mark Ruffalo"]
      },
      {
        "question": "Who is the youngest self-made billionaire?",
        "answer": "Kylie Jenner",
        "options": ["Mark Zuckerberg", "Kylie Jenner", "Evan Spiegel", "Taylor Swift"]
      },
      {
        "question": "What is the real name of the rapper Drake?",
        "answer": "Aubrey Graham",
        "options": ["Calvin Broadus", "Kanye West", "Aubrey Graham", "Jay-Z"]
      },
      {
        "question": "Who is the lead vocalist of the band Coldplay?",
        "answer": "Chris Martin",
        "options": ["Chris Martin", "Thom Yorke", "Dave Grohl", "Bono"]
      },
      {
        "question": "Which actress starred in the movie 'Pretty Woman'?",
        "answer": "Julia Roberts",
        "options": ["Meryl Streep", "Julia Roberts", "Demi Moore", "Meg Ryan"]
      },
      {
        "question": "Who is known as the 'Queen of Pop'?",
        "answer": "Madonna",
        "options": ["Madonna", "Beyoncé", "Rihanna", "Taylor Swift"]
      },
      {
        "question": "Which actor played the character Jack Dawson in the movie 'Titanic'?",
        "answer": "Leonardo DiCaprio",
        "options": ["Brad Pitt", "Tom Cruise", "Leonardo DiCaprio", "Johnny Depp"]
      },
      {
        "question": "Who is known as the 'Father of the Nation' in India?",
        "answer": "Mahatma Gandhi",
        "options": ["Jawaharlal Nehru", "Sardar Vallabhbhai Patel", "Mahatma Gandhi", "B.R. Ambedkar"]
      },
      {
        "question": "Which singer is often referred to as the 'King of Rock and Roll'?",
        "answer": "Elvis Presley",
        "options": ["Michael Jackson", "Elvis Presley", "John Lennon", "Bob Dylan"]
      }
    ]
 },

 //AutoMobile 
 { 
  id: 42, 
  img:"automobile.png", 
  category: "AutoMobile ", 
  name:"Car Brands",
  questionList: [
      {
        "question": "Which car brand produces the Civic, Accord, and CR-V models?",
        "answer": "Honda",
        "options": ["Toyota", "Ford", "Honda", "Chevrolet"]
      },
      {
        "question": "Which car brand's logo features a blue oval with the company's name in white?",
        "answer": "Ford",
        "options": ["Ford", "Toyota", "Chevrolet", "Honda"]
      },
      {
        "question": "Which car brand is known for producing luxury vehicles such as the A4, A6, and Q7?",
        "answer": "Audi",
        "options": ["BMW", "Audi", "Mercedes-Benz", "Lexus"]
      },
      {
        "question": "Which car brand's logo features a silver circle with a blue cross in the middle?",
        "answer": "Volvo",
        "options": ["Volvo", "Subaru", "Volkswagen", "Hyundai"]
      },
      {
        "question": "Which car brand produces the Mustang, F-150, and Explorer models?",
        "answer": "Ford",
        "options": ["Chevrolet", "Ford", "Dodge", "GMC"]
      },
      {
        "question": "Which car brand's logo features a blue oval with a silver script writing?",
        "answer": "Hyundai",
        "options": ["Toyota", "Honda", "Hyundai", "Nissan"]
      },
      {
        "question": "Which car brand is known for producing electric vehicles such as the Model S, Model 3, and Model X?",
        "answer": "Tesla",
        "options": ["Toyota", "Tesla", "Chevrolet", "Ford"]
      },
      {
        "question": "Which car brand's logo features a silver circle with a green swoosh inside?",
        "answer": "Kia",
        "options": ["Kia", "Mazda", "Subaru", "Buick"]
      },
      {
        "question": "Which car brand produces the 911, Cayenne, and Panamera models?",
        "answer": "Porsche",
        "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
      },
      {
        "question": "Which car brand's logo features a silver shield with a prancing horse inside?",
        "answer": "Ferrari",
        "options": ["Ferrari", "Lamborghini", "Bugatti", "Maserati"]
      },
      {
        "question": "Which car brand is known for producing luxury vehicles such as the S-Class, E-Class, and C-Class?",
        "answer": "Mercedes-Benz",
        "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
      },
      {
        "question": "Which car brand's logo features a blue oval with the company's name in white?",
        "answer": "Ford",
        "options": ["Ford", "Toyota", "Chevrolet", "Honda"]
      }
    ]
 },

 { 
  id: 43, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Sedans",
  questionList: [
    {
      "question": "Which car brand produces the Civic, Accord, and CR-V models?",
      "answer": "Honda",
      "options": ["Toyota", "Ford", "Honda", "Chevrolet"]
    },
    {
      "question": "Which car brand's logo features a blue oval with the company's name in white?",
      "answer": "Ford",
      "options": ["Ford", "Toyota", "Chevrolet", "Honda"]
    },
    {
      "question": "Which car brand is known for producing luxury vehicles such as the A4, A6, and Q7?",
      "answer": "Audi",
      "options": ["BMW", "Audi", "Mercedes-Benz", "Lexus"]
    },
    {
      "question": "Which car brand's logo features a silver circle with a blue cross in the middle?",
      "answer": "Volvo",
      "options": ["Volvo", "Subaru", "Volkswagen", "Hyundai"]
    },
    {
      "question": "Which car brand produces the Mustang, F-150, and Explorer models?",
      "answer": "Ford",
      "options": ["Chevrolet", "Ford", "Dodge", "GMC"]
    },
    {
      "question": "Which car brand's logo features a blue oval with a silver script writing?",
      "answer": "Hyundai",
      "options": ["Toyota", "Honda", "Hyundai", "Nissan"]
    },
    {
      "question": "Which car brand is known for producing electric vehicles such as the Model S, Model 3, and Model X?",
      "answer": "Tesla",
      "options": ["Toyota", "Tesla", "Chevrolet", "Ford"]
    },
    {
      "question": "Which car brand's logo features a silver circle with a green swoosh inside?",
      "answer": "Kia",
      "options": ["Kia", "Mazda", "Subaru", "Buick"]
    },
    {
      "question": "Which car brand produces the 911, Cayenne, and Panamera models?",
      "answer": "Porsche",
      "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
    },
    {
      "question": "Which car brand's logo features a silver shield with a prancing horse inside?",
      "answer": "Ferrari",
      "options": ["Ferrari", "Lamborghini", "Bugatti", "Maserati"]
    },
    {
      "question": "Which car brand is known for producing luxury vehicles such as the S-Class, E-Class, and C-Class?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which car brand's logo features a blue oval with the company's name in white?",
      "answer": "Ford",
      "options": ["Ford", "Toyota", "Chevrolet", "Honda"]
    }
  ]  
 },

 { 
  id: 44, 
  img: "automobile .png", 
  category: "AutoMobile ", 
  name:"SUVs",
  questionList: [
    {
      "question": "What does SUV stand for?",
      "answer": "Sport Utility Vehicle",
      "options": ["Super Urban Vehicle", "Sports Utility Van", "Sport Utility Vehicle", "Super Urban Van"]
    },
    {
      "question": "Which SUV is known as the 'King of the Road'?",
      "answer": "Land Rover Range Rover",
      "options": ["Jeep Grand Cherokee", "Toyota Land Cruiser", "Land Rover Range Rover", "Ford Expedition"]
    },
    {
      "question": "Which company produces the popular SUV model 'Rav4'?",
      "answer": "Toyota",
      "options": ["Honda", "Toyota", "Chevrolet", "Ford"]
    },
    {
      "question": "What is the full form of '4WD' in SUVs?",
      "answer": "Four-Wheel Drive",
      "options": ["Four Wheel Drive", "Four-Way Drive", "Four Wheel Drive", "Forward Wheel Drive"]
    },
    {
      "question": "Which SUV model is famously associated with off-road adventures?",
      "answer": "Jeep Wrangler",
      "options": ["Toyota Highlander", "Jeep Wrangler", "Ford Explorer", "Chevrolet Tahoe"]
    },
    {
      "question": "What is the name of the luxury SUV produced by Mercedes-Benz?",
      "answer": "Mercedes-Benz G-Class",
      "options": ["Mercedes-Benz GLC", "BMW X5", "Audi Q7", "Mercedes-Benz G-Class"]
    },
    {
      "question": "Which SUV brand offers the model 'Escalade'?",
      "answer": "Cadillac",
      "options": ["Lincoln", "GMC", "Chevrolet", "Cadillac"]
    },
    {
      "question": "Which SUV is known for its iconic boxy design and exceptional off-road capabilities?",
      "answer": "Land Rover Defender",
      "options": ["Ford Explorer", "Toyota Land Cruiser", "Land Rover Defender", "Jeep Grand Cherokee"]
    },
    {
      "question": "Which SUV brand offers the model 'Tiguan'?",
      "answer": "Volkswagen",
      "options": ["Subaru", "Kia", "Volkswagen", "Hyundai"]
    },
    {
      "question": "What is the name of the full-size SUV produced by Chevrolet?",
      "answer": "Chevrolet Suburban",
      "options": ["Chevrolet Tahoe", "GMC Yukon", "Cadillac Escalade", "Chevrolet Suburban"]
    },
    {
      "question": "Which SUV brand produces the 'Pilot' model?",
      "answer": "Honda",
      "options": ["Toyota", "Ford", "Honda", "Chevrolet"]
    },
    {
      "question": "What is the name of the compact SUV model offered by Nissan?",
      "answer": "Nissan Rogue",
      "options": ["Nissan Murano", "Nissan Rogue", "Nissan Pathfinder", "Nissan Armada"]
    }
  ]
 },

 { 
  id: 45, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Trucks",
  questionList: [
    {
      "question": "Which truck brand produces the F-150, F-250, and F-350 models?",
      "answer": "Ford",
      "options": ["Ford", "Chevrolet", "GMC", "Ram"]
    },
    {
      "question": "What does the acronym 'AWD' stand for in trucks?",
      "answer": "All-Wheel Drive",
      "options": ["All Way Drive", "All Wheel Drive", "Automatic Wheel Drive", "All-Wheel Drive"]
    },
    {
      "question": "Which truck brand offers the 'Sierra' and 'Canyon' models?",
      "answer": "GMC",
      "options": ["Ford", "Chevrolet", "GMC", "Ram"]
    },
    {
      "question": "Which truck is known as the 'King of the Hill'?",
      "answer": "Ford F-150",
      "options": ["Chevrolet Silverado", "GMC Sierra", "Ram 1500", "Ford F-150"]
    },
    {
      "question": "Which truck brand produces the popular 'Silverado' model?",
      "answer": "Chevrolet",
      "options": ["Ford", "Chevrolet", "GMC", "Ram"]
    },
    {
      "question": "Which truck is known for its heavy-duty towing capabilities and Cummins engine?",
      "answer": "Ram 2500",
      "options": ["Ford F-250", "Chevrolet Silverado 2500HD", "GMC Sierra 2500HD", "Ram 2500"]
    },
    {
      "question": "Which truck brand offers the 'Tundra' and 'Tacoma' models?",
      "answer": "Toyota",
      "options": ["Toyota", "Ford", "Chevrolet", "GMC"]
    },
    {
      "question": "Which truck is famously associated with the 'Hemi' engine?",
      "answer": "Ram 1500",
      "options": ["Ford F-150", "Chevrolet Silverado 1500", "GMC Sierra 1500", "Ram 1500"]
    },
    {
      "question": "Which truck brand produces the 'Ranger' and 'F-Series Super Duty' models?",
      "answer": "Ford",
      "options": ["Ford", "Chevrolet", "GMC", "Ram"]
    },
    {
      "question": "Which truck is known for its off-road prowess and TRD Pro trim?",
      "answer": "Toyota Tacoma",
      "options": ["Ford Ranger", "Chevrolet Colorado", "GMC Canyon", "Toyota Tacoma"]
    },
    {
      "question": "Which truck brand offers the '1500' and '2500' models?",
      "answer": "Ram",
      "options": ["Ford", "Chevrolet", "GMC", "Ram"]
    },
    {
      "question": "Which truck is known for its innovative MultiPro tailgate?",
      "answer": "GMC Sierra",
      "options": ["Ford F-150", "Chevrolet Silverado", "GMC Sierra", "Ram 1500"]
    }
  ]  
 },

 { 
  id: 46, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Electric Vehicles",
  questionList: [
    {
      "question": "Which electric vehicle brand is known for the Model S, Model 3, and Model X?",
      "answer": "Tesla",
      "options": ["Tesla", "Nissan", "Chevrolet", "BMW"]
    },
    {
      "question": "What is the name of the electric car model produced by Nissan?",
      "answer": "Nissan Leaf",
      "options": ["Nissan Bolt", "Nissan Spark", "Nissan Leaf", "Nissan Volt"]
    },
    {
      "question": "Which electric vehicle brand offers the 'Bolt EV'?",
      "answer": "Chevrolet",
      "options": ["Tesla", "Nissan", "Chevrolet", "BMW"]
    },
    {
      "question": "Which company produces the electric vehicle model 'i3'?",
      "answer": "BMW",
      "options": ["Tesla", "Nissan", "Chevrolet", "BMW"]
    },
    {
      "question": "What is the name of the electric car produced by Chevrolet?",
      "answer": "Chevrolet Bolt EV",
      "options": ["Chevrolet Spark EV", "Chevrolet Bolt EV", "Chevrolet Volt EV", "Chevrolet Leaf EV"]
    },
    {
      "question": "Which electric vehicle brand offers the 'Leaf' model?",
      "answer": "Nissan",
      "options": ["Tesla", "Nissan", "Chevrolet", "BMW"]
    },
    {
      "question": "What is the range of the Tesla Model S Plaid in miles?",
      "answer": "390 miles",
      "options": ["320 miles", "360 miles", "380 miles", "390 miles"]
    },
    {
      "question": "Which electric vehicle is known for its 'Autopilot' feature?",
      "answer": "Tesla Model 3",
      "options": ["Nissan Leaf", "Chevrolet Bolt EV", "BMW i3", "Tesla Model 3"]
    },
    {
      "question": "What is the name of the electric car model produced by BMW?",
      "answer": "BMW i3",
      "options": ["BMW i3", "BMW i4", "BMW i5", "BMW i8"]
    },
    {
      "question": "Which electric vehicle brand produces the 'i-Pace' model?",
      "answer": "Jaguar",
      "options": ["Tesla", "Nissan", "Chevrolet", "Jaguar"]
    },
    {
      "question": "What is the maximum charging speed (in kW) of the Tesla Supercharger V3?",
      "answer": "250 kW",
      "options": ["200 kW", "220 kW", "240 kW", "250 kW"]
    },
    {
      "question": "Which electric vehicle brand offers the 'Soul EV'?",
      "answer": "Kia",
      "options": ["Tesla", "Nissan", "Chevrolet", "Kia"]
    }
  ] 
 },

 { 
  id: 47, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Sports Cars",
  questionList: [
    {
      "question": "Which car brand produces the 911, Cayenne, and Panamera models?",
      "answer": "Porsche",
      "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
    },
    {
      "question": "Which car brand's logo features a silver shield with a prancing horse inside?",
      "answer": "Ferrari",
      "options": ["Ferrari", "Lamborghini", "Bugatti", "Maserati"]
    },
    {
      "question": "Which car brand produces the Corvette, Camaro, and Silverado models?",
      "answer": "Chevrolet",
      "options": ["Ford", "Chevrolet", "Dodge", "GMC"]
    },
    {
      "question": "Which car brand is known for its Veyron and Chiron hypercars?",
      "answer": "Bugatti",
      "options": ["Ferrari", "Lamborghini", "Bugatti", "Maserati"]
    },
    {
      "question": "Which car brand produces the Aventador, Huracan, and Urus models?",
      "answer": "Lamborghini",
      "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
    },
    {
      "question": "Which car brand's logo features a bull, symbolizing power and performance?",
      "answer": "Lamborghini",
      "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
    },
    {
      "question": "Which car brand is known for its GT-R and Z models?",
      "answer": "Nissan",
      "options": ["Toyota", "Honda", "Nissan", "Mitsubishi"]
    },
    {
      "question": "Which car brand produces the Mustang, F-150, and Explorer models?",
      "answer": "Ford",
      "options": ["Chevrolet", "Ford", "Dodge", "GMC"]
    },
    {
      "question": "Which car brand is known for its 458 Italia and LaFerrari models?",
      "answer": "Ferrari",
      "options": ["Ferrari", "Porsche", "Lamborghini", "Maserati"]
    },
    {
      "question": "Which car brand produces the GTI, Golf, and Passat models?",
      "answer": "Volkswagen",
      "options": ["BMW", "Audi", "Volkswagen", "Mercedes-Benz"]
    },
    {
      "question": "Which car brand is known for its NSX and Civic Type R models?",
      "answer": "Honda",
      "options": ["Toyota", "Honda", "Nissan", "Mitsubishi"]
    },
    {
      "question": "Which car brand produces the 570S, 720S, and Speedtail models?",
      "answer": "McLaren",
      "options": ["Ferrari", "Porsche", "Lamborghini", "McLaren"]
    }
  ]
 },

 { 
  id: 48, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Luxury Cars",
  questionList: [
    {
      "question": "Which car brand is known for producing luxury vehicles such as the S-Class, E-Class, and C-Class?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'Ultimate Driving Machine' slogan?",
      "answer": "BMW",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'Q7' and 'A8' models?",
      "answer": "Audi",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the 'RX', 'NX', and 'ES' models?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'Quattro' all-wheel-drive system?",
      "answer": "Audi",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'CLS', 'GLS', and 'SL' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'L' series vehicles?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the 'S-Class', 'C-Class', and 'GLC' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'XJ', 'XF', and 'F-Pace' models?",
      "answer": "Jaguar",
      "options": ["Audi", "BMW", "Lexus", "Jaguar"]
    },
    {
      "question": "Which luxury car brand is known for its 'IS', 'GS', and 'LS' models?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the '7 Series', '3 Series', and '5 Series' models?",
      "answer": "BMW",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'E-Class', 'G-Class', and 'GLA' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    }
  ] 
 },

 { 
  id: 49, 
  img: "automobile.png", 
  category: "AutoMobile ", 
  name:"Luxury Cars",
  questionList: [
    {
      "question": "Which car brand is known for producing luxury vehicles such as the S-Class, E-Class, and C-Class?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'Ultimate Driving Machine' slogan?",
      "answer": "BMW",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'Q7' and 'A8' models?",
      "answer": "Audi",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the 'RX', 'NX', and 'ES' models?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'Quattro' all-wheel-drive system?",
      "answer": "Audi",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'CLS', 'GLS', and 'SL' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'L' series vehicles?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the 'S-Class', 'C-Class', and 'GLC' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand offers the 'XJ', 'XF', and 'F-Pace' models?",
      "answer": "Jaguar",
      "options": ["Audi", "BMW", "Lexus", "Jaguar"]
    },
    {
      "question": "Which luxury car brand is known for its 'IS', 'GS', and 'LS' models?",
      "answer": "Lexus",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand produces the '7 Series', '3 Series', and '5 Series' models?",
      "answer": "BMW",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    },
    {
      "question": "Which luxury car brand is known for its 'E-Class', 'G-Class', and 'GLA' models?",
      "answer": "Mercedes-Benz",
      "options": ["Audi", "BMW", "Lexus", "Mercedes-Benz"]
    }
  ] 
 },

 //Bollywood 
 { 
  id: 50, 
  img: "bollywood.png", 
  category: "Bollywood", 
  name:"Bollywood Actors",
  questionList: [
    {
      "question": "Who is often referred to as the 'King Khan' of Bollywood?",
      "answer": "Shah Rukh Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which Bollywood actor is known as the 'Greek God'?",
      "answer": "Hrithik Roshan",
      "options": ["Ranbir Kapoor", "Hrithik Roshan", "Ranveer Singh", "Varun Dhawan"]
    },
    {
      "question": "Who is the 'Khiladi' of Bollywood?",
      "answer": "Akshay Kumar",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which actor is often called the 'Bhai' of Bollywood?",
      "answer": "Salman Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Who is known as the 'Bollywood Badshah'?",
      "answer": "Shah Rukh Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which Bollywood actor is known as the 'Bhaijaan'?",
      "answer": "Salman Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Who is referred to as the 'Perfectionist' of Bollywood?",
      "answer": "Aamir Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which actor is known as the 'Singham' of Bollywood?",
      "answer": "Ajay Devgn",
      "options": ["Ajay Devgn", "Salman Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Who is often called 'Mr. India' in Bollywood?",
      "answer": "Anil Kapoor",
      "options": ["Anil Kapoor", "Salman Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which Bollywood actor is known as the 'Dabangg'?",
      "answer": "Salman Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Who is known as the 'Baadshah of Bollywood'?",
      "answer": "Shah Rukh Khan",
      "options": ["Salman Khan", "Aamir Khan", "Shah Rukh Khan", "Akshay Kumar"]
    },
    {
      "question": "Which actor is often called 'Junior Bachchan'?",
      "answer": "Abhishek Bachchan",
      "options": ["Abhishek Bachchan", "Amitabh Bachchan", "Shah Rukh Khan", "Akshay Kumar"]
    }
  ]
 },

 { 
  id: 51, 
  img: "bollywood .png", 
  category: "Bollywood", 
  name:"Bollywood Actresses",
  questionList: [
    {
      "question": "Who is known as the 'Queen' of Bollywood?",
      "answer": "Kangana Ranaut",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is often referred to as the 'Desi Girl'?",
      "answer": "Priyanka Chopra",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Which actress is known as the 'Begum of Bollywood'?",
      "answer": "Kareena Kapoor Khan",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is often called the 'Mastani' of Bollywood?",
      "answer": "Deepika Padukone",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Which Bollywood actress is known as the 'Dream Girl'?",
      "answer": "Hema Malini",
      "options": ["Deepika Padukone", "Hema Malini", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is known as the 'Dhak-Dhak Girl' of Bollywood?",
      "answer": "Madhuri Dixit",
      "options": ["Madhuri Dixit", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Which actress is often referred to as the 'Chandni'?",
      "answer": "Sridevi",
      "options": ["Madhuri Dixit", "Sridevi", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is known as the 'Jhansi Ki Rani' of Bollywood?",
      "answer": "Kangana Ranaut",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Which actress is often called the 'Diva' of Bollywood?",
      "answer": "Rekha",
      "options": ["Rekha", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is known as the 'First Female Superstar' of Bollywood?",
      "answer": "Sridevi",
      "options": ["Madhuri Dixit", "Sridevi", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Which actress is known as the 'Queen of Hearts'?",
      "answer": "Deepika Padukone",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    },
    {
      "question": "Who is often called 'Bebo' in Bollywood?",
      "answer": "Kareena Kapoor Khan",
      "options": ["Deepika Padukone", "Kangana Ranaut", "Priyanka Chopra", "Kareena Kapoor Khan"]
    }
  ]  
 },

 { 
  id: 52, 
  img: "bollywood.png", 
  category: "Bollywood", 
  name:"Bollywood Movies",
  questionList: [
    {
      "question": "Which Bollywood movie is known for the dialogue 'Mere paas maa hai'?",
      "answer": "Deewaar",
      "options": ["Sholay", "Deewaar", "Mother India", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which movie is often referred to as the 'Mother of Indian Cinema'?",
      "answer": "Mother India",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which Bollywood movie is known for the dialogue 'Kitne aadmi the'?",
      "answer": "Sholay",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which movie is often referred to as 'DDLJ'?",
      "answer": "Dilwale Dulhania Le Jayenge",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which Bollywood movie is known for the dialogue 'Arey o Sambha'?",
      "answer": "Sholay",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which movie features the iconic train scene with the song 'Tujhe Dekha Toh'?",
      "answer": "Dilwale Dulhania Le Jayenge",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which Bollywood movie is known for the dialogue 'Bade bade deshon mein aisi chhoti chhoti baatein hoti rehti hai'?",
      "answer": "Dilwale Dulhania Le Jayenge",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which movie features the iconic 'angry young man' portrayal of Amitabh Bachchan?",
      "answer": "Deewaar",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which Bollywood movie is known for the dialogue 'Rishtey mein toh hum tumhare baap lagte hain'?",
      "answer": "Shahenshah",
      "options": ["Shahenshah", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which movie features the iconic 'Kitne aadmi the' dialogue?",
      "answer": "Sholay",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    },
    {
      "question": "Which Bollywood movie is known for the dialogue 'Aata majhi satakli'?",
      "answer": "Singham",
      "options": ["Sholay", "Mother India", "Deewaar", "Singham"]
    },
    {
      "question": "Which movie features the iconic 'Babuji, zara dheere chalo' song?",
      "answer": "Dilwale Dulhania Le Jayenge",
      "options": ["Sholay", "Mother India", "Deewaar", "Dilwale Dulhania Le Jayenge"]
    }
  ]  
 },

 { 
  id: 53, 
  img: "bollywood.png", 
  category: "Bollywood", 
  name:"Bollywood Songs",
  questionList: [
    {
      "question": "Which Bollywood song is often referred to as the 'Anthem of Friendship'?",
      "answer": "Yeh Dosti Hum Nahi Todenge",
      "options": ["Yeh Dosti Hum Nahi Todenge", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which song features the iconic train-top dance sequence with Shah Rukh Khan and Malaika Arora?",
      "answer": "Chaiyya Chaiyya",
      "options": ["Yeh Dosti Hum Nahi Todenge", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which Bollywood song is known for the dialogue 'Ek chatur naar karke singaar'?",
      "answer": "Ek Chatur Naar",
      "options": ["Yeh Dosti Hum Nahi Todenge", "Chaiyya Chaiyya", "Ek Chatur Naar", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which song features the iconic 'palat' scene with Shah Rukh Khan and Kajol?",
      "answer": "Tujhe Dekha Toh",
      "options": ["Tujhe Dekha Toh", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which Bollywood song is known for the dialogue 'Bachna Ae Haseeno, Lo Main Aa Gaya'?",
      "answer": "Bachna Ae Haseeno",
      "options": ["Bachna Ae Haseeno", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which song features the iconic 'rain dance' sequence with Aishwarya Rai and Shah Rukh Khan?",
      "answer": "Barso Re",
      "options": ["Barso Re", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which Bollywood song is known for the dialogue 'Main toh raste se ja raha tha'?",
      "answer": "Mai Toh Raste Se Ja Raha Tha",
      "options": ["Yeh Dosti Hum Nahi Todenge", "Chaiyya Chaiyya", "Mai Toh Raste Se Ja Raha Tha", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which song features the iconic 'swings' dance sequence with Shah Rukh Khan and Kajol?",
      "answer": "Ruk Ja O Dil Deewane",
      "options": ["Ruk Ja O Dil Deewane", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which Bollywood song is known for the dialogue 'Kabhi kabhi mere dil mein khayal aata hai'?",
      "answer": "Kabhi Kabhi",
      "options": ["Yeh Dosti Hum Nahi Todenge", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi"]
    },
    {
      "question": "Which song features the iconic 'cycle ride' sequence with Aamir Khan and Gracy Singh?",
      "answer": "Pardesi Pardesi",
      "options": ["Pardesi Pardesi", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which Bollywood song is known for the dialogue 'Kuch kuch hota hai Rahul, tum nahi samjhoge'?",
      "answer": "Kuch Kuch Hota Hai",
      "options": ["Kuch Kuch Hota Hai", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    },
    {
      "question": "Which song features the iconic 'lake' dance sequence with Raj Kapoor and Nargis?",
      "answer": "Pyar Hua Ikrar Hua",
      "options": ["Pyar Hua Ikrar Hua", "Chaiyya Chaiyya", "Mere Sapno Ki Rani", "Kabhi Kabhi Aditi"]
    }
  ]  
 },

 { 
  id: 54, 
  img: "bollywood.png", 
  category: "Bollywood", 
  name:"Bollywood Directors",
  questionList: [
    {
      "question": "Who is known as the 'King of Bollywood'?",
      "answer": "Karan Johar",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Yash Chopra"]
    },
    {
      "question": "Who is often referred to as the 'Showman' of Bollywood?",
      "answer": "Raj Kapoor",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Raj Kapoor"]
    },
    {
      "question": "Who is known for directing movies like 'Dilwale Dulhania Le Jayenge' and 'Veer-Zaara'?",
      "answer": "Yash Chopra",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Yash Chopra", "Raj Kapoor"]
    },
    {
      "question": "Who directed the 'Munna Bhai' series and '3 Idiots'?",
      "answer": "Rajkumar Hirani",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Raj Kapoor"]
    },
    {
      "question": "Who is known for films like 'Devdas' and 'Padmaavat'?",
      "answer": "Sanjay Leela Bhansali",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Raj Kapoor"]
    },
    {
      "question": "Who directed movies like 'Kuch Kuch Hota Hai' and 'My Name is Khan'?",
      "answer": "Karan Johar",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Yash Chopra"]
    },
    {
      "question": "Who is known for films like 'Sholay' and 'Deewaar'?",
      "answer": "Ramesh Sippy",
      "options": ["Ramesh Sippy", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Yash Chopra"]
    },
    {
      "question": "Who directed the iconic movie 'Mother India'?",
      "answer": "Mehboob Khan",
      "options": ["Mehboob Khan", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Yash Chopra"]
    },
    {
      "question": "Who is known for directing the 'Dil Chahta Hai' and 'Zindagi Na Milegi Dobara'?",
      "answer": "Farhan Akhtar",
      "options": ["Karan Johar", "Farhan Akhtar", "Rajkumar Hirani", "Yash Chopra"]
    },
    {
      "question": "Who directed the epic movie 'Mughal-e-Azam'?",
      "answer": "K. Asif",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "K. Asif", "Yash Chopra"]
    },
    {
      "question": "Who is known for movies like 'Guide' and 'Jewel Thief'?",
      "answer": "Vijay Anand",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Vijay Anand"]
    },
    {
      "question": "Who directed the critically acclaimed movie 'Masaan'?",
      "answer": "Neeraj Ghaywan",
      "options": ["Karan Johar", "Sanjay Leela Bhansali", "Rajkumar Hirani", "Neeraj Ghaywan"]
    }
  ] 
 },

 //Birds and Animals
 { 
  id: 55, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Birds of Prey",
  questionList: [
    {
      "question": "Which bird is known as the 'king of birds'?",
      "answer": "Eagle",
      "options": ["Hawk", "Falcon", "Owl", "Eagle"]
    },
    {
      "question": "Which bird has the keenest eyesight among all birds?",
      "answer": "Falcon",
      "options": ["Hawk", "Falcon", "Eagle", "Owl"]
    },
    {
      "question": "Which bird is the fastest animal on Earth?",
      "answer": "Peregrine Falcon",
      "options": ["Hawk", "Falcon", "Eagle", "Owl"]
    },
    {
      "question": "Which bird is known for its silent flight due to specialized feathers?",
      "answer": "Owl",
      "options": ["Hawk", "Falcon", "Eagle", "Owl"]
    },
    {
      "question": "Which bird is often associated with wisdom and knowledge?",
      "answer": "Owl",
      "options": ["Hawk", "Falcon", "Eagle", "Owl"]
    },
    {
      "question": "Which bird is the national bird of the United States?",
      "answer": "Bald Eagle",
      "options": ["Hawk", "Falcon", "Bald Eagle", "Owl"]
    },
    {
      "question": "Which bird is known for its hovering ability while hunting for prey?",
      "answer": "Kestrel",
      "options": ["Hawk", "Falcon", "Kestrel", "Eagle"]
    },
    {
      "question": "Which bird is commonly associated with carrying messages?",
      "answer": "Pigeon",
      "options": ["Hawk", "Falcon", "Pigeon", "Eagle"]
    },
    {
      "question": "Which bird is known for its strong talons and powerful grip?",
      "answer": "Golden Eagle",
      "options": ["Hawk", "Golden Eagle", "Pigeon", "Eagle"]
    },
    {
      "question": "Which bird of prey is native to Australia and is the largest bird of prey in the country?",
      "answer": "Wedge-tailed Eagle",
      "options": ["Hawk", "Falcon", "Wedge-tailed Eagle", "Eagle"]
    },
    {
      "question": "Which bird of prey is known for its distinctive cry that sounds like 'kee-eeeee-arr'?",
      "answer": "Red-tailed Hawk",
      "options": ["Red-tailed Hawk", "Falcon", "Pigeon", "Eagle"]
    },
    {
      "question": "Which bird of prey is known for its ability to dive at speeds over 240 miles per hour?",
      "answer": "Peregrine Falcon",
      "options": ["Hawk", "Peregrine Falcon", "Pigeon", "Eagle"]
    }
  ]  
 },

 { 
  id: 56, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Big Cats",
  questionList:[
    {
      "question": "Which big cat is known as the 'king of the jungle'?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is the largest of all the big cats?",
      "answer": "Tiger",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its distinctive rosette pattern on its coat?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is the fastest land animal?",
      "answer": "Cheetah",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its solitary and elusive nature?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often found in the dense forests of India?",
      "answer": "Tiger",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its mane, which only males possess?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its ability to climb trees?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often depicted as a symbol of courage and strength?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its retractable claws and excellent night vision?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often found in the savannas and grasslands of Africa?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its speed and agility in hunting prey?",
      "answer": "Cheetah",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    }
  ] 
 },

 { 
  id: 57, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Big Cats",
  questionList:[
    {
      "question": "Which big cat is known as the 'king of the jungle'?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is the largest of all the big cats?",
      "answer": "Tiger",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its distinctive rosette pattern on its coat?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is the fastest land animal?",
      "answer": "Cheetah",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its solitary and elusive nature?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often found in the dense forests of India?",
      "answer": "Tiger",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its mane, which only males possess?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its ability to climb trees?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often depicted as a symbol of courage and strength?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its retractable claws and excellent night vision?",
      "answer": "Leopard",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is often found in the savannas and grasslands of Africa?",
      "answer": "Lion",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    },
    {
      "question": "Which big cat is known for its speed and agility in hunting prey?",
      "answer": "Cheetah",
      "options": ["Lion", "Tiger", "Leopard", "Cheetah"]
    }
  ] 
 },
 { 
  id: 58, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Marine Life",
  questionList:[
    {
      "question": "Which marine animal is known as the 'king of the ocean'?",
      "answer": "Great White Shark",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is the largest mammal on Earth?",
      "answer": "Blue Whale",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is known for its playful behavior and high intelligence?",
      "answer": "Dolphin",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is also known as an orca?",
      "answer": "Killer Whale",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is capable of echolocation?",
      "answer": "Dolphin",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is known for its distinct black and white coloration?",
      "answer": "Killer Whale",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is the largest fish in the ocean?",
      "answer": "Whale Shark",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Whale Shark"]
    },
    {
      "question": "Which marine animal is known for its sharp teeth and powerful bite?",
      "answer": "Great White Shark",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is often referred to as the 'gentle giant'?",
      "answer": "Whale Shark",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Whale Shark"]
    },
    {
      "question": "Which marine animal is known for its long migrations across the ocean?",
      "answer": "Blue Whale",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Great White Shark"]
    },
    {
      "question": "Which marine animal is considered a keystone species in marine ecosystems?",
      "answer": "Sea Otter",
      "options": ["Dolphin", "Killer Whale", "Blue Whale", "Sea Otter"]
    },
    {
      "question": "Which marine animal is known for its distinctive spiraled horn?",
      "answer": "Narwhal",
      "options": ["Dolphin", "Killer Whale", "Narwhal", "Great White Shark"]
    }
  ]  
 },

 { 
  id: 59, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Wild Cats",
  questionList:[
    {
      "question": "Which wild cat is known for its tufted ears and bobbed tail?",
      "answer": "Bobcat",
      "options": ["Cheetah", "Leopard", "Bobcat", "Lynx"]
    },
    {
      "question": "Which wild cat is native to the Americas and has a distinctive spotted coat?",
      "answer": "Jaguar",
      "options": ["Cheetah", "Leopard", "Jaguar", "Lynx"]
    },
    {
      "question": "Which wild cat is known for its ability to roar but not purr?",
      "answer": "Lion",
      "options": ["Cheetah", "Lion", "Jaguar", "Leopard"]
    },
    {
      "question": "Which wild cat is native to North America and has a short tail and tufted ears?",
      "answer": "Lynx",
      "options": ["Cheetah", "Leopard", "Bobcat", "Lynx"]
    },
    {
      "question": "Which wild cat is known for its speed and agility in chasing down prey?",
      "answer": "Cheetah",
      "options": ["Cheetah", "Leopard", "Bobcat", "Lynx"]
    },
    {
      "question": "Which wild cat is the only one that can't fully retract its claws?",
      "answer": "Cheetah",
      "options": ["Cheetah", "Lion", "Jaguar", "Leopard"]
    },
    {
      "question": "Which wild cat is known for its solitary and elusive nature?",
      "answer": "Leopard",
      "options": ["Cheetah", "Lion", "Jaguar", "Leopard"]
    },
    {
      "question": "Which wild cat is native to South and Southeast Asia and is known for its strength?",
      "answer": "Tiger",
      "options": ["Cheetah", "Tiger", "Jaguar", "Leopard"]
    },
    {
      "question": "Which wild cat is known for its ability to climb trees and swim?",
      "answer": "Leopard",
      "options": ["Cheetah", "Lion", "Jaguar", "Leopard"]
    },
    {
      "question": "Which wild cat is known for its distinctive black coat and is native to Asia?",
      "answer": "Black Panther",
      "options": ["Cheetah", "Lion", "Black Panther", "Leopard"]
    },
    {
      "question": "Which wild cat is known for its powerful jaws and ability to kill large prey?",
      "answer": "Jaguar",
      "options": ["Cheetah", "Jaguar", "Bobcat", "Leopard"]
    },
    {
      "question": "Which wild cat is known for its ability to leap great distances?",
      "answer": "Leopard",
      "options": ["Cheetah", "Lion", "Jaguar", "Leopard"]
    }
  ]
 },

 { 
  id: 60, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Birds of Paradise",
  questionList:[
    {
      "question": "Which bird of paradise is known for its vibrant blue and green plumage?",
      "answer": "King of Saxony Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is named after the wife of the Duke of York?",
      "answer": "Victoria's Riflebird",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Victoria's Riflebird"]
    },
    {
      "question": "Which bird of paradise is known for its vibrant orange and yellow plumage?",
      "answer": "Raggiana Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is known for its iridescent green and blue throat feathers?",
      "answer": "Wilson's Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is known for its elaborate courtship displays?",
      "answer": "Greater Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is native to the island of New Guinea?",
      "answer": "All of the above",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is known for its long, white tail feathers?",
      "answer": "King of Saxony Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise has a courtship display that includes bowing, hopping, and wing-flapping?",
      "answer": "Victoria's Riflebird",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Victoria's Riflebird"]
    },
    {
      "question": "Which bird of paradise is known for its elaborate head plumes?",
      "answer": "Raggiana Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise is named after the wife of King William IV of the United Kingdom?",
      "answer": "Greater Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    },
    {
      "question": "Which bird of paradise has a call that sounds like a creaky gate?",
      "answer": "Victoria's Riflebird",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Victoria's Riflebird"]
    },
    {
      "question": "Which bird of paradise has a courtship display that involves twirling and dancing?",
      "answer": "Wilson's Bird of Paradise",
      "options": ["King of Saxony Bird of Paradise", "Wilson's Bird of Paradise", "Raggiana Bird of Paradise", "Greater Bird of Paradise"]
    }
  ]  
 },

 { 
  id: 61, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Farm Animals",
  questionList:[
    {
      "question": "Which farm animal is known for producing wool?",
      "answer": "Sheep",
      "options": ["Sheep", "Cow", "Goat", "Pig"]
    },
    {
      "question": "Which farm animal is known for its milk production?",
      "answer": "Cow",
      "options": ["Sheep", "Cow", "Goat", "Pig"]
    },
    {
      "question": "Which farm animal is known for its meat and milk production?",
      "answer": "Goat",
      "options": ["Sheep", "Cow", "Goat", "Pig"]
    },
    {
      "question": "Which farm animal is often kept for its eggs?",
      "answer": "Chicken",
      "options": ["Chicken", "Duck", "Turkey", "Goose"]
    },
    {
      "question": "Which farm animal is often used for its meat during festive occasions?",
      "answer": "Turkey",
      "options": ["Chicken", "Duck", "Turkey", "Goose"]
    },
    {
      "question": "Which farm animal is known for its role in pollination and honey production?",
      "answer": "Bee",
      "options": ["Bee", "Butterfly", "Wasp", "Ant"]
    },
    {
      "question": "Which farm animal is known for its role in controlling pests in agricultural fields?",
      "answer": "Cat",
      "options": ["Dog", "Cat", "Rat", "Snake"]
    },
    {
      "question": "Which farm animal is known for its ability to pull heavy loads?",
      "answer": "Ox",
      "options": ["Horse", "Donkey", "Ox", "Mule"]
    },
    {
      "question": "Which farm animal is known for its long lifespan and production of milk?",
      "answer": "Cow",
      "options": ["Sheep", "Cow", "Goat", "Pig"]
    },
    {
      "question": "Which farm animal is known for its meat, which is often used in various cuisines?",
      "answer": "Pig",
      "options": ["Sheep", "Cow", "Goat", "Pig"]
    },
    {
      "question": "Which farm animal is often associated with the phrase 'as stubborn as a...'?",
      "answer": "Mule",
      "options": ["Horse", "Donkey", "Ox", "Mule"]
    },
    {
      "question": "Which farm animal is known for its distinctive crowing in the early morning?",
      "answer": "Rooster",
      "options": ["Chicken", "Duck", "Turkey", "Rooster"]
    }
  ]  
 },

 { 
  id: 62, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Exotic Birds",
  questionList:[
    {
      "question": "Which exotic bird is known for its large, colorful bill?",
      "answer": "Toucan",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its ability to mimic human speech?",
      "answer": "Parrot",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for standing on one leg for long periods?",
      "answer": "Flamingo",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its vibrant plumage and elaborate courtship display?",
      "answer": "Peacock",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is native to Central and South America and has a long, curved bill?",
      "answer": "Toucan",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its distinctive call and bright green feathers?",
      "answer": "Quetzal",
      "options": ["Quetzal", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is considered a symbol of love and fidelity?",
      "answer": "Dove",
      "options": ["Dove", "Parrot", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its aerial acrobatics and courtship displays?",
      "answer": "Hummingbird",
      "options": ["Hummingbird", "Parrot", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is the national bird of India and known for its majestic tail feathers?",
      "answer": "Peacock",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its bright pink feathers and distinctive bill?",
      "answer": "Flamingo",
      "options": ["Parrot", "Flamingo", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its ability to fly backward and hover in mid-air?",
      "answer": "Hummingbird",
      "options": ["Hummingbird", "Parrot", "Toucan", "Peacock"]
    },
    {
      "question": "Which exotic bird is known for its connection to ancient Aztec and Mayan cultures?",
      "answer": "Quetzal",
      "options": ["Quetzal", "Parrot", "Toucan", "Peacock"]
    }
  ]  
 },

 { 
  id: 63, 
  img: "birdsandanimals.png", 
  category: "Bollywood", 
  name:"Predatory Birds",
  questionList:[
    {
      "question": "Which predatory bird is known for its distinctive 'hoo-hoo' call?",
      "answer": "Great Horned Owl",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its impressive diving speed during hunting?",
      "answer": "Peregrine Falcon",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its scavenging behavior and hooked beak?",
      "answer": "Vulture",
      "options": ["Vulture", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its distinctive white head and tail feathers?",
      "answer": "Bald Eagle",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its widespread distribution and piercing call?",
      "answer": "Red-tailed Hawk",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its silent flight and keen hunting abilities?",
      "answer": "Owl",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its ability to snatch prey from the water with its talons?",
      "answer": "Osprey",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Osprey", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its powerful talons and nocturnal hunting behavior?",
      "answer": "Owl",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Owl"]
    },
    {
      "question": "Which predatory bird is known for its sleek, streamlined body and high-speed dives?",
      "answer": "Peregrine Falcon",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its ability to soar effortlessly on thermal currents?",
      "answer": "Vulture",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Great Horned Owl", "Vulture"]
    },
    {
      "question": "Which predatory bird is known for its strong beak and sharp talons used for tearing flesh?",
      "answer": "Hawk",
      "options": ["Bald Eagle", "Hawk", "Great Horned Owl", "Peregrine Falcon"]
    },
    {
      "question": "Which predatory bird is known for its habit of nesting on high cliffs and ledges?",
      "answer": "Osprey",
      "options": ["Bald Eagle", "Red-tailed Hawk", "Osprey", "Peregrine Falcon"]
    }
  ]  
 },

//Personalities
{ 
  id: 64, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Introverted Personalities",
  questionList:[
    {
      "question": "Which term describes individuals who prefer solitary activities over social gatherings?",
      "answer": "Introvert",
      "options": ["Extrovert", "Ambivert", "Introvert", "Socialite"]
    },
    {
      "question": "What is the opposite personality type of an introvert?",
      "answer": "Extrovert",
      "options": ["Introvert", "Ambivert", "Extrovert", "Solitude"]
    },
    {
      "question": "Which type of environment do introverts typically find draining?",
      "answer": "Overstimulating",
      "options": ["Relaxing", "Overstimulating", "Quiet", "Serene"]
    },
    {
      "question": "What kind of social interactions do introverts often find challenging?",
      "answer": "Large gatherings",
      "options": ["One-on-one conversations", "Large gatherings", "Group activities", "Public speaking"]
    },
    {
      "question": "Which trait is commonly associated with introverted individuals?",
      "answer": "Thoughtfulness",
      "options": ["Boldness", "Thoughtfulness", "Talkativeness", "Impulsiveness"]
    },
    {
      "question": "What type of communication style do introverts tend to prefer?",
      "answer": "Written",
      "options": ["Verbal", "Written", "Visual", "Non-verbal"]
    },
    {
      "question": "Which social situations do introverts typically find energizing?",
      "answer": "Intimate gatherings",
      "options": ["Intimate gatherings", "Large parties", "Networking events", "Conferences"]
    },
    {
      "question": "What type of activities do introverts often enjoy?",
      "answer": "Reading",
      "options": ["Networking", "Reading", "Socializing", "Partying"]
    },
    {
      "question": "Which environment do introverts often seek out to recharge?",
      "answer": "Quiet and peaceful",
      "options": ["Lively and bustling", "Quiet and peaceful", "Crowded and noisy", "Stimulating and dynamic"]
    },
    {
      "question": "What kind of approach do introverts typically take to problem-solving?",
      "answer": "Reflective",
      "options": ["Collaborative", "Reflective", "Impulsive", "Assertive"]
    },
    {
      "question": "Which activity do introverts often engage in to unwind?",
      "answer": "Solo hobbies",
      "options": ["Team sports", "Solo hobbies", "Social events", "Concerts"]
    },
    {
      "question": "Which environment do introverts often thrive in?",
      "answer": "Independent work settings",
      "options": ["Open-plan offices", "Independent work settings", "Busy cafes", "Loud nightclubs"]
    }
  ]  
 },

 { 
  id: 65, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Extroverted Personalities",
  questionList:[
    {
      "question": "Which term describes individuals who gain energy from social interactions?",
      "answer": "Extrovert",
      "options": ["Introvert", "Ambivert", "Extrovert", "Loner"]
    },
    {
      "question": "What is the opposite personality type of an extrovert?",
      "answer": "Introvert",
      "options": ["Introvert", "Ambivert", "Extrovert", "Solitude"]
    },
    {
      "question": "Which type of environment do extroverts typically find energizing?",
      "answer": "Social and bustling",
      "options": ["Quiet and serene", "Social and bustling", "Isolated and remote", "Peaceful and calm"]
    },
    {
      "question": "What kind of social interactions do extroverts often enjoy?",
      "answer": "Large gatherings",
      "options": ["One-on-one conversations", "Large gatherings", "Group activities", "Public speaking"]
    },
    {
      "question": "Which trait is commonly associated with extroverted individuals?",
      "answer": "Outgoing",
      "options": ["Reserved", "Outgoing", "Thoughtful", "Introverted"]
    },
    {
      "question": "What type of communication style do extroverts tend to prefer?",
      "answer": "Verbal",
      "options": ["Verbal", "Written", "Visual", "Non-verbal"]
    },
    {
      "question": "Which social situations do extroverts typically find energizing?",
      "answer": "Networking events",
      "options": ["Intimate gatherings", "Large parties", "Networking events", "Conferences"]
    },
    {
      "question": "What type of activities do extroverts often enjoy?",
      "answer": "Socializing",
      "options": ["Reading", "Networking", "Socializing", "Partying"]
    },
    {
      "question": "Which environment do extroverts often seek out to recharge?",
      "answer": "Lively and bustling",
      "options": ["Lively and bustling", "Quiet and peaceful", "Crowded and noisy", "Stimulating and dynamic"]
    },
    {
      "question": "What kind of approach do extroverts typically take to problem-solving?",
      "answer": "Collaborative",
      "options": ["Collaborative", "Reflective", "Impulsive", "Assertive"]
    },
    {
      "question": "Which activity do extroverts often engage in to unwind?",
      "answer": "Social events",
      "options": ["Team sports", "Solo hobbies", "Social events", "Concerts"]
    },
    {
      "question": "Which environment do extroverts often thrive in?",
      "answer": "Open-plan offices",
      "options": ["Open-plan offices", "Independent work settings", "Busy cafes", "Loud nightclubs"]
    }
  ]  
 },
 { 
  id: 66, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Ambivert Personalities",
  questionList:[
    {
      "question": "What term describes individuals who exhibit both introverted and extroverted traits?",
      "answer": "Ambivert",
      "options": ["Introvert", "Extrovert", "Ambivert", "Unsure"]
    },
    {
      "question": "Which personality type can adapt to various social situations?",
      "answer": "Ambivert",
      "options": ["Introvert", "Extrovert", "Ambivert", "Reserved"]
    },
    {
      "question": "What type of environment do ambiverts often feel comfortable in?",
      "answer": "Varies depending on mood",
      "options": ["Quiet and peaceful", "Social and bustling", "Varies depending on mood", "Isolated and remote"]
    },
    {
      "question": "In social settings, do ambiverts prefer smaller or larger groups?",
      "answer": "Both, depending on the situation",
      "options": ["Smaller groups", "Larger groups", "Both, depending on the situation", "Neither, they prefer solitude"]
    },
    {
      "question": "Which trait is characteristic of ambiverts?",
      "answer": "Adaptable",
      "options": ["Reserved", "Outgoing", "Adaptable", "Introverted"]
    },
    {
      "question": "How do ambiverts typically communicate?",
      "answer": "Both verbally and in writing",
      "options": ["Verbally only", "In writing only", "Both verbally and in writing", "Non-verbally"]
    },
    {
      "question": "What type of social situations do ambiverts find enjoyable?",
      "answer": "A mix of intimate gatherings and large events",
      "options": ["Intimate gatherings", "Large parties", "Networking events", "A mix of intimate gatherings and large events"]
    },
    {
      "question": "What type of activities do ambiverts often engage in?",
      "answer": "Varied activities depending on mood",
      "options": ["Reading", "Socializing", "Solo hobbies", "Varied activities depending on mood"]
    },
    {
      "question": "Which environment do ambiverts seek to recharge in?",
      "answer": "Anywhere they can have some alone time",
      "options": ["Lively and bustling", "Quiet and peaceful", "Crowded and noisy", "Anywhere they can have some alone time"]
    },
    {
      "question": "How do ambiverts approach problem-solving?",
      "answer": "Flexibly, using both collaborative and reflective methods",
      "options": ["Collaborative", "Reflective", "Impulsive", "Flexibly, using both collaborative and reflective methods"]
    },
    {
      "question": "What activities do ambiverts engage in to unwind?",
      "answer": "Whatever they feel like doing in the moment",
      "options": ["Team sports", "Social events", "Solo hobbies", "Whatever they feel like doing in the moment"]
    },
    {
      "question": "Which environment do ambiverts thrive in?",
      "answer": "Varied environments",
      "options": ["Open-plan offices", "Independent work settings", "Busy cafes", "Varied environments"]
    }
  ]  
 },

 { 
  id: 67, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Reserved Personalities",
  questionList:[
    {
      "question": "Which term describes individuals who are quiet and prefer to keep to themselves?",
      "answer": "Reserved",
      "options": ["Outgoing", "Reserved", "Ambivert", "Socialite"]
    },
    {
      "question": "What type of social situations do reserved individuals typically avoid?",
      "answer": "Large gatherings",
      "options": ["One-on-one conversations", "Large gatherings", "Group activities", "Public speaking"]
    },
    {
      "question": "In social settings, do reserved individuals prefer to observe or engage?",
      "answer": "Observe",
      "options": ["Observe", "Engage", "It varies", "Depends on their mood"]
    },
    {
      "question": "Which trait is commonly associated with reserved individuals?",
      "answer": "Shy",
      "options": ["Outgoing", "Reserved", "Adventurous", "Extroverted"]
    },
    {
      "question": "How do reserved individuals typically communicate?",
      "answer": "Quietly and succinctly",
      "options": ["Verbally and loudly", "In writing only", "Quietly and succinctly", "Verbally but hesitantly"]
    },
    {
      "question": "What type of activities do reserved individuals often enjoy?",
      "answer": "Solo hobbies or quiet group activities",
      "options": ["Team sports", "Social events", "Solo hobbies or quiet group activities", "Adventurous outdoor activities"]
    },
    {
      "question": "Which environment do reserved individuals prefer?",
      "answer": "Quiet and calm",
      "options": ["Lively and bustling", "Quiet and calm", "Crowded and noisy", "Varied, depending on mood"]
    },
    {
      "question": "How do reserved individuals typically approach problem-solving?",
      "answer": "Independently",
      "options": ["Collaboratively", "Independently", "With hesitation", "It varies"]
    },
    {
      "question": "What activities do reserved individuals engage in to unwind?",
      "answer": "Reading or solitary activities",
      "options": ["Attending parties", "Socializing with friends", "Reading or solitary activities", "Going on adventures"]
    },
    {
      "question": "Which environment do reserved individuals thrive in?",
      "answer": "Quiet and serene environments",
      "options": ["Open-plan offices", "Independent work settings", "Busy cafes", "Quiet and serene environments"]
    },
    {
      "question": "How do reserved individuals feel about public speaking?",
      "answer": "Uncomfortable",
      "options": ["Excited", "Uncomfortable", "Indifferent", "Nervous but willing"]
    },
    {
      "question": "What type of social interactions do reserved individuals prefer?",
      "answer": "One-on-one conversations",
      "options": ["Large gatherings", "Group activities", "One-on-one conversations", "Public speaking"]
    }
  ]  
 },

 { 
  id: 68, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Adventurous Personalities",
  questionList:[
    {
      "question": "Which term describes individuals who seek out new experiences and enjoy taking risks?",
      "answer": "Adventurous",
      "options": ["Reserved", "Adventurous", "Cautious", "Introverted"]
    },
    {
      "question": "What kind of activities do adventurous individuals typically enjoy?",
      "answer": "Extreme sports and outdoor adventures",
      "options": ["Reading", "Watching movies", "Extreme sports and outdoor adventures", "Attending social events"]
    },
    {
      "question": "In social situations, do adventurous individuals prefer routine or spontaneity?",
      "answer": "Spontaneity",
      "options": ["Routine", "Spontaneity", "It varies", "Depends on their mood"]
    },
    {
      "question": "Which trait is commonly associated with adventurous individuals?",
      "answer": "Fearlessness",
      "options": ["Cautiousness", "Fearlessness", "Shyness", "Conformity"]
    },
    {
      "question": "How do adventurous individuals typically communicate?",
      "answer": "Energetically and enthusiastically",
      "options": ["Quietly and reservedly", "Energetically and enthusiastically", "In writing only", "With hesitation"]
    },
    {
      "question": "What type of environment do adventurous individuals thrive in?",
      "answer": "Dynamic and challenging environments",
      "options": ["Quiet and calm", "Dynamic and challenging environments", "Crowded and noisy", "Varied, depending on mood"]
    },
    {
      "question": "How do adventurous individuals approach problem-solving?",
      "answer": "Creatively and boldly",
      "options": ["Collaboratively", "Creatively and boldly", "With hesitation", "It varies"]
    },
    {
      "question": "What activities do adventurous individuals engage in to unwind?",
      "answer": "Seeking out new experiences",
      "options": ["Reading or solitary activities", "Socializing with friends", "Seeking out new experiences", "Going on vacations"]
    },
    {
      "question": "Which type of social interactions do adventurous individuals prefer?",
      "answer": "Group activities and events",
      "options": ["One-on-one conversations", "Large gatherings", "Group activities and events", "Public speaking"]
    },
    {
      "question": "How do adventurous individuals feel about routine?",
      "answer": "Constricted by it",
      "options": ["Comfortable with it", "Constricted by it", "Indifferent towards it", "Thrilled by it"]
    },
    {
      "question": "What type of environment do adventurous individuals find stifling?",
      "answer": "Predictable and mundane environments",
      "options": ["Lively and bustling", "Quiet and serene environments", "Predictable and mundane environments", "Crowded and noisy"]
    },
    {
      "question": "Which activities do adventurous individuals avoid?",
      "answer": "Safe and routine activities",
      "options": ["Extreme sports", "Safe and routine activities", "Watching movies at home", "Socializing with close friends only"]
    }
  ]  
 },

 { 
  id: 69, 
  img: "personalities.png", 
  category: "Personalities", 
  name:"Analytical Personalities",
  questionList:[
    {
      "question": "Which term describes individuals who are logical and methodical thinkers?",
      "answer": "Analytical",
      "options": ["Impulsive", "Analytical", "Creative", "Emotional"]
    },
    {
      "question": "What kind of activities do analytical individuals typically enjoy?",
      "answer": "Problem-solving and data analysis",
      "options": ["Extreme sports", "Reading fiction", "Problem-solving and data analysis", "Attending parties"]
    },
    {
      "question": "In social situations, do analytical individuals prefer structure or spontaneity?",
      "answer": "Structure",
      "options": ["Structure", "Spontaneity", "It varies", "Depends on their mood"]
    },
    {
      "question": "Which trait is commonly associated with analytical individuals?",
      "answer": "Logical",
      "options": ["Intuitive", "Logical", "Emotional", "Impulsive"]
    },
    {
      "question": "How do analytical individuals typically communicate?",
      "answer": "Rationally and objectively",
      "options": ["Emotionally and subjectively", "Rationally and objectively", "In writing only", "With hesitation"]
    },
    {
      "question": "What type of environment do analytical individuals thrive in?",
      "answer": "Structured and organized environments",
      "options": ["Dynamic and challenging environments", "Quiet and calm", "Structured and organized environments", "Varied, depending on mood"]
    },
    {
      "question": "How do analytical individuals approach problem-solving?",
      "answer": "Systematically and logically",
      "options": ["Collaboratively", "Systematically and logically", "With hesitation", "It varies"]
    },
    {
      "question": "What activities do analytical individuals engage in to unwind?",
      "answer": "Engaging in intellectually stimulating activities",
      "options": ["Reading fiction", "Socializing with friends", "Engaging in intellectually stimulating activities", "Going on adventures"]
    },
    {
      "question": "Which type of social interactions do analytical individuals prefer?",
      "answer": "Intellectual discussions",
      "options": ["One-on-one conversations", "Large gatherings", "Intellectual discussions", "Public speaking"]
    },
    {
      "question": "How do analytical individuals feel about ambiguity?",
      "answer": "Uncomfortable",
      "options": ["Comfortable", "Uncomfortable", "Indifferent", "Depends on the context"]
    },
    {
      "question": "What type of environment do analytical individuals find overwhelming?",
      "answer": "Unstructured and chaotic environments",
      "options": ["Lively and bustling", "Quiet and serene environments", "Unstructured and chaotic environments", "Predictable and mundane environments"]
    },
    {
      "question": "Which activities do analytical individuals avoid?",
      "answer": "Impulsive and risky activities",
      "options": ["Extreme sports", "Problem-solving and data analysis", "Impulsive and risky activities", "Attending parties"]
    }
  ]  
 },

 //Business
 { 
  id: 70, 
  img: "business.png", 
  category: "Business", 
  name:"Retail Business",
  questionList:[
    {
      "question": "What type of business sells products directly to consumers?",
      "answer": "Retail",
      "options": ["Wholesale", "Retail", "Manufacturing", "Service"]
    },
    {
      "question": "Which of the following is an example of a retail business?",
      "answer": "Clothing store",
      "options": ["Factory", "Warehouse", "Clothing store", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a retail business?",
      "answer": "Sell products to consumers",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Sell products to consumers", "Manufacture goods"]
    },
    {
      "question": "Which retail business model involves selling products through physical storefronts?",
      "answer": "Brick-and-mortar",
      "options": ["E-commerce", "Dropshipping", "Brick-and-mortar", "Direct sales"]
    },
    {
      "question": "What is a common challenge faced by retail businesses?",
      "answer": "Seasonal fluctuations in sales",
      "options": ["High profit margins", "Stable demand for products", "Seasonal fluctuations in sales", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a retail business?",
      "answer": "Location",
      "options": ["Product quality", "Employee salaries", "Location", "Online presence"]
    },
    {
      "question": "What type of inventory management system do many retail businesses use?",
      "answer": "Just-in-time",
      "options": ["First-in, first-out (FIFO)", "Last-in, first-out (LIFO)", "Just-in-time", "Batch processing"]
    },
    {
      "question": "What is the term for the process of persuading customers to buy more products?",
      "answer": "Upselling",
      "options": ["Downselling", "Cross-selling", "Upselling", "Discounting"]
    },
    {
      "question": "Which retail strategy involves offering discounts on products?",
      "answer": "Promotions",
      "options": ["Promotions", "Premium pricing", "Price skimming", "Price bundling"]
    },
    {
      "question": "What is the term for the area where retail transactions take place?",
      "answer": "Point of sale (POS)",
      "options": ["Point of purchase (POP)", "Point of sale (POS)", "Point of contact (POC)", "Point of transaction (POT)"]
    },
    {
      "question": "Which retail business model focuses on selling a wide variety of products?",
      "answer": "Department store",
      "options": ["Specialty store", "Department store", "Boutique", "Discount store"]
    },
    {
      "question": "What is the term for the process of bringing new products to market?",
      "answer": "Product launch",
      "options": ["Product development", "Product launch", "Product promotion", "Product distribution"]
    }
  ]  
 },
 { 
  id: 71, 
  img: "business.png", 
  category: "Business", 
  name:"E-commerce Business",
  questionList:[
    {
      "question": "What type of business conducts transactions over the internet?",
      "answer": "E-commerce",
      "options": ["Brick-and-mortar", "Wholesale", "E-commerce", "Manufacturing"]
    },
    {
      "question": "Which of the following is an example of an e-commerce business?",
      "answer": "Online bookstore",
      "options": ["Supermarket", "Warehouse", "Online bookstore", "Distribution center"]
    },
    {
      "question": "What is the primary platform for e-commerce transactions?",
      "answer": "Online marketplace",
      "options": ["Physical storefront", "Online marketplace", "Television shopping channel", "Catalog"]
    },
    {
      "question": "Which e-commerce model involves selling products through a third-party platform?",
      "answer": "Online marketplace",
      "options": ["Direct sales", "Dropshipping", "Online marketplace", "Brick-and-mortar"]
    },
    {
      "question": "What is a common challenge faced by e-commerce businesses?",
      "answer": "Shopping cart abandonment",
      "options": ["Physical store theft", "Long checkout lines", "Shopping cart abandonment", "High rent costs"]
    },
    {
      "question": "Which factor often influences the success of an e-commerce business?",
      "answer": "User experience (UX)",
      "options": ["Product variety", "Employee salaries", "User experience (UX)", "Store layout"]
    },
    {
      "question": "What type of inventory management system do many e-commerce businesses use?",
      "answer": "Dropshipping",
      "options": ["Just-in-time", "First-in, first-out (FIFO)", "Dropshipping", "Batch processing"]
    },
    {
      "question": "What is the term for recommending related products to customers?",
      "answer": "Cross-selling",
      "options": ["Downselling", "Cross-selling", "Upselling", "Discounting"]
    },
    {
      "question": "Which e-commerce strategy involves offering free shipping?",
      "answer": "Shipping incentives",
      "options": ["Flash sales", "Shipping incentives", "Price skimming", "Price bundling"]
    },
    {
      "question": "What is the term for the page where customers enter payment information?",
      "answer": "Checkout page",
      "options": ["Product page", "Landing page", "Checkout page", "Homepage"]
    },
    {
      "question": "Which e-commerce business model focuses on selling a specific type of product?",
      "answer": "Niche market",
      "options": ["Department store", "Niche market", "Boutique", "Discount store"]
    },
    {
      "question": "What is the term for the process of improving website visibility in search engines?",
      "answer": "Search engine optimization (SEO)",
      "options": ["Pay-per-click (PPC) advertising", "Social media marketing", "Search engine optimization (SEO)", "Email marketing"]
    }
  ]
},
{ 
  id: 72, 
  img: "business.png", 
  category: "Business", 
  name:"Food Service Business",
  questionList:[
    {
      "question": "What type of business serves prepared food and beverages to customers?",
      "answer": "Food service",
      "options": ["Retail", "Manufacturing", "Food service", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a food service business?",
      "answer": "Restaurant",
      "options": ["Factory", "Warehouse", "Restaurant", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a food service business?",
      "answer": "Provide meals to customers",
      "options": ["Sell products to other businesses", "Provide meals to customers", "Manufacture goods", "Distribute goods"]
    },
    {
      "question": "Which food service model involves customers ordering and receiving food at a counter?",
      "answer": "Fast casual",
      "options": ["Fine dining", "Buffet", "Fast casual", "Food truck"]
    },
    {
      "question": "What is a common challenge faced by food service businesses?",
      "answer": "High employee turnover",
      "options": ["Stable demand for products", "High employee turnover", "Seasonal fluctuations in sales", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a restaurant?",
      "answer": "Location",
      "options": ["Product quality", "Employee salaries", "Location", "Online presence"]
    },
    {
      "question": "What type of menu is typically used by fast food restaurants?",
      "answer": "Limited",
      "options": ["À la carte", "Tasting", "Limited", "Degustation"]
    },
    {
      "question": "What is the term for a fixed price menu offering a complete meal?",
      "answer": "Table d'hôte",
      "options": ["Buffet", "À la carte", "Degustation", "Table d'hôte"]
    },
    {
      "question": "Which food service strategy involves offering discounts during off-peak hours?",
      "answer": "Happy hour",
      "options": ["Early bird special", "Happy hour", "Weekend brunch", "Late-night menu"]
    },
    {
      "question": "What is the term for a restaurant's ambiance and atmosphere?",
      "answer": "Ambience",
      "options": ["Cuisine", "Service", "Décor", "Ambience"]
    },
    {
      "question": "Which food service model focuses on offering a wide variety of dishes for a fixed price?",
      "answer": "Buffet",
      "options": ["Fine dining", "Fast casual", "Food truck", "Buffet"]
    },
    {
      "question": "What is the term for a restaurant that specializes in grilled meat dishes?",
      "answer": "Steakhouse",
      "options": ["Pizzeria", "Sushi bar", "Steakhouse", "Bistro"]
    }
  ]  
 },

 { 
  id: 73, 
  img: "business.png", 
  category: "Business", 
  name:"Healthcare Business",
  questionList:[
    {
      "question": "What type of business provides medical services to patients?",
      "answer": "Healthcare",
      "options": ["Retail", "Manufacturing", "Healthcare", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a healthcare business?",
      "answer": "Hospital",
      "options": ["Factory", "Warehouse", "Hospital", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a healthcare business?",
      "answer": "Provide medical treatment",
      "options": ["Sell products to other businesses", "Provide medical treatment", "Manufacture goods", "Distribute goods"]
    },
    {
      "question": "Which healthcare model involves patients receiving treatment in their own homes?",
      "answer": "Home healthcare",
      "options": ["Primary care", "Specialty care", "Home healthcare", "Urgent care"]
    },
    {
      "question": "What is a common challenge faced by healthcare businesses?",
      "answer": "Regulatory compliance",
      "options": ["Stable demand for services", "High employee turnover", "Regulatory compliance", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a healthcare facility?",
      "answer": "Quality of care",
      "options": ["Patient demographics", "Employee salaries", "Quality of care", "Location"]
    },
    {
      "question": "What type of healthcare facility specializes in treating specific diseases or conditions?",
      "answer": "Specialty hospital",
      "options": ["General hospital", "Clinic", "Specialty hospital", "Urgent care center"]
    },
    {
      "question": "What is the term for the process of ensuring patient safety and confidentiality?",
      "answer": "HIPAA compliance",
      "options": ["EMR implementation", "ICD-10 coding", "HIPAA compliance", "Telemedicine integration"]
    },
    {
      "question": "Which healthcare strategy focuses on preventing illnesses and promoting wellness?",
      "answer": "Preventive care",
      "options": ["Emergency care", "Acute care", "Preventive care", "Rehabilitative care"]
    },
    {
      "question": "What is the term for the practice of providing medical care to individuals without insurance?",
      "answer": "Charity care",
      "options": ["Managed care", "Universal healthcare", "Charity care", "Private insurance"]
    },
    {
      "question": "Which healthcare facility provides non-emergency medical services on a walk-in basis?",
      "answer": "Urgent care center",
      "options": ["Emergency room", "Primary care clinic", "Urgent care center", "Specialty hospital"]
    },
    {
      "question": "What is the term for a healthcare facility that offers outpatient surgical procedures?",
      "answer": "Ambulatory surgery center",
      "options": ["General hospital", "Ambulatory surgery center", "Long-term care facility", "Hospice center"]
    }
  ]  
 },

 { 
  id: 74, 
  img: "business.png", 
  category: "Business", 
  name:"Technology Business",
  questionList:[
    {
      "question": "What type of business develops and sells technology products or services?",
      "answer": "Technology",
      "options": ["Retail", "Manufacturing", "Technology", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a technology business?",
      "answer": "Software company",
      "options": ["Factory", "Warehouse", "Software company", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a technology business?",
      "answer": "Innovate and improve technology",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Innovate and improve technology", "Distribute goods"]
    },
    {
      "question": "Which technology model involves delivering computing services over the internet?",
      "answer": "Cloud computing",
      "options": ["On-premises", "Cloud computing", "Mainframe computing", "Grid computing"]
    },
    {
      "question": "What is a common challenge faced by technology businesses?",
      "answer": "Rapid technological changes",
      "options": ["Stable demand for products", "High employee turnover", "Rapid technological changes", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a technology company?",
      "answer": "Innovation",
      "options": ["Product quality", "Employee salaries", "Innovation", "Online presence"]
    },
    {
      "question": "What type of software development model emphasizes continuous improvement?",
      "answer": "Agile",
      "options": ["Waterfall", "Agile", "Prototype", "Spiral"]
    },
    {
      "question": "What is the term for a software licensing model that allows users to use software for free?",
      "answer": "Open source",
      "options": ["Closed source", "Proprietary", "Open source", "Freeware"]
    },
    {
      "question": "Which technology strategy involves outsourcing IT functions to third-party providers?",
      "answer": "Managed services",
      "options": ["In-house IT", "Outsourcing", "Cloud computing", "Managed services"]
    },
    {
      "question": "What is the term for a network of interconnected devices and sensors?",
      "answer": "Internet of Things (IoT)",
      "options": ["Artificial intelligence (AI)", "Virtual reality (VR)", "Internet of Things (IoT)", "Blockchain"]
    },
    {
      "question": "Which technology business model offers access to software on a subscription basis?",
      "answer": "Software as a Service (SaaS)",
      "options": ["Platform as a Service (PaaS)", "Infrastructure as a Service (IaaS)", "Software as a Service (SaaS)", "On-premises software"]
    },
    {
      "question": "What is the term for a company that provides IT infrastructure and services to other businesses?",
      "answer": "Managed service provider (MSP)",
      "options": ["Cloud service provider (CSP)", "Internet service provider (ISP)", "Managed service provider (MSP)", "Web hosting provider"]
    }
  ]  
 },

 { 
  id: 75, 
  img: "business.png", 
  category: "Business", 
  name:"Real Estate Business",
  questionList:[
    {
      "question": "What type of business deals with the buying, selling, and renting of properties?",
      "answer": "Real estate",
      "options": ["Retail", "Manufacturing", "Real estate", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a real estate business?",
      "answer": "Real estate agency",
      "options": ["Factory", "Warehouse", "Real estate agency", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a real estate business?",
      "answer": "Facilitate property transactions",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Facilitate property transactions", "Distribute goods"]
    },
    {
      "question": "Which real estate model involves buying properties and renting them out to tenants?",
      "answer": "Rental property",
      "options": ["Commercial property", "Vacation rental", "Rental property", "Timeshare"]
    },
    {
      "question": "What is a common challenge faced by real estate businesses?",
      "answer": "Market fluctuations",
      "options": ["Stable demand for properties", "High employee turnover", "Market fluctuations", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a real estate agent?",
      "answer": "Networking skills",
      "options": ["Product knowledge", "Employee salaries", "Networking skills", "Online presence"]
    },
    {
      "question": "What type of property is used for business operations?",
      "answer": "Commercial",
      "options": ["Residential", "Industrial", "Commercial", "Vacation"]
    },
    {
      "question": "What is the term for the process of estimating a property's value?",
      "answer": "Appraisal",
      "options": ["Inspection", "Appraisal", "Survey", "Assessment"]
    },
    {
      "question": "Which real estate strategy involves buying properties at a low price and selling them for a profit?",
      "answer": "House flipping",
      "options": ["Buy and hold", "Rent-to-own", "House flipping", "Lease option"]
    },
    {
      "question": "What is the term for the legal document that transfers ownership of a property?",
      "answer": "Deed",
      "options": ["Mortgage", "Lease", "Title", "Deed"]
    },
    {
      "question": "Which real estate business model focuses on selling vacation homes and timeshares?",
      "answer": "Vacation rental",
      "options": ["Commercial property", "Rental property", "Vacation rental", "Cooperative"]
    },
    {
      "question": "What is the term for the process of dividing land into smaller parcels?",
      "answer": "Subdivision",
      "options": ["Zoning", "Entitlement", "Easement", "Subdivision"]
    }
  ]  
 },

 { 
  id: 76, 
  img: "business.png", 
  category: "Business", 
  name:"Consulting Business",
  questionList:[
    {
      "question": "What type of business provides expert advice and guidance to clients?",
      "answer": "Consulting",
      "options": ["Retail", "Manufacturing", "Consulting", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a consulting business?",
      "answer": "Management consulting firm",
      "options": ["Factory", "Warehouse", "Management consulting firm", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a consulting business?",
      "answer": "Help clients solve problems",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Help clients solve problems", "Distribute goods"]
    },
    {
      "question": "Which consulting model involves providing services remotely?",
      "answer": "Virtual consulting",
      "options": ["On-site consulting", "Virtual consulting", "Collaborative consulting", "Advisory consulting"]
    },
    {
      "question": "What is a common challenge faced by consulting businesses?",
      "answer": "Client acquisition",
      "options": ["Stable demand for services", "High employee turnover", "Client acquisition", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a consulting firm?",
      "answer": "Expertise",
      "options": ["Customer service", "Employee salaries", "Expertise", "Online presence"]
    },
    {
      "question": "What type of consulting focuses on improving organizational efficiency?",
      "answer": "Management consulting",
      "options": ["Financial consulting", "Marketing consulting", "Management consulting", "Technology consulting"]
    },
    {
      "question": "What is the term for a consulting engagement that addresses specific issues?",
      "answer": "Project",
      "options": ["Proposal", "Assessment", "Project", "Analysis"]
    },
    {
      "question": "Which consulting strategy involves providing ongoing support and advice?",
      "answer": "Retainer",
      "options": ["Hourly rate", "Fixed fee", "Performance-based", "Retainer"]
    },
    {
      "question": "What is the term for a consulting firm that specializes in a particular industry?",
      "answer": "Industry-specific",
      "options": ["Generalist", "Specialist", "Niche", "Industry-specific"]
    },
    {
      "question": "Which consulting approach focuses on delivering actionable insights and recommendations?",
      "answer": "Pragmatic",
      "options": ["Theoretical", "Pragmatic", "Collaborative", "Innovative"]
    },
    {
      "question": "What is the term for a consulting firm that offers services across multiple disciplines?",
      "answer": "Full-service",
      "options": ["Boutique", "Full-service", "Independent", "Freelance"]
    }
  ]  
 },

 { 
  id: 77, 
  img: "business.png", 
  category: "Business", 
  name:"Fashion Business",
  questionList:[
    {
      "question": "What type of business designs, manufactures, and sells clothing and accessories?",
      "answer": "Fashion",
      "options": ["Retail", "Manufacturing", "Fashion", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a fashion business?",
      "answer": "Fashion boutique",
      "options": ["Factory", "Warehouse", "Fashion boutique", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a fashion business?",
      "answer": "Create and sell fashionable products",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Create and sell fashionable products", "Distribute goods"]
    },
    {
      "question": "Which fashion model involves designing and producing custom-made clothing?",
      "answer": "Bespoke",
      "options": ["Ready-to-wear", "Haute couture", "Fast fashion", "Bespoke"]
    },
    {
      "question": "What is a common challenge faced by fashion businesses?",
      "answer": "Changing consumer preferences",
      "options": ["Stable demand for products", "High employee turnover", "Changing consumer preferences", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a fashion brand?",
      "answer": "Brand image",
      "options": ["Product quality", "Employee salaries", "Brand image", "Online presence"]
    },
    {
      "question": "What type of fashion focuses on creating unique, one-of-a-kind pieces?",
      "answer": "Haute couture",
      "options": ["Ready-to-wear", "Fast fashion", "Haute couture", "Streetwear"]
    },
    {
      "question": "What is the term for a fashion trend that gains widespread popularity?",
      "answer": "Fashion craze",
      "options": ["Fad", "Trend", "Fashion craze", "Classic"]
    },
    {
      "question": "Which fashion strategy involves producing limited quantities of exclusive products?",
      "answer": "Limited edition",
      "options": ["Mass production", "Limited edition", "Fast fashion", "Seasonal collection"]
    },
    {
      "question": "What is the term for a fashion show featuring the latest designs by a designer or brand?",
      "answer": "Runway show",
      "options": ["Fashion show", "Runway show", "Trunk show", "Sample sale"]
    },
    {
      "question": "Which fashion style is characterized by its casual and comfortable clothing?",
      "answer": "Streetwear",
      "options": ["Formalwear", "Business casual", "Athleisure", "Streetwear"]
    },
    {
      "question": "What is the term for a fashion retailer that offers discounted designer goods?",
      "answer": "Off-price",
      "options": ["Department store", "Boutique", "Off-price", "Outlet"]
    }
  ]  
 },

 { 
  id: 78, 
  img: "business.png", 
  category: "Business", 
  name:"Hospitality Business",
  questionList:[
    {
      "question": "What type of business provides lodging, dining, and entertainment services to guests?",
      "answer": "Hospitality",
      "options": ["Retail", "Manufacturing", "Hospitality", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of a hospitality business?",
      "answer": "Hotel",
      "options": ["Factory", "Warehouse", "Hotel", "Distribution center"]
    },
    {
      "question": "What is the primary goal of a hospitality business?",
      "answer": "Provide a positive guest experience",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Provide a positive guest experience", "Distribute goods"]
    },
    {
      "question": "Which hospitality model involves offering short-term accommodations in private homes?",
      "answer": "Vacation rental",
      "options": ["Hotel", "Bed and breakfast", "Vacation rental", "Hostel"]
    },
    {
      "question": "What is a common challenge faced by hospitality businesses?",
      "answer": "Seasonal demand fluctuations",
      "options": ["Stable demand for services", "High employee turnover", "Seasonal demand fluctuations", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of a hospitality business?",
      "answer": "Customer service",
      "options": ["Product quality", "Employee salaries", "Customer service", "Location"]
    },
    {
      "question": "What type of accommodation provides breakfast as part of the room rate?",
      "answer": "Bed and breakfast",
      "options": ["Hotel", "Motel", "Hostel", "Bed and breakfast"]
    },
    {
      "question": "What is the term for a fee charged for canceling a hotel reservation?",
      "answer": "Cancellation fee",
      "options": ["Reservation fee", "Booking fee", "Cancellation fee", "Security deposit"]
    },
    {
      "question": "Which hospitality strategy involves offering special discounts for advance bookings?",
      "answer": "Early booking discount",
      "options": ["Last-minute deals", "Early booking discount", "Extended stay promotion", "Loyalty program"]
    },
    {
      "question": "What is the term for a hotel room with two separate sleeping areas?",
      "answer": "Suite",
      "options": ["Single", "Double", "Suite", "Penthouse"]
    },
    {
      "question": "Which hospitality service allows guests to store their luggage before check-in or after check-out?",
      "answer": "Luggage storage",
      "options": ["Concierge", "Room service", "Luggage storage", "Valet parking"]
    },
    {
      "question": "What is the term for a hotel room with a balcony or patio?",
      "answer": "Room with a view",
      "options": ["Suite", "Room with a view", "Executive room", "Standard room"]
    }
  ]  
 },
 { 
  id: 79, 
  img: "business.png", 
  category: "Business", 
  name:"Education Business",
  questionList:[
    {
      "question": "What type of business provides instruction, training, and learning resources?",
      "answer": "Education",
      "options": ["Retail", "Manufacturing", "Education", "Wholesale"]
    },
    {
      "question": "Which of the following is an example of an education business?",
      "answer": "Tutoring center",
      "options": ["Factory", "Warehouse", "Tutoring center", "Distribution center"]
    },
    {
      "question": "What is the primary goal of an education business?",
      "answer": "Facilitate learning",
      "options": ["Sell products to other businesses", "Provide services to consumers", "Facilitate learning", "Distribute goods"]
    },
    {
      "question": "Which education model involves personalized instruction outside of traditional classrooms?",
      "answer": "Tutoring",
      "options": ["Distance learning", "Tutoring", "Online courses", "Group instruction"]
    },
    {
      "question": "What is a common challenge faced by education businesses?",
      "answer": "Adapting to new technologies",
      "options": ["Stable demand for services", "High employee turnover", "Adapting to new technologies", "Low competition"]
    },
    {
      "question": "Which factor often influences the success of an education business?",
      "answer": "Quality of instruction",
      "options": ["Facilities", "Employee salaries", "Quality of instruction", "Location"]
    },
    {
      "question": "What type of education focuses on teaching practical skills for specific careers?",
      "answer": "Vocational",
      "options": ["Primary", "Secondary", "Tertiary", "Vocational"]
    },
    {
      "question": "What is the term for a formal assessment of a student's knowledge and skills?",
      "answer": "Examination",
      "options": ["Assignment", "Test", "Quiz", "Examination"]
    },
    {
      "question": "Which education strategy involves offering courses through the internet?",
      "answer": "Online learning",
      "options": ["Distance education", "Blended learning", "Online learning", "E-learning"]
    },
    {
      "question": "What is the term for a specialized school focused on a particular subject or skill?",
      "answer": "Academy",
      "options": ["College", "University", "Academy", "Institute"]
    },
    {
      "question": "Which education model allows students to learn at their own pace?",
      "answer": "Self-directed learning",
      "options": ["Traditional classroom", "Self-directed learning", "Cooperative learning", "Experiential learning"]
    },
    {
      "question": "What is the term for a learning approach that combines online and in-person instruction?",
      "answer": "Blended learning",
      "options": ["Hybrid learning", "Synchronous learning", "Blended learning", "Asynchronous learning"]
    }
  ]  
 },

 //Litrature
 { 
  id: 80, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Poetry",
  questionList:[
    {
      "question": "Who wrote 'The Waste Land'?",
      "answer": "T.S. Eliot",
      "options": ["William Wordsworth", "T.S. Eliot", "Emily Dickinson", "Robert Frost"]
    },
    {
      "question": "Which poet wrote 'Ode to a Nightingale'?",
      "answer": "John Keats",
      "options": ["John Donne", "William Blake", "John Keats", "Lord Byron"]
    },
    {
      "question": "Who is the author of 'Howl'?",
      "answer": "Allen Ginsberg",
      "options": ["Walt Whitman", "Sylvia Plath", "Allen Ginsberg", "Langston Hughes"]
    },
    {
      "question": "Who wrote 'The Raven'?",
      "answer": "Edgar Allan Poe",
      "options": ["Emily Dickinson", "Edgar Allan Poe", "Robert Frost", "Sylvia Plath"]
    },
    {
      "question": "Which poet wrote 'Leaves of Grass'?",
      "answer": "Walt Whitman",
      "options": ["Walt Whitman", "Emily Dickinson", "Langston Hughes", "Maya Angelou"]
    },
    {
      "question": "Who is known for the poetry collection 'Songs of Innocence and Experience'?",
      "answer": "William Blake",
      "options": ["William Wordsworth", "William Shakespeare", "William Blake", "Robert Frost"]
    },
    {
      "question": "Which poet is famous for writing 'The Road Not Taken'?",
      "answer": "Robert Frost",
      "options": ["T.S. Eliot", "Emily Dickinson", "Robert Frost", "W.B. Yeats"]
    },
    {
      "question": "Who wrote the poem 'Do Not Go Gentle into That Good Night'?",
      "answer": "Dylan Thomas",
      "options": ["Pablo Neruda", "Dylan Thomas", "Seamus Heaney", "E.E. Cummings"]
    },
    {
      "question": "Which poet is associated with the collection 'The Love Song of J. Alfred Prufrock'?",
      "answer": "T.S. Eliot",
      "options": ["Sylvia Plath", "T.S. Eliot", "Langston Hughes", "W.B. Yeats"]
    },
    {
      "question": "Who wrote the poem 'To Autumn'?",
      "answer": "John Keats",
      "options": ["Percy Bysshe Shelley", "John Keats", "Lord Byron", "Samuel Taylor Coleridge"]
    },
    {
      "question": "Which poet is known for 'I Hear America Singing'?",
      "answer": "Walt Whitman",
      "options": ["Walt Whitman", "Emily Dickinson", "Langston Hughes", "Maya Angelou"]
    },
    {
      "question": "Who authored 'Paradise Lost'?",
      "answer": "John Milton",
      "options": ["John Donne", "John Milton", "William Wordsworth", "William Blake"]
    }
  ]  
 },
 { 
  id: 81, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Drama",
  questionList:[
    {
      "question": "Who wrote the tragedy 'Hamlet'?",
      "answer": "William Shakespeare",
      "options": ["Christopher Marlowe", "Ben Jonson", "William Shakespeare", "John Webster"]
    },
    {
      "question": "Which playwright is known for 'Death of a Salesman'?",
      "answer": "Arthur Miller",
      "options": ["Eugene O'Neill", "Arthur Miller", "Tennessee Williams", "Sam Shepard"]
    },
    {
      "question": "Who wrote the play 'A Streetcar Named Desire'?",
      "answer": "Tennessee Williams",
      "options": ["Arthur Miller", "Tennessee Williams", "Eugene O'Neill", "Sam Shepard"]
    },
    {
      "question": "Which playwright wrote 'The Importance of Being Earnest'?",
      "answer": "Oscar Wilde",
      "options": ["George Bernard Shaw", "Oscar Wilde", "Samuel Beckett", "Tom Stoppard"]
    },
    {
      "question": "Who is the author of 'Romeo and Juliet'?",
      "answer": "William Shakespeare",
      "options": ["Christopher Marlowe", "Ben Jonson", "William Shakespeare", "John Webster"]
    },
    {
      "question": "Which playwright wrote 'Long Day's Journey into Night'?",
      "answer": "Eugene O'Neill",
      "options": ["Arthur Miller", "Tennessee Williams", "Eugene O'Neill", "Sam Shepard"]
    },
    {
      "question": "Who authored the tragedy 'Macbeth'?",
      "answer": "William Shakespeare",
      "options": ["Christopher Marlowe", "Ben Jonson", "William Shakespeare", "John Webster"]
    },
    {
      "question": "Which playwright is known for 'Waiting for Godot'?",
      "answer": "Samuel Beckett",
      "options": ["George Bernard Shaw", "Oscar Wilde", "Samuel Beckett", "Tom Stoppard"]
    },
    {
      "question": "Who wrote the play 'Cat on a Hot Tin Roof'?",
      "answer": "Tennessee Williams",
      "options": ["Arthur Miller", "Tennessee Williams", "Eugene O'Neill", "Sam Shepard"]
    },
    {
      "question": "Which playwright is associated with 'Pygmalion'?",
      "answer": "George Bernard Shaw",
      "options": ["George Bernard Shaw", "Oscar Wilde", "Samuel Beckett", "Tom Stoppard"]
    },
    {
      "question": "Who authored the tragedy 'Oedipus Rex'?",
      "answer": "Sophocles",
      "options": ["Euripides", "Aeschylus", "Sophocles", "Virgil"]
    },
    {
      "question": "Which playwright is known for 'The Glass Menagerie'?",
      "answer": "Tennessee Williams",
      "options": ["Arthur Miller", "Tennessee Williams", "Eugene O'Neill", "Sam Shepard"]
    }
  ]  
 },
 { 
  id: 82, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Fiction",
  questionList:[
    {
      "question": "Who wrote 'Pride and Prejudice'?",
      "answer": "Jane Austen",
      "options": ["Emily Brontë", "Charlotte Brontë", "Jane Austen", "Virginia Woolf"]
    },
    {
      "question": "Which author penned 'To Kill a Mockingbird'?",
      "answer": "Harper Lee",
      "options": ["Mark Twain", "John Steinbeck", "Harper Lee", "Ray Bradbury"]
    },
    {
      "question": "Who is the author of '1984'?",
      "answer": "George Orwell",
      "options": ["Aldous Huxley", "George Orwell", "Ray Bradbury", "Kurt Vonnegut"]
    },
    {
      "question": "Which author wrote 'The Catcher in the Rye'?",
      "answer": "J.D. Salinger",
      "options": ["F. Scott Fitzgerald", "Ernest Hemingway", "J.D. Salinger", "William Faulkner"]
    },
    {
      "question": "Who is known for 'The Great Gatsby'?",
      "answer": "F. Scott Fitzgerald",
      "options": ["Ernest Hemingway", "F. Scott Fitzgerald", "J.D. Salinger", "William Faulkner"]
    },
    {
      "question": "Which author penned 'Brave New World'?",
      "answer": "Aldous Huxley",
      "options": ["George Orwell", "Aldous Huxley", "Ray Bradbury", "Kurt Vonnegut"]
    },
    {
      "question": "Who wrote 'Moby-Dick'?",
      "answer": "Herman Melville",
      "options": ["Nathaniel Hawthorne", "Herman Melville", "Edgar Allan Poe", "Henry David Thoreau"]
    },
    {
      "question": "Which author is known for 'The Lord of the Rings' trilogy?",
      "answer": "J.R.R. Tolkien",
      "options": ["C.S. Lewis", "J.R.R. Tolkien", "George R.R. Martin", "Philip Pullman"]
    },
    {
      "question": "Who is the author of 'The Hobbit'?",
      "answer": "J.R.R. Tolkien",
      "options": ["C.S. Lewis", "J.R.R. Tolkien", "George R.R. Martin", "Philip Pullman"]
    },
    {
      "question": "Which author wrote 'The Odyssey'?",
      "answer": "Homer",
      "options": ["Virgil", "Homer", "Herodotus", "Thucydides"]
    },
    {
      "question": "Who is known for the book 'Wuthering Heights'?",
      "answer": "Emily Brontë",
      "options": ["Charlotte Brontë", "Emily Brontë", "Jane Austen", "Virginia Woolf"]
    },
    {
      "question": "Which author penned 'The Bell Jar'?",
      "answer": "Sylvia Plath",
      "options": ["Virginia Woolf", "Sylvia Plath", "Charlotte Brontë", "Emily Brontë"]
    }
  ]  
 },
 { 
  id: 83, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Non-Fiction",
  questionList:[
    {
      "question": "Who wrote 'The Diary of a Young Girl'?",
      "answer": "Anne Frank",
      "options": ["Maya Angelou", "Anne Frank", "Malala Yousafzai", "Elie Wiesel"]
    },
    {
      "question": "Which author is known for 'A Brief History of Time'?",
      "answer": "Stephen Hawking",
      "options": ["Richard Dawkins", "Carl Sagan", "Stephen Hawking", "Neil deGrasse Tyson"]
    },
    {
      "question": "Who wrote 'The Audacity of Hope'?",
      "answer": "Barack Obama",
      "options": ["Hillary Clinton", "Michelle Obama", "Barack Obama", "Joe Biden"]
    },
    {
      "question": "Which author is known for 'Silent Spring'?",
      "answer": "Rachel Carson",
      "options": ["Greta Thunberg", "Rachel Carson", "Naomi Klein", "Vandana Shiva"]
    },
    {
      "question": "Who authored 'The Art of War'?",
      "answer": "Sun Tzu",
      "options": ["Machiavelli", "Sun Tzu", "Clausewitz", "Mao Zedong"]
    },
    {
      "question": "Which author wrote 'In Cold Blood'?",
      "answer": "Truman Capote",
      "options": ["Norman Mailer", "Truman Capote", "Hunter S. Thompson", "Joan Didion"]
    },
    {
      "question": "Who is known for 'The Feminine Mystique'?",
      "answer": "Betty Friedan",
      "options": ["Gloria Steinem", "Betty Friedan", "bell hooks", "Simone de Beauvoir"]
    },
    {
      "question": "Which author penned 'The Souls of Black Folk'?",
      "answer": "W.E.B. Du Bois",
      "options": ["Frederick Douglass", "Booker T. Washington", "W.E.B. Du Bois", "Ida B. Wells"]
    },
    {
      "question": "Who wrote 'The Autobiography of Malcolm X'?",
      "answer": "Malcolm X",
      "options": ["James Baldwin", "Malcolm X", "Toni Morrison", "Maya Angelou"]
    },
    {
      "question": "Which author is known for 'The Road to Serfdom'?",
      "answer": "Friedrich Hayek",
      "options": ["John Maynard Keynes", "Friedrich Hayek", "Karl Marx", "Milton Friedman"]
    },
    {
      "question": "Who authored 'The Origins of Species'?",
      "answer": "Charles Darwin",
      "options": ["Gregor Mendel", "Charles Darwin", "Alfred Russel Wallace", "Thomas Huxley"]
    },
    {
      "question": "Which author is known for 'On Liberty'?",
      "answer": "John Stuart Mill",
      "options": ["Adam Smith", "Karl Marx", "John Stuart Mill", "Friedrich Engels"]
    }
  ]  
 },
 { 
  id: 84, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Biography",
  questionList:[
    {
      "question": "Who wrote 'Steve Jobs'?",
      "answer": "Walter Isaacson",
      "options": ["Malcolm Gladwell", "Walter Isaacson", "Mark Zuckerberg", "Elon Musk"]
    },
    {
      "question": "Which author penned 'The Diary of Anne Frank'?",
      "answer": "Anne Frank",
      "options": ["Anne Frank", "Maya Angelou", "Malala Yousafzai", "Elie Wiesel"]
    },
    {
      "question": "Who is known for 'The Autobiography of Benjamin Franklin'?",
      "answer": "Benjamin Franklin",
      "options": ["George Washington", "Benjamin Franklin", "Thomas Jefferson", "John Adams"]
    },
    {
      "question": "Which author wrote 'Long Walk to Freedom'?",
      "answer": "Nelson Mandela",
      "options": ["Desmond Tutu", "Nelson Mandela", "Winnie Mandela", "Albertina Sisulu"]
    },
    {
      "question": "Who authored 'Bossypants'?",
      "answer": "Tina Fey",
      "options": ["Amy Poehler", "Tina Fey", "Mindy Kaling", "Ellen DeGeneres"]
    },
    {
      "question": "Which author is known for 'The Story of My Experiments with Truth'?",
      "answer": "Mahatma Gandhi",
      "options": ["Jawaharlal Nehru", "Mahatma Gandhi", "Subhas Chandra Bose", "B.R. Ambedkar"]
    },
    {
      "question": "Who wrote 'The Wright Brothers'?",
      "answer": "David McCullough",
      "options": ["Doris Kearns Goodwin", "David McCullough", "Jon Meacham", "Ron Chernow"]
    },
    {
      "question": "Which author penned 'I Am Malala'?",
      "answer": "Malala Yousafzai",
      "options": ["Anne Frank", "Maya Angelou", "Malala Yousafzai", "Elie Wiesel"]
    },
    {
      "question": "Who is known for 'Mein Kampf'?",
      "answer": "Adolf Hitler",
      "options": ["Joseph Stalin", "Benito Mussolini", "Adolf Hitler", "Winston Churchill"]
    },
    {
      "question": "Which author wrote 'Marie Antoinette: The Journey'?",
      "answer": "Antonia Fraser",
      "options": ["Hilary Mantel", "Antonia Fraser", "Alison Weir", "Simon Schama"]
    },
    {
      "question": "Who authored 'The Autobiography of Malcolm X'?",
      "answer": "Malcolm X",
      "options": ["James Baldwin", "Malcolm X", "Toni Morrison", "Maya Angelou"]
    },
    {
      "question": "Which author is known for 'Alexander Hamilton'?",
      "answer": "Ron Chernow",
      "options": ["Doris Kearns Goodwin", "David McCullough", "Jon Meacham", "Ron Chernow"]
    }
  ]  
 },
 { 
  id: 85, 
  img: "litrature.png", 
  category: "Litrature", 
  name:"Historical Fiction",
  questionList:[
    {
      "question": "Who wrote 'All the Light We Cannot See'?",
      "answer": "Anthony Doerr",
      "options": ["Anthony Doerr", "Donna Tartt", "John Boyne", "Amor Towles"]
    },
    {
      "question": "Which author penned 'The Book Thief'?",
      "answer": "Markus Zusak",
      "options": ["Khaled Hosseini", "Markus Zusak", "Jhumpa Lahiri", "Chimamanda Ngozi Adichie"]
    },
    {
      "question": "Who is known for 'The Nightingale'?",
      "answer": "Kristin Hannah",
      "options": ["Kristin Hannah", "Paula McLain", "Lisa See", "Kate Quinn"]
    },
    {
      "question": "Which author wrote 'The Tattooist of Auschwitz'?",
      "answer": "Heather Morris",
      "options": ["Heather Morris", "Ruta Sepetys", "Tatiana de Rosnay", "Anthony Doerr"]
    },
    {
      "question": "Who authored 'The Alice Network'?",
      "answer": "Kate Quinn",
      "options": ["Pam Jenoff", "Kate Quinn", "Kristin Hannah", "Donna Tartt"]
    },
    {
      "question": "Which author is known for 'The Orphan's Tale'?",
      "answer": "Pam Jenoff",
      "options": ["Pam Jenoff", "Kate Quinn", "Kristin Hannah", "Donna Tartt"]
    },
    {
      "question": "Who wrote 'The Guernsey Literary and Potato Peel Pie Society'?",
      "answer": "Mary Ann Shaffer and Annie Barrows",
      "options": ["Jojo Moyes", "Mary Ann Shaffer and Annie Barrows", "Kate Morton", "Victoria Hislop"]
    },
    {
      "question": "Which author penned 'The Light Between Oceans'?",
      "answer": "M.L. Stedman",
      "options": ["Paula McLain", "Kristin Hannah", "M.L. Stedman", "Lisa See"]
    },
    {
      "question": "Who is known for 'The Dutch House'?",
      "answer": "Ann Patchett",
      "options": ["Ann Patchett", "Elizabeth Strout", "Colson Whitehead", "Donna Tartt"]
    },
    {
      "question": "Which author wrote 'The Night Watchman'?",
      "answer": "Louise Erdrich",
      "options": ["Louise Erdrich", "Sigrid Nunez", "Elizabeth Strout", "Roxane Gay"]
    },
    {
      "question": "Who authored 'The Song of Achilles'?",
      "answer": "Madeline Miller",
      "options": ["Madeline Miller", "David Mitchell", "Esi Edugyan", "Toni Morrison"]
    },
    {
      "question": "Which author is known for 'The Underground Railroad'?",
      "answer": "Colson Whitehead",
      "options": ["Yaa Gyasi", "Colson Whitehead", "Ta-Nehisi Coates", "Angie Thomas"]
    }
  ]
 },

 //Fun Science
 { 
  id: 86, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Space Exploration",
  questionList:[
    {
      "question": "Which planet is known as the 'Red Planet'?",
      "answer": "Mars",
      "options": ["Venus", "Mars", "Jupiter", "Mercury"]
    },
    {
      "question": "What is the name of the largest moon of Saturn?",
      "answer": "Titan",
      "options": ["Europa", "Ganymede", "Titan", "Callisto"]
    },
    {
      "question": "Which space agency launched the Hubble Space Telescope?",
      "answer": "NASA",
      "options": ["NASA", "ESA", "Roscosmos", "ISRO"]
    },
    {
      "question": "What is the name of the first artificial satellite launched into space?",
      "answer": "Sputnik 1",
      "options": ["Sputnik 1", "Explorer 1", "Vostok 1", "Luna 2"]
    },
    {
      "question": "Who was the first human to travel into space?",
      "answer": "Yuri Gagarin",
      "options": ["Neil Armstrong", "Buzz Aldrin", "Yuri Gagarin", "John Glenn"]
    },
    {
      "question": "What is the name of the galaxy that contains our solar system?",
      "answer": "Milky Way",
      "options": ["Andromeda", "Milky Way", "Triangulum", "Messier 87"]
    },
    {
      "question": "Which spacecraft was the first to land humans on the Moon?",
      "answer": "Apollo 11",
      "options": ["Apollo 11", "Apollo 13", "Gemini 4", "Mercury 7"]
    },
    {
      "question": "What is the name of the phenomenon where a black hole's gravitational pull is so strong that even light cannot escape from it?",
      "answer": "Event Horizon",
      "options": ["Singularity", "Event Horizon", "Event Boundary", "Blackout Point"]
    },
    {
      "question": "Which planet is known for its beautiful rings?",
      "answer": "Saturn",
      "options": ["Jupiter", "Saturn", "Uranus", "Neptune"]
    },
    {
      "question": "What is the name of the first American woman in space?",
      "answer": "Sally Ride",
      "options": ["Sally Ride", "Valentina Tereshkova", "Mae Jemison", "Judith Resnik"]
    },
    {
      "question": "Which spacecraft carried the first humans to orbit the Moon?",
      "answer": "Apollo 8",
      "options": ["Apollo 8", "Apollo 11", "Apollo 13", "Apollo 14"]
    },
    {
      "question": "What is the name of the rover currently exploring the surface of Mars?",
      "answer": "Perseverance",
      "options": ["Opportunity", "Curiosity", "Perseverance", "Spirit"]
    }
  ] 
 },
 { 
  id: 87, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Amazing Animals",
  questionList:[
    {
      "question": "What is the fastest land animal?",
      "answer": "Cheetah",
      "options": ["Lion", "Cheetah", "Gazelle", "Pronghorn"]
    },
    {
      "question": "Which bird is known for its ability to mimic human speech?",
      "answer": "Parrot",
      "options": ["Crow", "Parrot", "Sparrow", "Eagle"]
    },
    {
      "question": "What is the largest mammal in the world?",
      "answer": "Blue Whale",
      "options": ["Elephant", "Blue Whale", "Giraffe", "Hippopotamus"]
    },
    {
      "question": "Which animal is known as the 'King of the Jungle'?",
      "answer": "Lion",
      "options": ["Tiger", "Lion", "Leopard", "Cheetah"]
    },
    {
      "question": "What is the only mammal capable of sustained flight?",
      "answer": "Bat",
      "options": ["Bat", "Bird", "Butterfly", "Pterosaur"]
    },
    {
      "question": "Which reptile is known for changing its color to match its surroundings?",
      "answer": "Chameleon",
      "options": ["Crocodile", "Chameleon", "Snake", "Lizard"]
    },
    {
      "question": "What is the largest bird in the world?",
      "answer": "Ostrich",
      "options": ["Emu", "Ostrich", "Condor", "Albatross"]
    },
    {
      "question": "Which mammal is capable of echolocation?",
      "answer": "Bat",
      "options": ["Dolphin", "Bat", "Whale", "Elephant"]
    },
    {
      "question": "What is the only continent where giraffes live in the wild?",
      "answer": "Africa",
      "options": ["Africa", "Asia", "Australia", "South America"]
    },
    {
      "question": "Which insect is known for its ability to carry objects many times heavier than itself?",
      "answer": "Ant",
      "options": ["Bee", "Ant", "Butterfly", "Grasshopper"]
    },
    {
      "question": "What is the world's largest lizard?",
      "answer": "Komodo Dragon",
      "options": ["Iguana", "Gecko", "Monitor Lizard", "Komodo Dragon"]
    },
    {
      "question": "Which animal has the longest lifespan?",
      "answer": "Bowhead Whale",
      "options": ["Galapagos Tortoise", "Bowhead Whale", "Elephant", "Human"]
    }
  ]  
 },
 { 
  id: 88, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Incredible Inventions",
  questionList:[
    {
      "question": "Who is credited with inventing the telephone?",
      "answer": "Alexander Graham Bell",
      "options": ["Thomas Edison", "Alexander Graham Bell", "Nikola Tesla", "Albert Einstein"]
    },
    {
      "question": "What was the first artificial satellite launched into space?",
      "answer": "Sputnik 1",
      "options": ["Vostok 1", "Sputnik 1", "Explorer 1", "Luna 2"]
    },
    {
      "question": "Who is known for discovering penicillin?",
      "answer": "Alexander Fleming",
      "options": ["Louis Pasteur", "Alexander Fleming", "Marie Curie", "Jonas Salk"]
    },
    {
      "question": "What is the name of the device that measures earthquakes?",
      "answer": "Seismograph",
      "options": ["Barometer", "Thermometer", "Seismograph", "Hygrometer"]
    },
    {
      "question": "Who invented the light bulb?",
      "answer": "Thomas Edison",
      "options": ["Nikola Tesla", "Thomas Edison", "Alexander Graham Bell", "Albert Einstein"]
    },
    {
      "question": "What is the name of the first computer virus?",
      "answer": "Creeper",
      "options": ["ILOVEYOU", "Creeper", "Mydoom", "Melissa"]
    },
    {
      "question": "Who invented the World Wide Web (WWW)?",
      "answer": "Tim Berners-Lee",
      "options": ["Bill Gates", "Steve Jobs", "Tim Berners-Lee", "Mark Zuckerberg"]
    },
    {
      "question": "What is the name of the first successful powered airplane?",
      "answer": "Wright Flyer",
      "options": ["Wright Flyer", "Spirit of St. Louis", "Kitty Hawk", "Red Baron"]
    },
    {
      "question": "Who invented the printing press?",
      "answer": "Johannes Gutenberg",
      "options": ["Leonardo da Vinci", "Johannes Gutenberg", "Galileo Galilei", "Isaac Newton"]
    },
    {
      "question": "What was the first spacecraft to reach the moon's surface?",
      "answer": "Luna 2",
      "options": ["Apollo 11", "Sputnik 1", "Vostok 1", "Luna 2"]
    },
    {
      "question": "Who is known for discovering the theory of relativity?",
      "answer": "Albert Einstein",
      "options": ["Isaac Newton", "Galileo Galilei", "Albert Einstein", "Stephen Hawking"]
    },
    {
      "question": "What is the name of the first satellite to orbit Earth?",
      "answer": "Sputnik 1",
      "options": ["Vostok 1", "Sputnik 1", "Explorer 1", "Luna 2"]
    }
  ]  
 },
 { 
  id: 89, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Earth Science Wonders",
  questionList:[
    {
      "question": "What is the name of the deepest known part of the Earth's oceans?",
      "answer": "Mariana Trench",
      "options": ["Mariana Trench", "Puerto Rico Trench", "Java Trench", "Tonga Trench"]
    },
    {
      "question": "What is the name of the layer of the Earth's atmosphere where weather occurs?",
      "answer": "Troposphere",
      "options": ["Stratosphere", "Troposphere", "Mesosphere", "Thermosphere"]
    },
    {
      "question": "What is the name of the process by which water evaporates from plants?",
      "answer": "Transpiration",
      "options": ["Condensation", "Evaporation", "Precipitation", "Transpiration"]
    },
    {
      "question": "What is the name of the longest mountain range on Earth?",
      "answer": "Andes",
      "options": ["Rocky Mountains", "Himalayas", "Andes", "Appalachian Mountains"]
    },
    {
      "question": "What is the name of the largest desert on Earth?",
      "answer": "Sahara",
      "options": ["Sahara", "Gobi", "Antarctic", "Arabian"]
    },
    {
      "question": "What is the name of the layer of the Earth's atmosphere that contains the ozone layer?",
      "answer": "Stratosphere",
      "options": ["Troposphere", "Stratosphere", "Mesosphere", "Thermosphere"]
    },
    {
      "question": "What is the name of the longest river in the world?",
      "answer": "Nile",
      "options": ["Amazon", "Nile", "Yangtze", "Mississippi"]
    },
    {
      "question": "What is the name of the world's largest ocean?",
      "answer": "Pacific",
      "options": ["Atlantic", "Pacific", "Indian", "Arctic"]
    },
    {
      "question": "What is the name of the layer of the Earth's atmosphere where meteors burn up?",
      "answer": "Mesosphere",
      "options": ["Troposphere", "Stratosphere", "Mesosphere", "Thermosphere"]
    },
    {
      "question": "What is the name of the force that causes objects to fall toward the center of the Earth?",
      "answer": "Gravity",
      "options": ["Magnetism", "Friction", "Gravity", "Inertia"]
    },
    {
      "question": "What is the name of the largest oceanic trench?",
      "answer": "Mariana Trench",
      "options": ["Mariana Trench", "Puerto Rico Trench", "Java Trench", "Tonga Trench"]
    },
    {
      "question": "What is the name of the layer of the Earth's atmosphere that contains the Northern Lights?",
      "answer": "Thermosphere",
      "options": ["Troposphere", "Stratosphere", "Mesosphere", "Thermosphere"]
    }
  ]  
 },
 { 
  id: 90, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Fascinating Physics",
  questionList:[
    {
      "question": "What is the SI unit of force?",
      "answer": "Newton",
      "options": ["Watt", "Joule", "Newton", "Ohm"]
    },
    {
      "question": "What is the speed of light in a vacuum?",
      "answer": "299,792,458 meters per second",
      "options": ["299,792,458 meters per second", "300,000,000 meters per second", "299,792,458 kilometers per second", "299,792 kilometers per second"]
    },
    {
      "question": "What is the acceleration due to gravity on Earth?",
      "answer": "9.8 meters per second squared",
      "options": ["9.8 meters per second squared", "10 meters per second squared", "9.81 meters per second squared", "10.2 meters per second squared"]
    },
    {
      "question": "What is the first law of motion?",
      "answer": "An object in motion stays in motion unless acted upon by an external force",
      "options": ["An object in motion stays in motion unless acted upon by an external force", "Force equals mass times acceleration", "For every action, there is an equal and opposite reaction", "Entropy always increases"]
    },
    {
      "question": "What is the fundamental force responsible for holding atoms together?",
      "answer": "Strong Nuclear Force",
      "options": ["Electromagnetic Force", "Gravitational Force", "Weak Nuclear Force", "Strong Nuclear Force"]
    },
    {
      "question": "What is the unit of electrical resistance?",
      "answer": "Ohm",
      "options": ["Volt", "Ampere", "Watt", "Ohm"]
    },
    {
      "question": "What is the equation for Einstein's theory of relativity?",
      "answer": "E=mc^2",
      "options": ["E=mc^2", "F=ma", "E=hf", "PV=nRT"]
    },
    {
      "question": "What is the unit of electric charge?",
      "answer": "Coulomb",
      "options": ["Watt", "Coulomb", "Joule", "Ohm"]
    },
    {
      "question": "What is the second law of thermodynamics?",
      "answer": "Entropy of the universe tends to a maximum",
      "options": ["Energy is conserved", "Entropy of the universe tends to a maximum", "Entropy always decreases", "Heat flows from hot to cold"]
    },
    {
      "question": "What is the force exerted by gravity on an object?",
      "answer": "Weight",
      "options": ["Mass", "Weight", "Density", "Volume"]
    },
    {
      "question": "What is the fundamental unit of charge?",
      "answer": "Coulomb",
      "options": ["Watt", "Ampere", "Coulomb", "Ohm"]
    },
    {
      "question": "What is the law of conservation of energy?",
      "answer": "Energy cannot be created or destroyed, only transformed",
      "options": ["Energy cannot be created or destroyed, only transformed", "Total energy of an isolated system remains constant", "Energy flows from high to low concentration", "Entropy always decreases"]
    }
  ]  
 },
 { 
  id: 91, 
  img: "funscience.png", 
  category: "Fun Science", 
  name:"Chemistry Fun",
  questionList:[
    {
      "question": "What is the chemical symbol for gold?",
      "answer": "Au",
      "options": ["Ag", "Au", "Al", "Fe"]
    },
    {
      "question": "What is the pH of pure water?",
      "answer": "7",
      "options": ["5", "7", "10", "14"]
    },
    {
      "question": "What is the most abundant element in the Earth's atmosphere?",
      "answer": "Nitrogen",
      "options": ["Oxygen", "Carbon", "Nitrogen", "Hydrogen"]
    },
    {
      "question": "What is the chemical symbol for table salt?",
      "answer": "NaCl",
      "options": ["H2O", "NaCl", "CaCO3", "C6H12O6"]
    },
    {
      "question": "What is the chemical symbol for water?",
      "answer": "H2O",
      "options": ["H2O", "CO2", "O2", "H2"]
    },
    {
      "question": "What is the process by which a solid turns into a gas without going through the liquid state?",
      "answer": "Sublimation",
      "options": ["Fusion", "Vaporization", "Evaporation", "Sublimation"]
    },
    {
      "question": "What is the lightest element?",
      "answer": "Hydrogen",
      "options": ["Helium", "Oxygen", "Carbon", "Hydrogen"]
    },
    {
      "question": "What is the chemical symbol for oxygen?",
      "answer": "O",
      "options": ["O2", "CO", "O", "CO2"]
    },
    {
      "question": "What is the process of a liquid becoming a gas?",
      "answer": "Evaporation",
      "options": ["Vaporization", "Evaporation", "Condensation", "Sublimation"]
    },
    {
      "question": "What is the chemical symbol for carbon dioxide?",
      "answer": "CO2",
      "options": ["CO2", "CO", "O2", "H2O"]
    },
    {
      "question": "What is the process of a gas turning into a liquid?",
      "answer": "Condensation",
      "options": ["Evaporation", "Condensation", "Vaporization", "Sublimation"]
    },
    {
      "question": "What is the chemical symbol for iron?",
      "answer": "Fe",
      "options": ["Ag", "Au", "Al", "Fe"]
    }
  ]  
 },

 //Famous Personalities
 { 
  id: 92, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Scientists",
  questionList:[
    {
      "question": "Who is known as the father of modern physics?",
      "answer": "Albert Einstein",
      "options": ["Isaac Newton", "Galileo Galilei", "Albert Einstein", "Niels Bohr"]
    },
    {
      "question": "Who discovered the theory of general relativity?",
      "answer": "Albert Einstein",
      "options": ["Isaac Newton", "Galileo Galilei", "Albert Einstein", "Niels Bohr"]
    },
    {
      "question": "Who is credited with the discovery of penicillin?",
      "answer": "Alexander Fleming",
      "options": ["Marie Curie", "Alexander Fleming", "Louis Pasteur", "Robert Koch"]
    },
    {
      "question": "Who developed the theory of evolution by natural selection?",
      "answer": "Charles Darwin",
      "options": ["Gregor Mendel", "Louis Pasteur", "Charles Darwin", "Alfred Wallace"]
    },
    {
      "question": "Who is known for the theory of relativity?",
      "answer": "Albert Einstein",
      "options": ["Isaac Newton", "Galileo Galilei", "Albert Einstein", "Niels Bohr"]
    },
    {
      "question": "Who formulated the laws of motion and universal gravitation?",
      "answer": "Isaac Newton",
      "options": ["Albert Einstein", "Galileo Galilei", "Isaac Newton", "Niels Bohr"]
    },
    {
      "question": "Who is considered the founder of modern chemistry?",
      "answer": "Antoine Lavoisier",
      "options": ["Antoine Lavoisier", "Dmitri Mendeleev", "Marie Curie", "Robert Hooke"]
    },
    {
      "question": "Who is known for the theory of electromagnetism?",
      "answer": "James Clerk Maxwell",
      "options": ["Michael Faraday", "James Clerk Maxwell", "Nikola Tesla", "Thomas Edison"]
    },
    {
      "question": "Who discovered radioactivity?",
      "answer": "Marie Curie",
      "options": ["Marie Curie", "Ernest Rutherford", "Antoine Becquerel", "Wilhelm Röntgen"]
    },
    {
      "question": "Who proposed the heliocentric model of the solar system?",
      "answer": "Nicolaus Copernicus",
      "options": ["Nicolaus Copernicus", "Johannes Kepler", "Galileo Galilei", "Tycho Brahe"]
    },
    {
      "question": "Who is known as the father of modern chemistry?",
      "answer": "Antoine Lavoisier",
      "options": ["Antoine Lavoisier", "Dmitri Mendeleev", "Marie Curie", "Robert Hooke"]
    },
    {
      "question": "Who discovered the law of universal gravitation?",
      "answer": "Isaac Newton",
      "options": ["Albert Einstein", "Galileo Galilei", "Isaac Newton", "Niels Bohr"]
    }
  ]  
 },
 { 
  id: 93, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Authors",
  questionList:[
    {
      "question": "Who wrote 'Pride and Prejudice'?",
      "answer": "Jane Austen",
      "options": ["Emily Brontë", "Jane Austen", "Charlotte Brontë", "Virginia Woolf"]
    },
    {
      "question": "Who is the author of 'To Kill a Mockingbird'?",
      "answer": "Harper Lee",
      "options": ["Harper Lee", "F. Scott Fitzgerald", "J.D. Salinger", "Ernest Hemingway"]
    },
    {
      "question": "Who wrote '1984'?",
      "answer": "George Orwell",
      "options": ["George Orwell", "Aldous Huxley", "Ray Bradbury", "Kurt Vonnegut"]
    },
    {
      "question": "Who authored 'The Great Gatsby'?",
      "answer": "F. Scott Fitzgerald",
      "options": ["Harper Lee", "F. Scott Fitzgerald", "J.D. Salinger", "Ernest Hemingway"]
    },
    {
      "question": "Who is the author of 'The Catcher in the Rye'?",
      "answer": "J.D. Salinger",
      "options": ["Harper Lee", "F. Scott Fitzgerald", "J.D. Salinger", "Ernest Hemingway"]
    },
    {
      "question": "Who wrote 'Hamlet'?",
      "answer": "William Shakespeare",
      "options": ["William Shakespeare", "Leo Tolstoy", "Fyodor Dostoevsky", "Charles Dickens"]
    },
    {
      "question": "Who authored 'The Lord of the Rings' trilogy?",
      "answer": "J.R.R. Tolkien",
      "options": ["J.K. Rowling", "J.R.R. Tolkien", "C.S. Lewis", "George R.R. Martin"]
    },
    {
      "question": "Who is known for writing 'Moby-Dick'?",
      "answer": "Herman Melville",
      "options": ["Mark Twain", "Herman Melville", "Nathaniel Hawthorne", "Jack London"]
    },
    {
      "question": "Who wrote 'Jane Eyre'?",
      "answer": "Charlotte Brontë",
      "options": ["Emily Brontë", "Jane Austen", "Charlotte Brontë", "Virginia Woolf"]
    },
    {
      "question": "Who is the author of 'The Odyssey'?",
      "answer": "Homer",
      "options": ["Virgil", "Homer", "Plato", "Aristotle"]
    },
    {
      "question": "Who authored 'The Adventures of Huckleberry Finn'?",
      "answer": "Mark Twain",
      "options": ["Herman Melville", "Mark Twain", "Nathaniel Hawthorne", "Jack London"]
    },
    {
      "question": "Who wrote 'One Hundred Years of Solitude'?",
      "answer": "Gabriel García Márquez",
      "options": ["Gabriel García Márquez", "Pablo Neruda", "Isabel Allende", "Jorge Luis Borges"]
    }
  ]
 },
 { 
  id: 94, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Artists",
  questionList:[
    {
      "question": "Who painted the Mona Lisa?",
      "answer": "Leonardo da Vinci",
      "options": ["Michelangelo", "Raphael", "Leonardo da Vinci", "Vincent van Gogh"]
    },
    {
      "question": "Who created the sculpture 'David'?",
      "answer": "Michelangelo",
      "options": ["Leonardo da Vinci", "Raphael", "Michelangelo", "Vincent van Gogh"]
    },
    {
      "question": "Who painted 'Starry Night'?",
      "answer": "Vincent van Gogh",
      "options": ["Pablo Picasso", "Claude Monet", "Vincent van Gogh", "Salvador Dalí"]
    },
    {
      "question": "Who is known for the painting 'The Persistence of Memory'?",
      "answer": "Salvador Dalí",
      "options": ["Pablo Picasso", "Claude Monet", "Vincent van Gogh", "Salvador Dalí"]
    },
    {
      "question": "Who painted 'The Scream'?",
      "answer": "Edvard Munch",
      "options": ["Pablo Picasso", "Claude Monet", "Edvard Munch", "Salvador Dalí"]
    },
    {
      "question": "Who created the 'Last Supper'?",
      "answer": "Leonardo da Vinci",
      "options": ["Michelangelo", "Raphael", "Leonardo da Vinci", "Vincent van Gogh"]
    },
    {
      "question": "Who painted 'Guernica'?",
      "answer": "Pablo Picasso",
      "options": ["Pablo Picasso", "Claude Monet", "Vincent van Gogh", "Salvador Dalí"]
    },
    {
      "question": "Who sculpted 'The Thinker'?",
      "answer": "Auguste Rodin",
      "options": ["Donatello", "Auguste Rodin", "Michelangelo", "Bernini"]
    },
    {
      "question": "Who painted 'Water Lilies'?",
      "answer": "Claude Monet",
      "options": ["Pablo Picasso", "Claude Monet", "Vincent van Gogh", "Salvador Dalí"]
    },
    {
      "question": "Who created 'The Birth of Venus'?",
      "answer": "Sandro Botticelli",
      "options": ["Leonardo da Vinci", "Raphael", "Sandro Botticelli", "Vincent van Gogh"]
    },
    {
      "question": "Who painted 'The Starry Night'?",
      "answer": "Vincent van Gogh",
      "options": ["Leonardo da Vinci", "Raphael", "Vincent van Gogh", "Michelangelo"]
    },
    {
      "question": "Who is known for the painting 'The Creation of Adam'?",
      "answer": "Michelangelo",
      "options": ["Leonardo da Vinci", "Michelangelo", "Vincent van Gogh", "Raphael"]
    }
  ]  
 },
 { 
  id: 95, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Musicians",
  questionList:[
    {
      "question": "Who is known as the 'King of Pop'?",
      "answer": "Michael Jackson",
      "options": ["Michael Jackson", "Elvis Presley", "John Lennon", "Madonna"]
    },
    {
      "question": "Who is the lead vocalist of the band Queen?",
      "answer": "Freddie Mercury",
      "options": ["Freddie Mercury", "John Lennon", "Mick Jagger", "Elvis Presley"]
    },
    {
      "question": "Who is the guitarist for the band Led Zeppelin?",
      "answer": "Jimmy Page",
      "options": ["Eric Clapton", "Jimi Hendrix", "Jimmy Page", "David Gilmour"]
    },
    {
      "question": "Who is known as the 'Queen of Soul'?",
      "answer": "Aretha Franklin",
      "options": ["Whitney Houston", "Aretha Franklin", "Beyoncé", "Diana Ross"]
    },
    {
      "question": "Who is the lead vocalist of the band The Beatles?",
      "answer": "John Lennon",
      "options": ["Paul McCartney", "John Lennon", "George Harrison", "Ringo Starr"]
    },
    {
      "question": "Who is known as the 'Godfather of Soul'?",
      "answer": "James Brown",
      "options": ["Ray Charles", "James Brown", "Marvin Gaye", "Stevie Wonder"]
    },
    {
      "question": "Who is the lead vocalist of the band The Rolling Stones?",
      "answer": "Mick Jagger",
      "options": ["Mick Jagger", "Keith Richards", "Ronnie Wood", "Charlie Watts"]
    },
    {
      "question": "Who is known as the 'Queen of Pop'?",
      "answer": "Madonna",
      "options": ["Madonna", "Whitney Houston", "Beyoncé", "Britney Spears"]
    },
    {
      "question": "Who is the lead vocalist of the band Nirvana?",
      "answer": "Kurt Cobain",
      "options": ["Kurt Cobain", "Dave Grohl", "Krist Novoselic", "Eddie Vedder"]
    },
    {
      "question": "Who is known as the 'Rocket Man'?",
      "answer": "Elton John",
      "options": ["Elton John", "Billy Joel", "Stevie Wonder", "Paul McCartney"]
    },
    {
      "question": "Who is the lead vocalist of the band Queen?",
      "answer": "Freddie Mercury",
      "options": ["Freddie Mercury", "John Lennon", "Mick Jagger", "Elvis Presley"]
    },
    {
      "question": "Who is the lead vocalist of the band The Beatles?",
      "answer": "John Lennon",
      "options": ["Paul McCartney", "John Lennon", "George Harrison", "Ringo Starr"]
    }
  ]  
 },
 { 
  id: 96, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Actors/Actresses",
  questionList:[
    {
      "question": "Who won the Academy Award for Best Actor for his role in 'The Godfather'?",
      "answer": "Marlon Brando",
      "options": ["Marlon Brando", "Al Pacino", "Robert De Niro", "Jack Nicholson"]
    },
    {
      "question": "Who is the lead actor in the movie 'Forrest Gump'?",
      "answer": "Tom Hanks",
      "options": ["Tom Cruise", "Tom Hanks", "Brad Pitt", "Leonardo DiCaprio"]
    },
    {
      "question": "Who played the role of Iron Man in the Marvel Cinematic Universe?",
      "answer": "Robert Downey Jr.",
      "options": ["Chris Hemsworth", "Chris Evans", "Robert Downey Jr.", "Mark Ruffalo"]
    },
    {
      "question": "Who won the Academy Award for Best Actress for her role in 'La La Land'?",
      "answer": "Emma Stone",
      "options": ["Emma Watson", "Emma Stone", "Natalie Portman", "Jennifer Lawrence"]
    },
    {
      "question": "Who portrayed Captain Jack Sparrow in the 'Pirates of the Caribbean' series?",
      "answer": "Johnny Depp",
      "options": ["Brad Pitt", "Leonardo DiCaprio", "Johnny Depp", "Tom Cruise"]
    },
    {
      "question": "Who is known for his role as Batman in 'The Dark Knight' trilogy?",
      "answer": "Christian Bale",
      "options": ["Ben Affleck", "Christian Bale", "Michael Keaton", "George Clooney"]
    },
    {
      "question": "Who played the role of Hermione Granger in the 'Harry Potter' film series?",
      "answer": "Emma Watson",
      "options": ["Emma Watson", "Emma Stone", "Natalie Portman", "Jennifer Lawrence"]
    },
    {
      "question": "Who won the Academy Award for Best Actor for his role in 'The Revenant'?",
      "answer": "Leonardo DiCaprio",
      "options": ["Brad Pitt", "Leonardo DiCaprio", "Johnny Depp", "Tom Cruise"]
    },
    {
      "question": "Who portrayed Tony Stark in the Marvel Cinematic Universe?",
      "answer": "Robert Downey Jr.",
      "options": ["Chris Hemsworth", "Chris Evans", "Robert Downey Jr.", "Mark Ruffalo"]
    },
    {
      "question": "Who won the Academy Award for Best Actress for her role in 'Silver Linings Playbook'?",
      "answer": "Jennifer Lawrence",
      "options": ["Emma Watson", "Emma Stone", "Natalie Portman", "Jennifer Lawrence"]
    },
    {
      "question": "Who played the role of Neo in 'The Matrix' trilogy?",
      "answer": "Keanu Reeves",
      "options": ["Tom Cruise", "Keanu Reeves", "Brad Pitt", "Leonardo DiCaprio"]
    },
    {
      "question": "Who is known for his role as Wolverine in the 'X-Men' film series?",
      "answer": "Hugh Jackman",
      "options": ["Hugh Jackman", "Chris Hemsworth", "Chris Evans", "Robert Downey Jr."]
    }
  ]  
 },
 { 
  id: 97, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Politicians",
  questionList:[
    {
      "question": "Who was the first President of the United States?",
      "answer": "George Washington",
      "options": ["Thomas Jefferson", "George Washington", "John Adams", "Abraham Lincoln"]
    },
    {
      "question": "Who was the first Prime Minister of India?",
      "answer": "Jawaharlal Nehru",
      "options": ["Mahatma Gandhi", "Jawaharlal Nehru", "Indira Gandhi", "Sardar Vallabhbhai Patel"]
    },
    {
      "question": "Who is known as the 'Iron Lady' of British politics?",
      "answer": "Margaret Thatcher",
      "options": ["Angela Merkel", "Theresa May", "Margaret Thatcher", "Hillary Clinton"]
    },
    {
      "question": "Who was the first female Prime Minister of the United Kingdom?",
      "answer": "Margaret Thatcher",
      "options": ["Angela Merkel", "Theresa May", "Margaret Thatcher", "Hillary Clinton"]
    },
    {
      "question": "Who is the current President of Russia?",
      "answer": "Vladimir Putin",
      "options": ["Vladimir Putin", "Dmitry Medvedev", "Boris Yeltsin", "Mikhail Gorbachev"]
    },
    {
      "question": "Who served as the President of the United States during the Civil War?",
      "answer": "Abraham Lincoln",
      "options": ["Thomas Jefferson", "George Washington", "Abraham Lincoln", "John F. Kennedy"]
    },
    {
      "question": "Who was the first female Chancellor of Germany?",
      "answer": "Angela Merkel",
      "options": ["Angela Merkel", "Theresa May", "Margaret Thatcher", "Hillary Clinton"]
    },
    {
      "question": "Who was the longest-serving Prime Minister of the United Kingdom in the 20th century?",
      "answer": "Margaret Thatcher",
      "options": ["Tony Blair", "David Cameron", "Margaret Thatcher", "Winston Churchill"]
    },
    {
      "question": "Who is the current Prime Minister of Canada?",
      "answer": "Justin Trudeau",
      "options": ["Stephen Harper", "Justin Trudeau", "Jean Chrétien", "Brian Mulroney"]
    },
    {
      "question": "Who is known as the 'Father of the Nation' in India?",
      "answer": "Mahatma Gandhi",
      "options": ["Jawaharlal Nehru", "Indira Gandhi", "Mahatma Gandhi", "Sardar Vallabhbhai Patel"]
    },
    {
      "question": "Who served as the first President of South Africa after apartheid?",
      "answer": "Nelson Mandela",
      "options": ["Nelson Mandela", "F.W. de Klerk", "Thabo Mbeki", "Jacob Zuma"]
    },
    {
      "question": "Who is the current Chancellor of Germany?",
      "answer": "Angela Merkel",
      "options": ["Angela Merkel", "Theresa May", "Margaret Thatcher", "Hillary Clinton"]
    }
  ]  
 },
 { 
  id: 98, 
  img: "famous.png", 
  category: "Famous Personalities", 
  name:"Historical Figures",
  questionList:[
    {
      "question": "Who was the ancient Egyptian queen known for her beauty and her relationships with Julius Caesar and Mark Antony?",
      "answer": "Cleopatra",
      "options": ["Nefertiti", "Hatshepsut", "Cleopatra", "Catherine the Great"]
    },
    {
      "question": "Who was the first Emperor of China, known for unifying China and beginning construction of the Great Wall?",
      "answer": "Qin Shi Huang",
      "options": ["Confucius", "Qin Shi Huang", "Mao Zedong", "Sun Yat-sen"]
    },
    {
      "question": "Who was the founder of the Mongol Empire and the largest contiguous empire in history?",
      "answer": "Genghis Khan",
      "options": ["Attila the Hun", "Genghis Khan", "Alexander the Great", "Julius Caesar"]
    },
    {
      "question": "Who was the first female pharaoh of ancient Egypt?",
      "answer": "Hatshepsut",
      "options": ["Nefertiti", "Hatshepsut", "Cleopatra", "Catherine the Great"]
    },
    {
      "question": "Who was the first female ruler of Russia, known for her expansionist policies?",
      "answer": "Catherine the Great",
      "options": ["Nefertiti", "Hatshepsut", "Cleopatra", "Catherine the Great"]
    },
    {
      "question": "Who was the Athenian philosopher known for his contributions to ethics and epistemology?",
      "answer": "Socrates",
      "options": ["Plato", "Aristotle", "Socrates", "Homer"]
    },
    {
      "question": "Who was the Roman general and statesman known for his role in the collapse of the Roman Republic and the rise of the Roman Empire?",
      "answer": "Julius Caesar",
      "options": ["Hannibal", "Augustus", "Julius Caesar", "Napoleon"]
    },
    {
      "question": "Who was the founder of the Achaemenid Empire, also known as the Persian Empire?",
      "answer": "Cyrus the Great",
      "options": ["Darius the Great", "Xerxes the Great", "Cyrus the Great", "Alexander the Great"]
    },
    {
      "question": "Who was the ancient Greek philosopher known for his works on metaphysics, ethics, and aesthetics?",
      "answer": "Aristotle",
      "options": ["Plato", "Aristotle", "Socrates", "Homer"]
    },
    {
      "question": "Who was the Roman emperor known for his military achievements and the construction of Hadrian's Wall?",
      "answer": "Hadrian",
      "options": ["Augustus", "Trajan", "Hadrian", "Marcus Aurelius"]
    },
    {
      "question": "Who was the ancient Greek king known for his military conquests and the spread of Hellenistic culture?",
      "answer": "Alexander the Great",
      "options": ["Julius Caesar", "Attila the Hun", "Genghis Khan", "Alexander the Great"]
    },
    {
      "question": "Who was the founder of Islam?",
      "answer": "Muhammad",
      "options": ["Abraham", "Moses", "Jesus", "Muhammad"]
    }
  ]  
 },
 //General Trivia
 { 
  id: 99, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Science Trivia",
  questionList:[
    {
      "question": "What is the chemical symbol for water?",
      "answer": "H2O",
      "options": ["H2O", "CO2", "O2", "NaCl"]
    },
    {
      "question": "What is the closest planet to the sun?",
      "answer": "Mercury",
      "options": ["Venus", "Mars", "Mercury", "Earth"]
    },
    {
      "question": "What is the powerhouse of the cell?",
      "answer": "Mitochondria",
      "options": ["Nucleus", "Mitochondria", "Ribosome", "Endoplasmic Reticulum"]
    },
    {
      "question": "What is the chemical symbol for gold?",
      "answer": "Au",
      "options": ["Ag", "Au", "Fe", "Cu"]
    },
    {
      "question": "What is the largest organ in the human body?",
      "answer": "Skin",
      "options": ["Brain", "Heart", "Skin", "Liver"]
    },
    {
      "question": "What is the study of earthquakes called?",
      "answer": "Seismology",
      "options": ["Biology", "Astronomy", "Seismology", "Meteorology"]
    },
    {
      "question": "What is the process by which plants make their food called?",
      "answer": "Photosynthesis",
      "options": ["Respiration", "Fermentation", "Photosynthesis", "Transpiration"]
    },
    {
      "question": "What is the chemical symbol for iron?",
      "answer": "Fe",
      "options": ["Fe", "Ir", "In", "Au"]
    },
    {
      "question": "Which planet is known as the 'Red Planet'?",
      "answer": "Mars",
      "options": ["Venus", "Mercury", "Mars", "Jupiter"]
    },
    {
      "question": "What is the hardest natural substance on Earth?",
      "answer": "Diamond",
      "options": ["Gold", "Iron", "Diamond", "Platinum"]
    },
    {
      "question": "What is the largest mammal in the world?",
      "answer": "Blue whale",
      "options": ["Elephant", "Blue whale", "Giraffe", "Hippopotamus"]
    },
    {
      "question": "What is the chemical symbol for sodium?",
      "answer": "Na",
      "options": ["Sn", "Na", "N", "Ne"]
    }
  ]  
 },
 { 
  id: 100, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Geography Trivia",
  questionList:[
    {
      "question": "What is the capital of France?",
      "answer": "Paris",
      "options": ["London", "Berlin", "Madrid", "Paris"]
    },
    {
      "question": "Which is the largest desert in the world?",
      "answer": "Sahara Desert",
      "options": ["Gobi Desert", "Kalahari Desert", "Sahara Desert", "Arabian Desert"]
    },
    {
      "question": "What is the tallest mountain in the world?",
      "answer": "Mount Everest",
      "options": ["K2", "Mount Kilimanjaro", "Mount Everest", "Kangchenjunga"]
    },
    {
      "question": "Which river is the longest in the world?",
      "answer": "Nile River",
      "options": ["Amazon River", "Yangtze River", "Nile River", "Mississippi River"]
    },
    {
      "question": "Which country is known as the Land of the Rising Sun?",
      "answer": "Japan",
      "options": ["China", "Japan", "India", "Australia"]
    },
    {
      "question": "Which continent is the driest in the world?",
      "answer": "Antarctica",
      "options": ["Africa", "Australia", "North America", "Antarctica"]
    },
    {
      "question": "Which country is known as the Emerald Isle?",
      "answer": "Ireland",
      "options": ["Scotland", "England", "Ireland", "Wales"]
    },
    {
      "question": "Which city is located both in Europe and Asia?",
      "answer": "Istanbul",
      "options": ["Moscow", "Istanbul", "Athens", "Rome"]
    },
    {
      "question": "Which country is the largest by land area?",
      "answer": "Russia",
      "options": ["China", "United States", "Canada", "Russia"]
    },
    {
      "question": "What is the smallest country in the world?",
      "answer": "Vatican City",
      "options": ["Monaco", "Liechtenstein", "San Marino", "Vatican City"]
    },
    {
      "question": "Which ocean is the deepest?",
      "answer": "Pacific Ocean",
      "options": ["Atlantic Ocean", "Indian Ocean", "Arctic Ocean", "Pacific Ocean"]
    },
    {
      "question": "What is the largest country in South America?",
      "answer": "Brazil",
      "options": ["Argentina", "Peru", "Colombia", "Brazil"]
    }
  ]  
 },
 { 
  id: 101, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Sports Trivia",
  questionList:[
    {
      "question": "In which sport is the Davis Cup awarded?",
      "answer": "Tennis",
      "options": ["Golf", "Tennis", "Soccer", "Cricket"]
    },
    {
      "question": "Who is the all-time leading goal scorer in FIFA World Cup history?",
      "answer": "Marta",
      "options": ["Lionel Messi", "Cristiano Ronaldo", "Pele", "Marta"]
    },
    {
      "question": "Which country won the first Rugby World Cup in 1987?",
      "answer": "New Zealand",
      "options": ["Australia", "South Africa", "New Zealand", "England"]
    },
    {
      "question": "What is the diameter of a basketball hoop in inches?",
      "answer": "18 inches",
      "options": ["16 inches", "18 inches", "20 inches", "22 inches"]
    },
    {
      "question": "How many players are there on a baseball team?",
      "answer": "9",
      "options": ["7", "9", "11", "12"]
    },
    {
      "question": "What is the highest possible score in a gymnastics routine?",
      "answer": "10",
      "options": ["9", "9.5", "10", "10.5"]
    },
    {
      "question": "In which sport do teams compete for the Stanley Cup?",
      "answer": "Ice Hockey",
      "options": ["Baseball", "Ice Hockey", "Basketball", "American Football"]
    },
    {
      "question": "What is the total number of holes on a standard golf course?",
      "answer": "18",
      "options": ["9", "12", "18", "24"]
    },
    {
      "question": "Who holds the record for the most Olympic gold medals?",
      "answer": "Michael Phelps",
      "options": ["Usain Bolt", "Michael Phelps", "Simone Biles", "Larisa Latynina"]
    },
    {
      "question": "Which country won the most medals in the 2016 Summer Olympics?",
      "answer": "United States",
      "options": ["China", "Russia", "United States", "Great Britain"]
    },
    {
      "question": "What is the circumference of a standard soccer ball?",
      "answer": "27 to 28 inches",
      "options": ["25 to 26 inches", "27 to 28 inches", "29 to 30 inches", "31 to 32 inches"]
    },
    {
      "question": "Who was the first athlete to run a mile in under four minutes?",
      "answer": "Roger Bannister",
      "options": ["Roger Bannister", "Usain Bolt", "Michael Johnson", "Haile Gebrselassie"]
    }
  ]  
 },
 { 
  id: 102, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Technology Trivia",
  questionList:[
    {
      "question": "Who is the co-founder of Apple Inc. along with Steve Jobs?",
      "answer": "Steve Wozniak",
      "options": ["Tim Cook", "Steve Wozniak", "Bill Gates", "Mark Zuckerberg"]
    },
    {
      "question": "What does 'www' stand for in a website browser?",
      "answer": "World Wide Web",
      "options": ["World War Web", "Web World Wide", "World Wide Web", "Web World War"]
    },
    {
      "question": "Who developed the first graphical web browser?",
      "answer": "Marc Andreessen",
      "options": ["Tim Berners-Lee", "Bill Gates", "Larry Page", "Marc Andreessen"]
    },
    {
      "question": "What is the name of Amazon's virtual assistant?",
      "answer": "Alexa",
      "options": ["Siri", "Alexa", "Cortana", "Google Assistant"]
    },
    {
      "question": "What is the term used for malicious software intended to damage or disable computers?",
      "answer": "Virus",
      "options": ["Spyware", "Malware", "Trojan Horse", "Virus"]
    },
    {
      "question": "What is the currency of the online gaming platform 'Roblox'?",
      "answer": "Robux",
      "options": ["Coins", "Gems", "Robux", "Credits"]
    },
    {
      "question": "Which company developed the Java programming language?",
      "answer": "Sun Microsystems",
      "options": ["Microsoft", "Apple", "Google", "Sun Microsystems"]
    },
    {
      "question": "What is the process of transferring data over a network, especially the internet, called?",
      "answer": "Downloading",
      "options": ["Uploading", "Downloading", "Transferring", "Streaming"]
    },
    {
      "question": "Who is the CEO of Tesla, Inc.?",
      "answer": "Elon Musk",
      "options": ["Bill Gates", "Mark Zuckerberg", "Elon Musk", "Jeff Bezos"]
    },
    {
      "question": "What is the world's largest social media platform by user count?",
      "answer": "Facebook",
      "options": ["Instagram", "Twitter", "Facebook", "LinkedIn"]
    },
    {
      "question": "What is the name of the first commercial video game?",
      "answer": "Pong",
      "options": ["Pac-Man", "Space Invaders", "Pong", "Tetris"]
    },
    {
      "question": "What is the term used for a small piece of code that is able to self-replicate?",
      "answer": "Worm",
      "options": ["Trojan", "Virus", "Worm", "Spyware"]
    }
  ]
 },
 { 
  id: 103, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Music Trivia",
  questionList:[
    {
      "question": "Who is known as the 'King of Pop'?",
      "answer": "Michael Jackson",
      "options": ["Prince", "Elvis Presley", "Michael Jackson", "Madonna"]
    },
    {
      "question": "What is the best-selling album of all time?",
      "answer": "Thriller by Michael Jackson",
      "options": ["Back in Black by AC/DC", "The Dark Side of the Moon by Pink Floyd", "Thriller by Michael Jackson", "The Wall by Pink Floyd"]
    },
    {
      "question": "Who is the lead vocalist of the band Queen?",
      "answer": "Freddie Mercury",
      "options": ["Freddie Mercury", "David Bowie", "Roger Daltrey", "Robert Plant"]
    },
    {
      "question": "What is the highest-pitched member of the string family?",
      "answer": "Violin",
      "options": ["Viola", "Cello", "Double bass", "Violin"]
    },
    {
      "question": "Who wrote the song 'Bohemian Rhapsody'?",
      "answer": "Freddie Mercury",
      "options": ["David Bowie", "Freddie Mercury", "Roger Waters", "John Lennon"]
    },
    {
      "question": "Which band had hits with 'Hotel California' and 'Take It Easy'?",
      "answer": "Eagles",
      "options": ["Led Zeppelin", "The Rolling Stones", "The Beatles", "Eagles"]
    },
    {
      "question": "Who is known as the 'Queen of Soul'?",
      "answer": "Aretha Franklin",
      "options": ["Whitney Houston", "Mariah Carey", "Aretha Franklin", "Tina Turner"]
    },
    {
      "question": "Which famous singer was known as 'The Voice'?",
      "answer": "Frank Sinatra",
      "options": ["Elvis Presley", "Frank Sinatra", "Johnny Cash", "Nat King Cole"]
    },
    {
      "question": "What is the longest-running Broadway musical of all time?",
      "answer": "The Phantom of the Opera",
      "options": ["The Lion King", "Cats", "Les Misérables", "The Phantom of the Opera"]
    },
    {
      "question": "Who composed the famous 'Moonlight Sonata'?",
      "answer": "Ludwig van Beethoven",
      "options": ["Wolfgang Amadeus Mozart", "Johann Sebastian Bach", "Ludwig van Beethoven", "Franz Schubert"]
    },
    {
      "question": "Who was the first woman to be inducted into the Rock and Roll Hall of Fame?",
      "answer": "Aretha Franklin",
      "options": ["Janis Joplin", "Tina Turner", "Aretha Franklin", "Patti Smith"]
    },
    {
      "question": "Which band's albums include 'The Wall' and 'The Dark Side of the Moon'?",
      "answer": "Pink Floyd",
      "options": ["The Beatles", "Pink Floyd", "Led Zeppelin", "The Rolling Stones"]
    }
  ]  
 },
 { 
  id: 104, 
  img: "generaltrivia.png", 
  category: "General Trivia", 
  name:"Movie Trivia",
  questionList:[
    {
      "question": "Who directed the movie 'Schindler's List'?",
      "answer": "Steven Spielberg",
      "options": ["Quentin Tarantino", "Steven Spielberg", "Martin Scorsese", "Christopher Nolan"]
    },
    {
      "question": "In the movie 'The Shawshank Redemption', who played the role of Andy Dufresne?",
      "answer": "Tim Robbins",
      "options": ["Morgan Freeman", "Tom Hanks", "Tim Robbins", "Brad Pitt"]
    },
    {
      "question": "What is the highest-grossing film of all time (unadjusted for inflation)?",
      "answer": "Avengers: Endgame",
      "options": ["Avatar", "Avengers: Endgame", "Titanic", "Star Wars: The Force Awakens"]
    },
    {
      "question": "Who played the role of Jack Dawson in the movie 'Titanic'?",
      "answer": "Leonardo DiCaprio",
      "options": ["Tom Cruise", "Brad Pitt", "Leonardo DiCaprio", "Johnny Depp"]
    },
    {
      "question": "Which movie won the Academy Award for Best Picture in 2019?",
      "answer": "Green Book",
      "options": ["Bohemian Rhapsody", "Green Book", "Roma", "Black Panther"]
    },
    {
      "question": "Who directed the movie 'Pulp Fiction'?",
      "answer": "Quentin Tarantino",
      "options": ["Martin Scorsese", "Steven Spielberg", "Quentin Tarantino", "Christopher Nolan"]
    },
    {
      "question": "What is the name of the fictional kingdom in the movie 'Black Panther'?",
      "answer": "Wakanda",
      "options": ["Asgard", "Westeros", "Wakanda", "Narnia"]
    },
    {
      "question": "Who won the Academy Award for Best Actor for his role in the movie 'The Revenant'?",
      "answer": "Leonardo DiCaprio",
      "options": ["Matthew McConaughey", "Leonardo DiCaprio", "Joaquin Phoenix", "Daniel Day-Lewis"]
    },
    {
      "question": "Which actor played the role of Tony Stark in the Marvel Cinematic Universe?",
      "answer": "Robert Downey Jr.",
      "options": ["Chris Evans", "Mark Ruffalo", "Robert Downey Jr.", "Chris Hemsworth"]
    },
    {
      "question": "Who directed the movie 'Forrest Gump'?",
      "answer": "Robert Zemeckis",
      "options": ["Steven Spielberg", "James Cameron", "Quentin Tarantino", "Robert Zemeckis"]
    },
    {
      "question": "Which movie features the character Hannibal Lecter?",
      "answer": "The Silence of the Lambs",
      "options": ["Se7en", "Psycho", "The Silence of the Lambs", "American Psycho"]
    },
    {
      "question": "What is the name of the island where 'Jurassic Park' is set?",
      "answer": "Isla Nublar",
      "options": ["Isla Sorna", "Isla Nublar", "Isla Pena", "Isla Muerta"]
    }
  ]  
 },

 //Monuments
 { 
  id: 105, 
  img: "monuments.png", 
  category: "Monuments", 
  name:"Pyramids",
  questionList:[
    {
      "question": "Where are the Great Pyramids located?",
      "answer": "Egypt",
      "options": ["Egypt", "Greece", "Mexico", "China"]
    },
    {
      "question": "Who built the Great Pyramid of Giza?",
      "answer": "Khufu (Cheops)",
      "options": ["Khafre", "Khufu", "Menkaure", "Djoser"]
    },
    {
      "question": "What is the tallest of the Great Pyramids?",
      "answer": "Great Pyramid of Khufu",
      "options": ["Great Pyramid of Khufu", "Great Pyramid of Khafre", "Great Pyramid of Menkaure"]
    },
    {
      "question": "How many pyramids are there in Giza?",
      "answer": "Three",
      "options": ["One", "Two", "Three", "Four"]
    },
    {
      "question": "What is the name of the Sphinx located near the Great Pyramids?",
      "answer": "The Great Sphinx of Giza",
      "options": ["The Great Sphinx of Giza", "The Sphinx of Luxor", "The Sphinx of Saqqara"]
    },
    {
      "question": "Which of the following is not a pyramid?",
      "answer": "Chichen Itza",
      "options": ["Great Pyramid of Giza", "Pyramid of Khafre", "Pyramid of the Sun", "Chichen Itza"]
    },
    {
      "question": "Which ancient civilization built the pyramids of Teotihuacan?",
      "answer": "Aztecs",
      "options": ["Mayans", "Incas", "Aztecs", "Toltecs"]
    },
    {
      "question": "What is the name of the step pyramid located in Saqqara, Egypt?",
      "answer": "Djoser's Pyramid",
      "options": ["Khufu's Pyramid", "Khafre's Pyramid", "Djoser's Pyramid", "Menkaure's Pyramid"]
    },
    {
      "question": "What is the purpose of the Bent Pyramid's unusual shape?",
      "answer": "Structural instability",
      "options": ["Symbolism", "Alignment with stars", "Structural instability", "Protection from invaders"]
    },
    {
      "question": "Which of the following pyramids is located in Mexico?",
      "answer": "Pyramid of the Sun",
      "options": ["Great Pyramid of Giza", "Pyramid of Khufu", "Pyramid of the Sun", "Pyramid of Djoser"]
    },
    {
      "question": "What is the name of the ancient city near the pyramids of Teotihuacan?",
      "answer": "Teotihuacan",
      "options": ["Tenochtitlan", "Teotihuacan", "Chichen Itza", "Machu Picchu"]
    },
    {
      "question": "Which pharaoh ordered the construction of the Step Pyramid of Djoser?",
      "answer": "Djoser",
      "options": ["Khufu", "Khafre", "Menkaure", "Djoser"]
    }
  ]  
 },
 { 
  id: 106, 
  img: "monuments.png", 
  category: "Monuments", 
  name:"Statues",
  questionList:[
    {
      "question": "What is the name of the statue located in Rio de Janeiro, Brazil?",
      "answer": "Christ the Redeemer",
      "options": ["The Statue of Liberty", "Christ the Redeemer", "The Colossus of Rhodes", "The Great Sphinx of Giza"]
    },
    {
      "question": "Where is the statue 'The Thinker' located?",
      "answer": "France",
      "options": ["Italy", "Spain", "France", "Greece"]
    },
    {
      "question": "What is the name of the giant Buddha statue in Leshan, China?",
      "answer": "Leshan Giant Buddha",
      "options": ["Tian Tan Buddha", "Leshan Giant Buddha", "Giant Buddha of Thailand", "Ushiku Daibutsu"]
    },
    {
      "question": "Which ancient statue is believed to depict the Greek god Helios?",
      "answer": "Colossus of Rhodes",
      "options": ["Colossus of Rhodes", "Statue of Zeus at Olympia", "Athena Parthenos", "The Thinker"]
    },
    {
      "question": "What is the name of the famous statue located in New York Harbor?",
      "answer": "The Statue of Liberty",
      "options": ["Christ the Redeemer", "The Statue of Liberty", "The Great Sphinx of Giza", "The Colossus of Rhodes"]
    },
    {
      "question": "Which statue is a symbol of democracy and freedom?",
      "answer": "Statue of Liberty",
      "options": ["Tian Tan Buddha", "Statue of Liberty", "Leshan Giant Buddha", "Christ the Redeemer"]
    },
    {
      "question": "What material was used to create the Terracotta Army in China?",
      "answer": "Terracotta",
      "options": ["Bronze", "Stone", "Terracotta", "Marble"]
    },
    {
      "question": "Where is the Sphinx located?",
      "answer": "Giza, Egypt",
      "options": ["Athens, Greece", "Rome, Italy", "Giza, Egypt", "Paris, France"]
    },
    {
      "question": "What is the name of the giant seated Buddha statue in Thailand?",
      "answer": "Giant Buddha of Thailand",
      "options": ["Tian Tan Buddha", "Leshan Giant Buddha", "Giant Buddha of Thailand", "Ushiku Daibutsu"]
    },
    {
      "question": "Which statue is situated on top of Mount Corcovado?",
      "answer": "Christ the Redeemer",
      "options": ["The Statue of Liberty", "The Colossus of Rhodes", "The Great Sphinx of Giza", "Christ the Redeemer"]
    },
    {
      "question": "What is the name of the ancient statue of the goddess Athena?",
      "answer": "Athena Parthenos",
      "options": ["Colossus of Rhodes", "Statue of Zeus at Olympia", "Athena Parthenos", "The Thinker"]
    },
    {
      "question": "Which statue is known for its serene expression and depicted in a seated position?",
      "answer": "Leshan Giant Buddha",
      "options": ["Tian Tan Buddha", "Leshan Giant Buddha", "Giant Buddha of Thailand", "Ushiku Daibutsu"]
    }
  ]  
 },
 { 
  id: 107, 
  img: "monuments.png", 
  category: "Monuments", 
  name:"Cathedrals",
  questionList:[
    {
      "question": "Which cathedral is known for its Gothic architecture and located in Paris, France?",
      "answer": "Notre-Dame Cathedral",
      "options": ["St. Peter's Basilica", "Notre-Dame Cathedral", "Westminster Abbey", "Cologne Cathedral"]
    },
    {
      "question": "Where is St. Basil's Cathedral located?",
      "answer": "Moscow, Russia",
      "options": ["Moscow, Russia", "Rome, Italy", "Athens, Greece", "Berlin, Germany"]
    },
    {
      "question": "What is the largest cathedral in the world by volume?",
      "answer": "St. Peter's Basilica",
      "options": ["St. Paul's Cathedral", "St. Peter's Basilica", "Cologne Cathedral", "Notre-Dame Cathedral"]
    },
    {
      "question": "Which cathedral is located in the Vatican City?",
      "answer": "St. Peter's Basilica",
      "options": ["Westminster Abbey", "St. Paul's Cathedral", "Cologne Cathedral", "St. Peter's Basilica"]
    },
    {
      "question": "Where is the Hagia Sophia located?",
      "answer": "Istanbul, Turkey",
      "options": ["Athens, Greece", "Rome, Italy", "Istanbul, Turkey", "Cairo, Egypt"]
    },
    {
      "question": "Which cathedral is known for its Romanesque architecture and located in England?",
      "answer": "Durham Cathedral",
      "options": ["St. Paul's Cathedral", "Durham Cathedral", "Westminster Abbey", "Canterbury Cathedral"]
    },
    {
      "question": "What is the name of the cathedral located in Cologne, Germany, known for its twin spires?",
      "answer": "Cologne Cathedral",
      "options": ["Notre-Dame Cathedral", "Cologne Cathedral", "Westminster Abbey", "St. Paul's Cathedral"]
    },
    {
      "question": "Where is the Duomo di Milano located?",
      "answer": "Milan, Italy",
      "options": ["Florence, Italy", "Venice, Italy", "Rome, Italy", "Milan, Italy"]
    },
    {
      "question": "Which cathedral is known for its medieval stained glass windows?",
      "answer": "Chartres Cathedral",
      "options": ["St. Vitus Cathedral", "Chartres Cathedral", "Cologne Cathedral", "St. Paul's Cathedral"]
    },
    {
      "question": "Where is the Cathedral of St. John the Divine located?",
      "answer": "New York City, USA",
      "options": ["Paris, France", "London, England", "Rome, Italy", "New York City, USA"]
    },
    {
      "question": "What is the name of the cathedral located in Barcelona, Spain, known for its distinctive spires?",
      "answer": "Sagrada Familia",
      "options": ["Sagrada Familia", "St. Basil's Cathedral", "Westminster Abbey", "Cologne Cathedral"]
    },
    {
      "question": "Where is the Canterbury Cathedral located?",
      "answer": "England",
      "options": ["France", "Italy", "Germany", "England"]
    }
  ]  
 },
 { 
  id: 108, 
  img: "monuments.png", 
  category: "Monuments", 
  name:"Ruins",
  questionList:[
    {
      "question": "What is the name of the ancient city buried by volcanic ash in Italy?",
      "answer": "Pompeii",
      "options": ["Troy", "Pompeii", "Ephesus", "Machu Picchu"]
    },
    {
      "question": "Where are the ruins of the ancient city of Petra located?",
      "answer": "Jordan",
      "options": ["Egypt", "Jordan", "Lebanon", "Syria"]
    },
    {
      "question": "Which ancient city was home to the famous Library of Alexandria?",
      "answer": "Alexandria",
      "options": ["Rome", "Athens", "Alexandria", "Byzantium"]
    },
    {
      "question": "What is the name of the pre-Columbian city located in present-day Mexico?",
      "answer": "Teotihuacan",
      "options": ["Chichen Itza", "Teotihuacan", "Palenque", "Machu Picchu"]
    },
    {
      "question": "Where are the ruins of the ancient city of Ephesus located?",
      "answer": "Turkey",
      "options": ["Greece", "Turkey", "Italy", "Egypt"]
    },
    {
      "question": "What is the name of the ancient city in Greece known for its mythological connection to the Trojan War?",
      "answer": "Troy",
      "options": ["Athens", "Sparta", "Troy", "Ephesus"]
    },
    {
      "question": "Where are the ruins of the ancient Mayan city of Chichen Itza located?",
      "answer": "Mexico",
      "options": ["Peru", "Mexico", "Guatemala", "Honduras"]
    },
    {
      "question": "What is the name of the ancient city in Italy known for its well-preserved Roman ruins?",
      "answer": "Herculaneum",
      "options": ["Pompeii", "Herculaneum", "Rome", "Pisa"]
    },
    {
      "question": "Where are the ruins of the ancient Incan city of Machu Picchu located?",
      "answer": "Peru",
      "options": ["Peru", "Bolivia", "Chile", "Ecuador"]
    },
    {
      "question": "What is the name of the ancient city located in present-day Lebanon known for its Roman ruins?",
      "answer": "Baalbek",
      "options": ["Tyre", "Sidon", "Baalbek", "Byblos"]
    },
    {
      "question": "Where are the ruins of the ancient city of Palmyra located?",
      "answer": "Syria",
      "options": ["Iraq", "Syria", "Jordan", "Lebanon"]
    },
    {
      "question": "What is the name of the ancient city in Cambodia known for its temple complex?",
      "answer": "Angkor",
      "options": ["Bagan", "Angkor", "Sukhothai", "Ayutthaya"]
    }
  ]  
 },
 { 
  id: 109, 
  img: "monuments.png", 
  category: "Monuments", 
  name:"Towers",
  questionList:[
    {
      "question": "Where is the tallest tower in the world, Burj Khalifa, located?",
      "answer": "Dubai, UAE",
      "options": ["New York City, USA", "Tokyo, Japan", "Shanghai, China", "Dubai, UAE"]
    },
    {
      "question": "What is the name of the tower in London, England, that houses the Big Ben bell?",
      "answer": "Elizabeth Tower",
      "options": ["Tower of London", "Westminster Abbey", "Tower Bridge", "Elizabeth Tower"]
    },
    {
      "question": "Where is the Leaning Tower of Pisa located?",
      "answer": "Italy",
      "options": ["Spain", "France", "Italy", "Greece"]
    },
    {
      "question": "What is the name of the tower located in Paris, France, known for its iron lattice structure?",
      "answer": "Eiffel Tower",
      "options": ["Eiffel Tower", "Arc de Triomphe", "Louvre Pyramid", "Montparnasse Tower"]
    },
    {
      "question": "Where is the Tokyo Skytree located?",
      "answer": "Japan",
      "options": ["China", "South Korea", "Japan", "Taiwan"]
    },
    {
      "question": "What is the name of the tower located in Seattle, USA, known for its unique shape?",
      "answer": "Space Needle",
      "options": ["Empire State Building", "Space Needle", "Chrysler Building", "One World Trade Center"]
    },
    {
      "question": "Where is the CN Tower located?",
      "answer": "Toronto, Canada",
      "options": ["New York City, USA", "Chicago, USA", "Toronto, Canada", "Montreal, Canada"]
    },
    {
      "question": "What is the name of the tower located in Berlin, Germany, known for its observation deck and revolving restaurant?",
      "answer": "Fernsehturm Berlin",
      "options": ["Berlin TV Tower", "Cologne Cathedral", "Brandenburg Gate", "Fernsehturm Berlin"]
    },
    {
      "question": "Where is the Petronas Twin Towers located?",
      "answer": "Kuala Lumpur, Malaysia",
      "options": ["Singapore", "Bangkok, Thailand", "Kuala Lumpur, Malaysia", "Jakarta, Indonesia"]
    },
    {
      "question": "What is the name of the tower located in Sydney, Australia, known for its distinctive sail-like design?",
      "answer": "Sydney Opera House",
      "options": ["Sydney Tower", "Sydney Opera House", "Harbour Bridge", "Anzac Bridge"]
    },
    {
      "question": "Where is the Willis Tower located?",
      "answer": "Chicago, USA",
      "options": ["Los Angeles, USA", "Chicago, USA", "Houston, USA", "Philadelphia, USA"]
    },
    {
      "question": "What is the name of the tower located in Las Vegas, USA, known for its observation deck and thrill rides?",
      "answer": "Stratosphere Tower",
      "options": ["Eiffel Tower", "Space Needle", "Stratosphere Tower", "CN Tower"]
    }
  ]  
 },
]